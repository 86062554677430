import {
  LazyMotion,
  domAnimation,
  m,
  Variants,
  AnimationControls,
} from 'framer-motion'
import { bgGridDotColor, greyGridDotColor, tealGridDotColor } from './constants'

const STEPS = 6
const FADE_DURATION = 0.15

const ascendingGroupVariants: Variants = {
  animate: {
    transition: {
      staggerChildren: FADE_DURATION,
    },
  },
}

const ascendingVariants: Variants = {
  before: {
    fill: greyGridDotColor,
  },
  animate: {
    fill: [
      greyGridDotColor,
      tealGridDotColor,
      tealGridDotColor,
      greyGridDotColor,
    ],
    transition: {
      duration: FADE_DURATION * 2,
    },
  },
}

const finalVariants: Variants = {
  before: {
    fill: greyGridDotColor,
  },
  animate: {
    fill: tealGridDotColor,
    transition: {
      delay: STEPS * FADE_DURATION,
      duration: FADE_DURATION,
    },
  },
}

const pulseVariants: Variants = {
  before: {
    opacity: 1,
    scale: 1,
    transition: {
      repeat: 0,
    },
  },
  animate: {
    scale: 3,
    opacity: 0,
    transition: {
      delay: (STEPS + 1) * FADE_DURATION,
      duration: 1,
    },
  },
}

const OffersAnimation = ({ controls }: { controls: AnimationControls }) => {
  return (
    <div>
      <LazyMotion features={domAnimation}>
        {/* prettier-ignore */}
        <m.svg
          initial="before"
          animate={controls}
          viewBox="0 0 382 330"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <m.g variants={ascendingGroupVariants}>
            <m.path variants={ascendingVariants} d="M35.6985 304C40.669 304 44.6985 299.971 44.6985 295C44.6985 290.029 40.669 286 35.6985 286C30.7279 286 26.6985 290.029 26.6985 295C26.6985 299.971 30.7279 304 35.6985 304Z" fill={greyGridDotColor}/>
            <m.path variants={ascendingVariants} d="M87.6985 278C92.669 278 96.6985 273.971 96.6985 269C96.6985 264.029 92.669 260 87.6985 260C82.7279 260 78.6985 264.029 78.6985 269C78.6985 273.971 82.7279 278 87.6985 278Z" fill={greyGridDotColor}/>
            <m.path variants={ascendingVariants} d="M113.698 226C118.669 226 122.698 221.971 122.698 217C122.698 212.029 118.669 208 113.698 208C108.728 208 104.698 212.029 104.698 217C104.698 221.971 108.728 226 113.698 226Z" fill={greyGridDotColor}/>
            <m.path variants={ascendingVariants} d="M165.698 174C170.669 174 174.698 169.971 174.698 165C174.698 160.029 170.669 156 165.698 156C160.728 156 156.698 160.029 156.698 165C156.698 169.971 160.728 174 165.698 174Z" fill={greyGridDotColor}/>
            <m.path variants={ascendingVariants} d="M217.698 148C222.669 148 226.698 143.971 226.698 139C226.698 134.029 222.669 130 217.698 130C212.728 130 208.698 134.029 208.698 139C208.698 143.971 212.728 148 217.698 148Z" fill={greyGridDotColor}/>
            <m.path variants={ascendingVariants} d="M295.698 70C300.669 70 304.698 65.9706 304.698 61C304.698 56.0294 300.669 52 295.698 52C290.728 52 286.698 56.0294 286.698 61C286.698 65.9706 290.728 70 295.698 70Z" fill={greyGridDotColor}/>
          </m.g>

          <g>
            <path d="M9.69849 330C14.669 330 18.6985 325.971 18.6985 321C18.6985 316.029 14.669 312 9.69849 312C4.72792 312 0.698486 316.029 0.698486 321C0.698486 325.971 4.72792 330 9.69849 330Z" fill={bgGridDotColor}/>
            <path d="M35.6985 330C40.669 330 44.6985 325.971 44.6985 321C44.6985 316.029 40.669 312 35.6985 312C30.7279 312 26.6985 316.029 26.6985 321C26.6985 325.971 30.7279 330 35.6985 330Z" fill={bgGridDotColor}/>
            <path d="M61.6985 330C66.669 330 70.6985 325.971 70.6985 321C70.6985 316.029 66.669 312 61.6985 312C56.7279 312 52.6985 316.029 52.6985 321C52.6985 325.971 56.7279 330 61.6985 330Z" fill={bgGridDotColor}/>
            <path d="M87.6985 330C92.669 330 96.6985 325.971 96.6985 321C96.6985 316.029 92.669 312 87.6985 312C82.7279 312 78.6985 316.029 78.6985 321C78.6985 325.971 82.7279 330 87.6985 330Z" fill={bgGridDotColor}/>
            <path d="M113.698 330C118.669 330 122.698 325.971 122.698 321C122.698 316.029 118.669 312 113.698 312C108.728 312 104.698 316.029 104.698 321C104.698 325.971 108.728 330 113.698 330Z" fill={bgGridDotColor}/>
            <path d="M139.698 330C144.669 330 148.698 325.971 148.698 321C148.698 316.029 144.669 312 139.698 312C134.728 312 130.698 316.029 130.698 321C130.698 325.971 134.728 330 139.698 330Z" fill={bgGridDotColor}/>
            <path d="M165.698 330C170.669 330 174.698 325.971 174.698 321C174.698 316.029 170.669 312 165.698 312C160.728 312 156.698 316.029 156.698 321C156.698 325.971 160.728 330 165.698 330Z" fill={bgGridDotColor}/>
            <path d="M191.698 330C196.669 330 200.698 325.971 200.698 321C200.698 316.029 196.669 312 191.698 312C186.728 312 182.698 316.029 182.698 321C182.698 325.971 186.728 330 191.698 330Z" fill={bgGridDotColor}/>
            <path d="M217.698 330C222.669 330 226.698 325.971 226.698 321C226.698 316.029 222.669 312 217.698 312C212.728 312 208.698 316.029 208.698 321C208.698 325.971 212.728 330 217.698 330Z" fill={bgGridDotColor}/>
            <path d="M243.698 330C248.669 330 252.698 325.971 252.698 321C252.698 316.029 248.669 312 243.698 312C238.728 312 234.698 316.029 234.698 321C234.698 325.971 238.728 330 243.698 330Z" fill={bgGridDotColor}/>
            <path d="M269.698 330C274.669 330 278.698 325.971 278.698 321C278.698 316.029 274.669 312 269.698 312C264.728 312 260.698 316.029 260.698 321C260.698 325.971 264.728 330 269.698 330Z" fill={bgGridDotColor}/>
            <path d="M295.698 330C300.669 330 304.698 325.971 304.698 321C304.698 316.029 300.669 312 295.698 312C290.728 312 286.698 316.029 286.698 321C286.698 325.971 290.728 330 295.698 330Z" fill={bgGridDotColor}/>
            <path d="M321.698 330C326.669 330 330.698 325.971 330.698 321C330.698 316.029 326.669 312 321.698 312C316.728 312 312.698 316.029 312.698 321C312.698 325.971 316.728 330 321.698 330Z" fill={bgGridDotColor}/>
            <path d="M347.698 330C352.669 330 356.698 325.971 356.698 321C356.698 316.029 352.669 312 347.698 312C342.728 312 338.698 316.029 338.698 321C338.698 325.971 342.728 330 347.698 330Z" fill={bgGridDotColor}/>
            <path d="M373.698 330C378.669 330 382.698 325.971 382.698 321C382.698 316.029 378.669 312 373.698 312C368.728 312 364.698 316.029 364.698 321C364.698 325.971 368.728 330 373.698 330Z" fill={bgGridDotColor}/>
            <path d="M9.69849 304C14.669 304 18.6985 299.971 18.6985 295C18.6985 290.029 14.669 286 9.69849 286C4.72792 286 0.698486 290.029 0.698486 295C0.698486 299.971 4.72792 304 9.69849 304Z" fill={bgGridDotColor}/>
            <path d="M61.6985 304C66.669 304 70.6985 299.971 70.6985 295C70.6985 290.029 66.669 286 61.6985 286C56.7279 286 52.6985 290.029 52.6985 295C52.6985 299.971 56.7279 304 61.6985 304Z" fill={bgGridDotColor}/>
            <path d="M87.6985 304C92.669 304 96.6985 299.971 96.6985 295C96.6985 290.029 92.669 286 87.6985 286C82.7279 286 78.6985 290.029 78.6985 295C78.6985 299.971 82.7279 304 87.6985 304Z" fill={bgGridDotColor}/>
            <path d="M113.698 304C118.669 304 122.698 299.971 122.698 295C122.698 290.029 118.669 286 113.698 286C108.728 286 104.698 290.029 104.698 295C104.698 299.971 108.728 304 113.698 304Z" fill={greyGridDotColor}/>
            <path d="M139.698 304C144.669 304 148.698 299.971 148.698 295C148.698 290.029 144.669 286 139.698 286C134.728 286 130.698 290.029 130.698 295C130.698 299.971 134.728 304 139.698 304Z" fill={bgGridDotColor}/>
            <path d="M165.698 304C170.669 304 174.698 299.971 174.698 295C174.698 290.029 170.669 286 165.698 286C160.728 286 156.698 290.029 156.698 295C156.698 299.971 160.728 304 165.698 304Z" fill={bgGridDotColor}/>
            <path d="M191.698 304C196.669 304 200.698 299.971 200.698 295C200.698 290.029 196.669 286 191.698 286C186.728 286 182.698 290.029 182.698 295C182.698 299.971 186.728 304 191.698 304Z" fill={greyGridDotColor}/>
            <path d="M217.698 304C222.669 304 226.698 299.971 226.698 295C226.698 290.029 222.669 286 217.698 286C212.728 286 208.698 290.029 208.698 295C208.698 299.971 212.728 304 217.698 304Z" fill={bgGridDotColor}/>
            <path d="M243.698 304C248.669 304 252.698 299.971 252.698 295C252.698 290.029 248.669 286 243.698 286C238.728 286 234.698 290.029 234.698 295C234.698 299.971 238.728 304 243.698 304Z" fill={bgGridDotColor}/>
            <path d="M269.698 304C274.669 304 278.698 299.971 278.698 295C278.698 290.029 274.669 286 269.698 286C264.728 286 260.698 290.029 260.698 295C260.698 299.971 264.728 304 269.698 304Z" fill={bgGridDotColor}/>
            <path d="M295.698 304C300.669 304 304.698 299.971 304.698 295C304.698 290.029 300.669 286 295.698 286C290.728 286 286.698 290.029 286.698 295C286.698 299.971 290.728 304 295.698 304Z" fill={bgGridDotColor}/>
            <path d="M321.698 304C326.669 304 330.698 299.971 330.698 295C330.698 290.029 326.669 286 321.698 286C316.728 286 312.698 290.029 312.698 295C312.698 299.971 316.728 304 321.698 304Z" fill={bgGridDotColor}/>
            <path d="M347.698 304C352.669 304 356.698 299.971 356.698 295C356.698 290.029 352.669 286 347.698 286C342.728 286 338.698 290.029 338.698 295C338.698 299.971 342.728 304 347.698 304Z" fill={bgGridDotColor}/>
            <path d="M373.698 304C378.669 304 382.698 299.971 382.698 295C382.698 290.029 378.669 286 373.698 286C368.728 286 364.698 290.029 364.698 295C364.698 299.971 368.728 304 373.698 304Z" fill={bgGridDotColor}/>
            <path d="M9.69849 278C14.669 278 18.6985 273.971 18.6985 269C18.6985 264.029 14.669 260 9.69849 260C4.72792 260 0.698486 264.029 0.698486 269C0.698486 273.971 4.72792 278 9.69849 278Z" fill={bgGridDotColor}/>
            <path d="M35.6985 278C40.669 278 44.6985 273.971 44.6985 269C44.6985 264.029 40.669 260 35.6985 260C30.7279 260 26.6985 264.029 26.6985 269C26.6985 273.971 30.7279 278 35.6985 278Z" fill={bgGridDotColor}/>
            <path d="M61.6985 278C66.669 278 70.6985 273.971 70.6985 269C70.6985 264.029 66.669 260 61.6985 260C56.7279 260 52.6985 264.029 52.6985 269C52.6985 273.971 56.7279 278 61.6985 278Z" fill={bgGridDotColor}/>
            <path d="M113.698 278C118.669 278 122.698 273.971 122.698 269C122.698 264.029 118.669 260 113.698 260C108.728 260 104.698 264.029 104.698 269C104.698 273.971 108.728 278 113.698 278Z" fill={bgGridDotColor}/>
            <path d="M139.698 278C144.669 278 148.698 273.971 148.698 269C148.698 264.029 144.669 260 139.698 260C134.728 260 130.698 264.029 130.698 269C130.698 273.971 134.728 278 139.698 278Z" fill={bgGridDotColor}/>
            <path d="M165.698 278C170.669 278 174.698 273.971 174.698 269C174.698 264.029 170.669 260 165.698 260C160.728 260 156.698 264.029 156.698 269C156.698 273.971 160.728 278 165.698 278Z" fill={bgGridDotColor}/>
            <path d="M191.698 278C196.669 278 200.698 273.971 200.698 269C200.698 264.029 196.669 260 191.698 260C186.728 260 182.698 264.029 182.698 269C182.698 273.971 186.728 278 191.698 278Z" fill={bgGridDotColor}/>
            <path d="M217.698 278C222.669 278 226.698 273.971 226.698 269C226.698 264.029 222.669 260 217.698 260C212.728 260 208.698 264.029 208.698 269C208.698 273.971 212.728 278 217.698 278Z" fill={greyGridDotColor}/>
            <path d="M243.698 278C248.669 278 252.698 273.971 252.698 269C252.698 264.029 248.669 260 243.698 260C238.728 260 234.698 264.029 234.698 269C234.698 273.971 238.728 278 243.698 278Z" fill={bgGridDotColor}/>
            <path d="M269.698 278C274.669 278 278.698 273.971 278.698 269C278.698 264.029 274.669 260 269.698 260C264.728 260 260.698 264.029 260.698 269C260.698 273.971 264.728 278 269.698 278Z" fill={bgGridDotColor}/>
            <path d="M295.698 278C300.669 278 304.698 273.971 304.698 269C304.698 264.029 300.669 260 295.698 260C290.728 260 286.698 264.029 286.698 269C286.698 273.971 290.728 278 295.698 278Z" fill={bgGridDotColor}/>
            <path d="M321.698 278C326.669 278 330.698 273.971 330.698 269C330.698 264.029 326.669 260 321.698 260C316.728 260 312.698 264.029 312.698 269C312.698 273.971 316.728 278 321.698 278Z" fill={bgGridDotColor}/>
            <path d="M347.698 278C352.669 278 356.698 273.971 356.698 269C356.698 264.029 352.669 260 347.698 260C342.728 260 338.698 264.029 338.698 269C338.698 273.971 342.728 278 347.698 278Z" fill={bgGridDotColor}/>
            <path d="M373.698 278C378.669 278 382.698 273.971 382.698 269C382.698 264.029 378.669 260 373.698 260C368.728 260 364.698 264.029 364.698 269C364.698 273.971 368.728 278 373.698 278Z" fill={bgGridDotColor}/>
            <path d="M9.69849 252C14.669 252 18.6985 247.971 18.6985 243C18.6985 238.029 14.669 234 9.69849 234C4.72792 234 0.698486 238.029 0.698486 243C0.698486 247.971 4.72792 252 9.69849 252Z" fill={bgGridDotColor}/>
            <path d="M35.6985 252C40.669 252 44.6985 247.971 44.6985 243C44.6985 238.029 40.669 234 35.6985 234C30.7279 234 26.6985 238.029 26.6985 243C26.6985 247.971 30.7279 252 35.6985 252Z" fill={bgGridDotColor}/>
            <path d="M61.6985 252C66.669 252 70.6985 247.971 70.6985 243C70.6985 238.029 66.669 234 61.6985 234C56.7279 234 52.6985 238.029 52.6985 243C52.6985 247.971 56.7279 252 61.6985 252Z" fill={bgGridDotColor}/>
            <path d="M87.6985 252C92.669 252 96.6985 247.971 96.6985 243C96.6985 238.029 92.669 234 87.6985 234C82.7279 234 78.6985 238.029 78.6985 243C78.6985 247.971 82.7279 252 87.6985 252Z" fill={bgGridDotColor}/>
            <path d="M113.698 252C118.669 252 122.698 247.971 122.698 243C122.698 238.029 118.669 234 113.698 234C108.728 234 104.698 238.029 104.698 243C104.698 247.971 108.728 252 113.698 252Z" fill={bgGridDotColor}/>
            <path d="M139.698 252C144.669 252 148.698 247.971 148.698 243C148.698 238.029 144.669 234 139.698 234C134.728 234 130.698 238.029 130.698 243C130.698 247.971 134.728 252 139.698 252Z" fill={bgGridDotColor}/>
            <path d="M165.698 252C170.669 252 174.698 247.971 174.698 243C174.698 238.029 170.669 234 165.698 234C160.728 234 156.698 238.029 156.698 243C156.698 247.971 160.728 252 165.698 252Z" fill={greyGridDotColor}/>
            <path d="M191.698 252C196.669 252 200.698 247.971 200.698 243C200.698 238.029 196.669 234 191.698 234C186.728 234 182.698 238.029 182.698 243C182.698 247.971 186.728 252 191.698 252Z" fill={greyGridDotColor}/>
            <path d="M217.698 252C222.669 252 226.698 247.971 226.698 243C226.698 238.029 222.669 234 217.698 234C212.728 234 208.698 238.029 208.698 243C208.698 247.971 212.728 252 217.698 252Z" fill={bgGridDotColor}/>
            <path d="M243.698 252C248.669 252 252.698 247.971 252.698 243C252.698 238.029 248.669 234 243.698 234C238.728 234 234.698 238.029 234.698 243C234.698 247.971 238.728 252 243.698 252Z" fill={bgGridDotColor}/>
            <path d="M269.698 252C274.669 252 278.698 247.971 278.698 243C278.698 238.029 274.669 234 269.698 234C264.728 234 260.698 238.029 260.698 243C260.698 247.971 264.728 252 269.698 252Z" fill={greyGridDotColor}/>
            <path d="M295.698 252C300.669 252 304.698 247.971 304.698 243C304.698 238.029 300.669 234 295.698 234C290.728 234 286.698 238.029 286.698 243C286.698 247.971 290.728 252 295.698 252Z" fill={bgGridDotColor}/>
            <path d="M321.698 252C326.669 252 330.698 247.971 330.698 243C330.698 238.029 326.669 234 321.698 234C316.728 234 312.698 238.029 312.698 243C312.698 247.971 316.728 252 321.698 252Z" fill={bgGridDotColor}/>
            <path d="M347.698 252C352.669 252 356.698 247.971 356.698 243C356.698 238.029 352.669 234 347.698 234C342.728 234 338.698 238.029 338.698 243C338.698 247.971 342.728 252 347.698 252Z" fill={bgGridDotColor}/>
            <path d="M373.698 252C378.669 252 382.698 247.971 382.698 243C382.698 238.029 378.669 234 373.698 234C368.728 234 364.698 238.029 364.698 243C364.698 247.971 368.728 252 373.698 252Z" fill={bgGridDotColor}/>
            <path d="M9.69849 226C14.669 226 18.6985 221.971 18.6985 217C18.6985 212.029 14.669 208 9.69849 208C4.72792 208 0.698486 212.029 0.698486 217C0.698486 221.971 4.72792 226 9.69849 226Z" fill={bgGridDotColor}/>
            <path d="M35.6985 226C40.669 226 44.6985 221.971 44.6985 217C44.6985 212.029 40.669 208 35.6985 208C30.7279 208 26.6985 212.029 26.6985 217C26.6985 221.971 30.7279 226 35.6985 226Z" fill={bgGridDotColor}/>
            <path d="M61.6985 226C66.669 226 70.6985 221.971 70.6985 217C70.6985 212.029 66.669 208 61.6985 208C56.7279 208 52.6985 212.029 52.6985 217C52.6985 221.971 56.7279 226 61.6985 226Z" fill={bgGridDotColor}/>
            <path d="M87.6985 226C92.669 226 96.6985 221.971 96.6985 217C96.6985 212.029 92.669 208 87.6985 208C82.7279 208 78.6985 212.029 78.6985 217C78.6985 221.971 82.7279 226 87.6985 226Z" fill={bgGridDotColor}/>
            <path d="M139.698 226C144.669 226 148.698 221.971 148.698 217C148.698 212.029 144.669 208 139.698 208C134.728 208 130.698 212.029 130.698 217C130.698 221.971 134.728 226 139.698 226Z" fill={bgGridDotColor}/>
            <path d="M165.698 226C170.669 226 174.698 221.971 174.698 217C174.698 212.029 170.669 208 165.698 208C160.728 208 156.698 212.029 156.698 217C156.698 221.971 160.728 226 165.698 226Z" fill={bgGridDotColor}/>
            <path d="M191.698 226C196.669 226 200.698 221.971 200.698 217C200.698 212.029 196.669 208 191.698 208C186.728 208 182.698 212.029 182.698 217C182.698 221.971 186.728 226 191.698 226Z" fill={bgGridDotColor}/>
            <path d="M217.698 226C222.669 226 226.698 221.971 226.698 217C226.698 212.029 222.669 208 217.698 208C212.728 208 208.698 212.029 208.698 217C208.698 221.971 212.728 226 217.698 226Z" fill={bgGridDotColor}/>
            <path d="M243.698 226C248.669 226 252.698 221.971 252.698 217C252.698 212.029 248.669 208 243.698 208C238.728 208 234.698 212.029 234.698 217C234.698 221.971 238.728 226 243.698 226Z" fill={bgGridDotColor}/>
            <path d="M269.698 226C274.669 226 278.698 221.971 278.698 217C278.698 212.029 274.669 208 269.698 208C264.728 208 260.698 212.029 260.698 217C260.698 221.971 264.728 226 269.698 226Z" fill={bgGridDotColor}/>
            <path d="M295.698 226C300.669 226 304.698 221.971 304.698 217C304.698 212.029 300.669 208 295.698 208C290.728 208 286.698 212.029 286.698 217C286.698 221.971 290.728 226 295.698 226Z" fill={greyGridDotColor}/>
            <path d="M321.698 226C326.669 226 330.698 221.971 330.698 217C330.698 212.029 326.669 208 321.698 208C316.728 208 312.698 212.029 312.698 217C312.698 221.971 316.728 226 321.698 226Z" fill={bgGridDotColor}/>
            <path d="M347.698 226C352.669 226 356.698 221.971 356.698 217C356.698 212.029 352.669 208 347.698 208C342.728 208 338.698 212.029 338.698 217C338.698 221.971 342.728 226 347.698 226Z" fill={bgGridDotColor}/>
            <path d="M373.698 226C378.669 226 382.698 221.971 382.698 217C382.698 212.029 378.669 208 373.698 208C368.728 208 364.698 212.029 364.698 217C364.698 221.971 368.728 226 373.698 226Z" fill={bgGridDotColor}/>
            <path d="M9.69849 200C14.669 200 18.6985 195.971 18.6985 191C18.6985 186.029 14.669 182 9.69849 182C4.72792 182 0.698486 186.029 0.698486 191C0.698486 195.971 4.72792 200 9.69849 200Z" fill={bgGridDotColor}/>
            <path d="M35.6985 200C40.669 200 44.6985 195.971 44.6985 191C44.6985 186.029 40.669 182 35.6985 182C30.7279 182 26.6985 186.029 26.6985 191C26.6985 195.971 30.7279 200 35.6985 200Z" fill={bgGridDotColor}/>
            <path d="M61.6985 200C66.669 200 70.6985 195.971 70.6985 191C70.6985 186.029 66.669 182 61.6985 182C56.7279 182 52.6985 186.029 52.6985 191C52.6985 195.971 56.7279 200 61.6985 200Z" fill={greyGridDotColor}/>
            <path d="M87.6985 200C92.669 200 96.6985 195.971 96.6985 191C96.6985 186.029 92.669 182 87.6985 182C82.7279 182 78.6985 186.029 78.6985 191C78.6985 195.971 82.7279 200 87.6985 200Z" fill={bgGridDotColor}/>
            <path d="M113.698 200C118.669 200 122.698 195.971 122.698 191C122.698 186.029 118.669 182 113.698 182C108.728 182 104.698 186.029 104.698 191C104.698 195.971 108.728 200 113.698 200Z" fill={bgGridDotColor}/>
            <path d="M139.698 200C144.669 200 148.698 195.971 148.698 191C148.698 186.029 144.669 182 139.698 182C134.728 182 130.698 186.029 130.698 191C130.698 195.971 134.728 200 139.698 200Z" fill={bgGridDotColor}/>
            <path d="M165.698 200C170.669 200 174.698 195.971 174.698 191C174.698 186.029 170.669 182 165.698 182C160.728 182 156.698 186.029 156.698 191C156.698 195.971 160.728 200 165.698 200Z" fill={bgGridDotColor}/>
            <path d="M191.698 200C196.669 200 200.698 195.971 200.698 191C200.698 186.029 196.669 182 191.698 182C186.728 182 182.698 186.029 182.698 191C182.698 195.971 186.728 200 191.698 200Z" fill={bgGridDotColor}/>
            <path d="M217.698 200C222.669 200 226.698 195.971 226.698 191C226.698 186.029 222.669 182 217.698 182C212.728 182 208.698 186.029 208.698 191C208.698 195.971 212.728 200 217.698 200Z" fill={greyGridDotColor}/>
            <path d="M243.698 200C248.669 200 252.698 195.971 252.698 191C252.698 186.029 248.669 182 243.698 182C238.728 182 234.698 186.029 234.698 191C234.698 195.971 238.728 200 243.698 200Z" fill={bgGridDotColor}/>
            <path d="M269.698 200C274.669 200 278.698 195.971 278.698 191C278.698 186.029 274.669 182 269.698 182C264.728 182 260.698 186.029 260.698 191C260.698 195.971 264.728 200 269.698 200Z" fill={bgGridDotColor}/>
            <path d="M295.698 200C300.669 200 304.698 195.971 304.698 191C304.698 186.029 300.669 182 295.698 182C290.728 182 286.698 186.029 286.698 191C286.698 195.971 290.728 200 295.698 200Z" fill={bgGridDotColor}/>
            <path d="M321.698 200C326.669 200 330.698 195.971 330.698 191C330.698 186.029 326.669 182 321.698 182C316.728 182 312.698 186.029 312.698 191C312.698 195.971 316.728 200 321.698 200Z" fill={bgGridDotColor}/>
            <path d="M347.698 200C352.669 200 356.698 195.971 356.698 191C356.698 186.029 352.669 182 347.698 182C342.728 182 338.698 186.029 338.698 191C338.698 195.971 342.728 200 347.698 200Z" fill={bgGridDotColor}/>
            <path d="M373.698 200C378.669 200 382.698 195.971 382.698 191C382.698 186.029 378.669 182 373.698 182C368.728 182 364.698 186.029 364.698 191C364.698 195.971 368.728 200 373.698 200Z" fill={bgGridDotColor}/>
            <path d="M9.69849 174C14.669 174 18.6985 169.971 18.6985 165C18.6985 160.029 14.669 156 9.69849 156C4.72792 156 0.698486 160.029 0.698486 165C0.698486 169.971 4.72792 174 9.69849 174Z" fill={bgGridDotColor}/>
            <path d="M35.6985 174C40.669 174 44.6985 169.971 44.6985 165C44.6985 160.029 40.669 156 35.6985 156C30.7279 156 26.6985 160.029 26.6985 165C26.6985 169.971 30.7279 174 35.6985 174Z" fill={bgGridDotColor}/>
            <path d="M61.6985 174C66.669 174 70.6985 169.971 70.6985 165C70.6985 160.029 66.669 156 61.6985 156C56.7279 156 52.6985 160.029 52.6985 165C52.6985 169.971 56.7279 174 61.6985 174Z" fill={bgGridDotColor}/>
            <path d="M87.6985 174C92.669 174 96.6985 169.971 96.6985 165C96.6985 160.029 92.669 156 87.6985 156C82.7279 156 78.6985 160.029 78.6985 165C78.6985 169.971 82.7279 174 87.6985 174Z" fill={bgGridDotColor}/>
            <path d="M113.698 174C118.669 174 122.698 169.971 122.698 165C122.698 160.029 118.669 156 113.698 156C108.728 156 104.698 160.029 104.698 165C104.698 169.971 108.728 174 113.698 174Z" fill={bgGridDotColor}/>
            <path d="M139.698 174C144.669 174 148.698 169.971 148.698 165C148.698 160.029 144.669 156 139.698 156C134.728 156 130.698 160.029 130.698 165C130.698 169.971 134.728 174 139.698 174Z" fill={bgGridDotColor}/>
            <path d="M191.698 174C196.669 174 200.698 169.971 200.698 165C200.698 160.029 196.669 156 191.698 156C186.728 156 182.698 160.029 182.698 165C182.698 169.971 186.728 174 191.698 174Z" fill={bgGridDotColor}/>
            <path d="M217.698 174C222.669 174 226.698 169.971 226.698 165C226.698 160.029 222.669 156 217.698 156C212.728 156 208.698 160.029 208.698 165C208.698 169.971 212.728 174 217.698 174Z" fill={bgGridDotColor}/>
            <path d="M243.698 174C248.669 174 252.698 169.971 252.698 165C252.698 160.029 248.669 156 243.698 156C238.728 156 234.698 160.029 234.698 165C234.698 169.971 238.728 174 243.698 174Z" fill={bgGridDotColor}/>
            <path d="M269.698 174C274.669 174 278.698 169.971 278.698 165C278.698 160.029 274.669 156 269.698 156C264.728 156 260.698 160.029 260.698 165C260.698 169.971 264.728 174 269.698 174Z" fill={bgGridDotColor}/>
            <path d="M295.698 174C300.669 174 304.698 169.971 304.698 165C304.698 160.029 300.669 156 295.698 156C290.728 156 286.698 160.029 286.698 165C286.698 169.971 290.728 174 295.698 174Z" fill={greyGridDotColor}/>
            <path d="M321.698 174C326.669 174 330.698 169.971 330.698 165C330.698 160.029 326.669 156 321.698 156C316.728 156 312.698 160.029 312.698 165C312.698 169.971 316.728 174 321.698 174Z" fill={bgGridDotColor}/>
            <path d="M347.698 174C352.669 174 356.698 169.971 356.698 165C356.698 160.029 352.669 156 347.698 156C342.728 156 338.698 160.029 338.698 165C338.698 169.971 342.728 174 347.698 174Z" fill={bgGridDotColor}/>
            <path d="M373.698 174C378.669 174 382.698 169.971 382.698 165C382.698 160.029 378.669 156 373.698 156C368.728 156 364.698 160.029 364.698 165C364.698 169.971 368.728 174 373.698 174Z" fill={bgGridDotColor}/>
            <path d="M9.69849 148C14.669 148 18.6985 143.971 18.6985 139C18.6985 134.029 14.669 130 9.69849 130C4.72792 130 0.698486 134.029 0.698486 139C0.698486 143.971 4.72792 148 9.69849 148Z" fill={bgGridDotColor}/>
            <path d="M35.6985 148C40.669 148 44.6985 143.971 44.6985 139C44.6985 134.029 40.669 130 35.6985 130C30.7279 130 26.6985 134.029 26.6985 139C26.6985 143.971 30.7279 148 35.6985 148Z" fill={bgGridDotColor}/>
            <path d="M61.6985 148C66.669 148 70.6985 143.971 70.6985 139C70.6985 134.029 66.669 130 61.6985 130C56.7279 130 52.6985 134.029 52.6985 139C52.6985 143.971 56.7279 148 61.6985 148Z" fill={bgGridDotColor}/>
            <path d="M87.6985 148C92.669 148 96.6985 143.971 96.6985 139C96.6985 134.029 92.669 130 87.6985 130C82.7279 130 78.6985 134.029 78.6985 139C78.6985 143.971 82.7279 148 87.6985 148Z" fill={bgGridDotColor}/>
            <path d="M113.698 148C118.669 148 122.698 143.971 122.698 139C122.698 134.029 118.669 130 113.698 130C108.728 130 104.698 134.029 104.698 139C104.698 143.971 108.728 148 113.698 148Z" fill={bgGridDotColor}/>
            <path d="M139.698 148C144.669 148 148.698 143.971 148.698 139C148.698 134.029 144.669 130 139.698 130C134.728 130 130.698 134.029 130.698 139C130.698 143.971 134.728 148 139.698 148Z" fill={bgGridDotColor}/>
            <path d="M165.698 148C170.669 148 174.698 143.971 174.698 139C174.698 134.029 170.669 130 165.698 130C160.728 130 156.698 134.029 156.698 139C156.698 143.971 160.728 148 165.698 148Z" fill={bgGridDotColor}/>
            <path d="M191.698 148C196.669 148 200.698 143.971 200.698 139C200.698 134.029 196.669 130 191.698 130C186.728 130 182.698 134.029 182.698 139C182.698 143.971 186.728 148 191.698 148Z" fill={greyGridDotColor}/>
            <path d="M243.698 148C248.669 148 252.698 143.971 252.698 139C252.698 134.029 248.669 130 243.698 130C238.728 130 234.698 134.029 234.698 139C234.698 143.971 238.728 148 243.698 148Z" fill={bgGridDotColor}/>
            <path d="M269.698 148C274.669 148 278.698 143.971 278.698 139C278.698 134.029 274.669 130 269.698 130C264.728 130 260.698 134.029 260.698 139C260.698 143.971 264.728 148 269.698 148Z" fill={bgGridDotColor}/>
            <path d="M295.698 148C300.669 148 304.698 143.971 304.698 139C304.698 134.029 300.669 130 295.698 130C290.728 130 286.698 134.029 286.698 139C286.698 143.971 290.728 148 295.698 148Z" fill={bgGridDotColor}/>
            <path d="M321.698 148C326.669 148 330.698 143.971 330.698 139C330.698 134.029 326.669 130 321.698 130C316.728 130 312.698 134.029 312.698 139C312.698 143.971 316.728 148 321.698 148Z" fill={greyGridDotColor}/>
            <path d="M347.698 148C352.669 148 356.698 143.971 356.698 139C356.698 134.029 352.669 130 347.698 130C342.728 130 338.698 134.029 338.698 139C338.698 143.971 342.728 148 347.698 148Z" fill={bgGridDotColor}/>
            <path d="M373.698 148C378.669 148 382.698 143.971 382.698 139C382.698 134.029 378.669 130 373.698 130C368.728 130 364.698 134.029 364.698 139C364.698 143.971 368.728 148 373.698 148Z" fill={bgGridDotColor}/>
            <path d="M9.69849 122C14.669 122 18.6985 117.971 18.6985 113C18.6985 108.029 14.669 104 9.69849 104C4.72792 104 0.698486 108.029 0.698486 113C0.698486 117.971 4.72792 122 9.69849 122Z" fill={bgGridDotColor}/>
            <path d="M35.6985 122C40.669 122 44.6985 117.971 44.6985 113C44.6985 108.029 40.669 104 35.6985 104C30.7279 104 26.6985 108.029 26.6985 113C26.6985 117.971 30.7279 122 35.6985 122Z" fill={bgGridDotColor}/>
            <path d="M61.6985 122C66.669 122 70.6985 117.971 70.6985 113C70.6985 108.029 66.669 104 61.6985 104C56.7279 104 52.6985 108.029 52.6985 113C52.6985 117.971 56.7279 122 61.6985 122Z" fill={bgGridDotColor}/>
            <path d="M87.6985 122C92.669 122 96.6985 117.971 96.6985 113C96.6985 108.029 92.669 104 87.6985 104C82.7279 104 78.6985 108.029 78.6985 113C78.6985 117.971 82.7279 122 87.6985 122Z" fill={bgGridDotColor}/>
            <path d="M113.698 122C118.669 122 122.698 117.971 122.698 113C122.698 108.029 118.669 104 113.698 104C108.728 104 104.698 108.029 104.698 113C104.698 117.971 108.728 122 113.698 122Z" fill={bgGridDotColor}/>
            <path d="M139.698 122C144.669 122 148.698 117.971 148.698 113C148.698 108.029 144.669 104 139.698 104C134.728 104 130.698 108.029 130.698 113C130.698 117.971 134.728 122 139.698 122Z" fill={bgGridDotColor}/>
            <path d="M165.698 122C170.669 122 174.698 117.971 174.698 113C174.698 108.029 170.669 104 165.698 104C160.728 104 156.698 108.029 156.698 113C156.698 117.971 160.728 122 165.698 122Z" fill={bgGridDotColor}/>
            <path d="M191.698 122C196.669 122 200.698 117.971 200.698 113C200.698 108.029 196.669 104 191.698 104C186.728 104 182.698 108.029 182.698 113C182.698 117.971 186.728 122 191.698 122Z" fill={bgGridDotColor}/>
            <path d="M217.698 122C222.669 122 226.698 117.971 226.698 113C226.698 108.029 222.669 104 217.698 104C212.728 104 208.698 108.029 208.698 113C208.698 117.971 212.728 122 217.698 122Z" fill={bgGridDotColor}/>
            <path d="M243.698 122C248.669 122 252.698 117.971 252.698 113C252.698 108.029 248.669 104 243.698 104C238.728 104 234.698 108.029 234.698 113C234.698 117.971 238.728 122 243.698 122Z" fill={bgGridDotColor}/>
            <path d="M269.698 122C274.669 122 278.698 117.971 278.698 113C278.698 108.029 274.669 104 269.698 104C264.728 104 260.698 108.029 260.698 113C260.698 117.971 264.728 122 269.698 122Z" fill={bgGridDotColor}/>
            <path d="M295.698 122C300.669 122 304.698 117.971 304.698 113C304.698 108.029 300.669 104 295.698 104C290.728 104 286.698 108.029 286.698 113C286.698 117.971 290.728 122 295.698 122Z" fill={bgGridDotColor}/>
            <path d="M321.698 122C326.669 122 330.698 117.971 330.698 113C330.698 108.029 326.669 104 321.698 104C316.728 104 312.698 108.029 312.698 113C312.698 117.971 316.728 122 321.698 122Z" fill={bgGridDotColor}/>
            <path d="M347.698 122C352.669 122 356.698 117.971 356.698 113C356.698 108.029 352.669 104 347.698 104C342.728 104 338.698 108.029 338.698 113C338.698 117.971 342.728 122 347.698 122Z" fill={bgGridDotColor}/>
            <path d="M373.698 122C378.669 122 382.698 117.971 382.698 113C382.698 108.029 378.669 104 373.698 104C368.728 104 364.698 108.029 364.698 113C364.698 117.971 368.728 122 373.698 122Z" fill={bgGridDotColor}/>
            <path d="M9.69849 96C14.669 96 18.6985 91.9706 18.6985 87C18.6985 82.0294 14.669 78 9.69849 78C4.72792 78 0.698486 82.0294 0.698486 87C0.698486 91.9706 4.72792 96 9.69849 96Z" fill={bgGridDotColor}/>
            <path d="M35.6985 96C40.669 96 44.6985 91.9706 44.6985 87C44.6985 82.0294 40.669 78 35.6985 78C30.7279 78 26.6985 82.0294 26.6985 87C26.6985 91.9706 30.7279 96 35.6985 96Z" fill={bgGridDotColor}/>
            <path d="M61.6985 96C66.669 96 70.6985 91.9706 70.6985 87C70.6985 82.0294 66.669 78 61.6985 78C56.7279 78 52.6985 82.0294 52.6985 87C52.6985 91.9706 56.7279 96 61.6985 96Z" fill={bgGridDotColor}/>
            <path d="M87.6985 96C92.669 96 96.6985 91.9706 96.6985 87C96.6985 82.0294 92.669 78 87.6985 78C82.7279 78 78.6985 82.0294 78.6985 87C78.6985 91.9706 82.7279 96 87.6985 96Z" fill={bgGridDotColor}/>
            <path d="M113.698 96C118.669 96 122.698 91.9706 122.698 87C122.698 82.0294 118.669 78 113.698 78C108.728 78 104.698 82.0294 104.698 87C104.698 91.9706 108.728 96 113.698 96Z" fill={bgGridDotColor}/>
            <path d="M139.698 96C144.669 96 148.698 91.9706 148.698 87C148.698 82.0294 144.669 78 139.698 78C134.728 78 130.698 82.0294 130.698 87C130.698 91.9706 134.728 96 139.698 96Z" fill={bgGridDotColor}/>
            <path d="M165.698 96C170.669 96 174.698 91.9706 174.698 87C174.698 82.0294 170.669 78 165.698 78C160.728 78 156.698 82.0294 156.698 87C156.698 91.9706 160.728 96 165.698 96Z" fill={bgGridDotColor}/>
            <path d="M191.698 96C196.669 96 200.698 91.9706 200.698 87C200.698 82.0294 196.669 78 191.698 78C186.728 78 182.698 82.0294 182.698 87C182.698 91.9706 186.728 96 191.698 96Z" fill={bgGridDotColor}/>
            <path d="M217.698 96C222.669 96 226.698 91.9706 226.698 87C226.698 82.0294 222.669 78 217.698 78C212.728 78 208.698 82.0294 208.698 87C208.698 91.9706 212.728 96 217.698 96Z" fill={greyGridDotColor}/>
            <path d="M243.698 96C248.669 96 252.698 91.9706 252.698 87C252.698 82.0294 248.669 78 243.698 78C238.728 78 234.698 82.0294 234.698 87C234.698 91.9706 238.728 96 243.698 96Z" fill={bgGridDotColor}/>
            <path d="M269.698 96C274.669 96 278.698 91.9706 278.698 87C278.698 82.0294 274.669 78 269.698 78C264.728 78 260.698 82.0294 260.698 87C260.698 91.9706 264.728 96 269.698 96Z" fill={greyGridDotColor}/>
            <path d="M295.698 96C300.669 96 304.698 91.9706 304.698 87C304.698 82.0294 300.669 78 295.698 78C290.728 78 286.698 82.0294 286.698 87C286.698 91.9706 290.728 96 295.698 96Z" fill={bgGridDotColor}/>
            <path d="M321.698 96C326.669 96 330.698 91.9706 330.698 87C330.698 82.0294 326.669 78 321.698 78C316.728 78 312.698 82.0294 312.698 87C312.698 91.9706 316.728 96 321.698 96Z" fill={bgGridDotColor}/>
            <path d="M347.698 96C352.669 96 356.698 91.9706 356.698 87C356.698 82.0294 352.669 78 347.698 78C342.728 78 338.698 82.0294 338.698 87C338.698 91.9706 342.728 96 347.698 96Z" fill={bgGridDotColor}/>
            <path d="M373.698 96C378.669 96 382.698 91.9706 382.698 87C382.698 82.0294 378.669 78 373.698 78C368.728 78 364.698 82.0294 364.698 87C364.698 91.9706 368.728 96 373.698 96Z" fill={bgGridDotColor}/>
            <path d="M9.69849 70C14.669 70 18.6985 65.9706 18.6985 61C18.6985 56.0294 14.669 52 9.69849 52C4.72792 52 0.698486 56.0294 0.698486 61C0.698486 65.9706 4.72792 70 9.69849 70Z" fill={bgGridDotColor}/>
            <path d="M35.6985 70C40.669 70 44.6985 65.9706 44.6985 61C44.6985 56.0294 40.669 52 35.6985 52C30.7279 52 26.6985 56.0294 26.6985 61C26.6985 65.9706 30.7279 70 35.6985 70Z" fill={bgGridDotColor}/>
            <path d="M61.6985 70C66.669 70 70.6985 65.9706 70.6985 61C70.6985 56.0294 66.669 52 61.6985 52C56.7279 52 52.6985 56.0294 52.6985 61C52.6985 65.9706 56.7279 70 61.6985 70Z" fill={bgGridDotColor}/>
            <path d="M87.6985 70C92.669 70 96.6985 65.9706 96.6985 61C96.6985 56.0294 92.669 52 87.6985 52C82.7279 52 78.6985 56.0294 78.6985 61C78.6985 65.9706 82.7279 70 87.6985 70Z" fill={bgGridDotColor}/>
            <path d="M113.698 70C118.669 70 122.698 65.9706 122.698 61C122.698 56.0294 118.669 52 113.698 52C108.728 52 104.698 56.0294 104.698 61C104.698 65.9706 108.728 70 113.698 70Z" fill={bgGridDotColor}/>
            <path d="M139.698 70C144.669 70 148.698 65.9706 148.698 61C148.698 56.0294 144.669 52 139.698 52C134.728 52 130.698 56.0294 130.698 61C130.698 65.9706 134.728 70 139.698 70Z" fill={bgGridDotColor}/>
            <path d="M165.698 70C170.669 70 174.698 65.9706 174.698 61C174.698 56.0294 170.669 52 165.698 52C160.728 52 156.698 56.0294 156.698 61C156.698 65.9706 160.728 70 165.698 70Z" fill={bgGridDotColor}/>
            <path d="M191.698 70C196.669 70 200.698 65.9706 200.698 61C200.698 56.0294 196.669 52 191.698 52C186.728 52 182.698 56.0294 182.698 61C182.698 65.9706 186.728 70 191.698 70Z" fill={bgGridDotColor}/>
            <path d="M217.698 70C222.669 70 226.698 65.9706 226.698 61C226.698 56.0294 222.669 52 217.698 52C212.728 52 208.698 56.0294 208.698 61C208.698 65.9706 212.728 70 217.698 70Z" fill={bgGridDotColor}/>
            <path d="M243.698 70C248.669 70 252.698 65.9706 252.698 61C252.698 56.0294 248.669 52 243.698 52C238.728 52 234.698 56.0294 234.698 61C234.698 65.9706 238.728 70 243.698 70Z" fill={bgGridDotColor}/>
            <path d="M269.698 70C274.669 70 278.698 65.9706 278.698 61C278.698 56.0294 274.669 52 269.698 52C264.728 52 260.698 56.0294 260.698 61C260.698 65.9706 264.728 70 269.698 70Z" fill={bgGridDotColor}/>
            <path d="M321.698 70C326.669 70 330.698 65.9706 330.698 61C330.698 56.0294 326.669 52 321.698 52C316.728 52 312.698 56.0294 312.698 61C312.698 65.9706 316.728 70 321.698 70Z" fill={bgGridDotColor}/>
            <path d="M347.698 70C352.669 70 356.698 65.9706 356.698 61C356.698 56.0294 352.669 52 347.698 52C342.728 52 338.698 56.0294 338.698 61C338.698 65.9706 342.728 70 347.698 70Z" fill={bgGridDotColor}/>
            <path d="M373.698 70C378.669 70 382.698 65.9706 382.698 61C382.698 56.0294 378.669 52 373.698 52C368.728 52 364.698 56.0294 364.698 61C364.698 65.9706 368.728 70 373.698 70Z" fill={bgGridDotColor}/>
            <path d="M9.69849 44C14.669 44 18.6985 39.9706 18.6985 35C18.6985 30.0294 14.669 26 9.69849 26C4.72792 26 0.698486 30.0294 0.698486 35C0.698486 39.9706 4.72792 44 9.69849 44Z" fill={bgGridDotColor}/>
            <path d="M35.6985 44C40.669 44 44.6985 39.9706 44.6985 35C44.6985 30.0294 40.669 26 35.6985 26C30.7279 26 26.6985 30.0294 26.6985 35C26.6985 39.9706 30.7279 44 35.6985 44Z" fill={bgGridDotColor}/>
            <path d="M61.6985 44C66.669 44 70.6985 39.9706 70.6985 35C70.6985 30.0294 66.669 26 61.6985 26C56.7279 26 52.6985 30.0294 52.6985 35C52.6985 39.9706 56.7279 44 61.6985 44Z" fill={bgGridDotColor}/>
            <path d="M87.6985 44C92.669 44 96.6985 39.9706 96.6985 35C96.6985 30.0294 92.669 26 87.6985 26C82.7279 26 78.6985 30.0294 78.6985 35C78.6985 39.9706 82.7279 44 87.6985 44Z" fill={bgGridDotColor}/>
            <path d="M113.698 44C118.669 44 122.698 39.9706 122.698 35C122.698 30.0294 118.669 26 113.698 26C108.728 26 104.698 30.0294 104.698 35C104.698 39.9706 108.728 44 113.698 44Z" fill={bgGridDotColor}/>
            <path d="M139.698 44C144.669 44 148.698 39.9706 148.698 35C148.698 30.0294 144.669 26 139.698 26C134.728 26 130.698 30.0294 130.698 35C130.698 39.9706 134.728 44 139.698 44Z" fill={bgGridDotColor}/>
            <path d="M165.698 44C170.669 44 174.698 39.9706 174.698 35C174.698 30.0294 170.669 26 165.698 26C160.728 26 156.698 30.0294 156.698 35C156.698 39.9706 160.728 44 165.698 44Z" fill={bgGridDotColor}/>
            <path d="M191.698 44C196.669 44 200.698 39.9706 200.698 35C200.698 30.0294 196.669 26 191.698 26C186.728 26 182.698 30.0294 182.698 35C182.698 39.9706 186.728 44 191.698 44Z" fill={bgGridDotColor}/>
            <path d="M217.698 44C222.669 44 226.698 39.9706 226.698 35C226.698 30.0294 222.669 26 217.698 26C212.728 26 208.698 30.0294 208.698 35C208.698 39.9706 212.728 44 217.698 44Z" fill={bgGridDotColor}/>
            <path d="M243.698 44C248.669 44 252.698 39.9706 252.698 35C252.698 30.0294 248.669 26 243.698 26C238.728 26 234.698 30.0294 234.698 35C234.698 39.9706 238.728 44 243.698 44Z" fill={bgGridDotColor}/>
            <path d="M269.698 44C274.669 44 278.698 39.9706 278.698 35C278.698 30.0294 274.669 26 269.698 26C264.728 26 260.698 30.0294 260.698 35C260.698 39.9706 264.728 44 269.698 44Z" fill={bgGridDotColor}/>
            <path d="M295.698 44C300.669 44 304.698 39.9706 304.698 35C304.698 30.0294 300.669 26 295.698 26C290.728 26 286.698 30.0294 286.698 35C286.698 39.9706 290.728 44 295.698 44Z" fill={bgGridDotColor}/>
            <path d="M347.698 44C352.669 44 356.698 39.9706 356.698 35C356.698 30.0294 352.669 26 347.698 26C342.728 26 338.698 30.0294 338.698 35C338.698 39.9706 342.728 44 347.698 44Z" fill={bgGridDotColor}/>
            <path d="M373.698 44C378.669 44 382.698 39.9706 382.698 35C382.698 30.0294 378.669 26 373.698 26C368.728 26 364.698 30.0294 364.698 35C364.698 39.9706 368.728 44 373.698 44Z" fill={bgGridDotColor}/>
            <path d="M9.69849 18C14.669 18 18.6985 13.9706 18.6985 9C18.6985 4.02944 14.669 0 9.69849 0C4.72792 0 0.698486 4.02944 0.698486 9C0.698486 13.9706 4.72792 18 9.69849 18Z" fill={bgGridDotColor}/>
            <path d="M35.6985 18C40.669 18 44.6985 13.9706 44.6985 9C44.6985 4.02944 40.669 0 35.6985 0C30.7279 0 26.6985 4.02944 26.6985 9C26.6985 13.9706 30.7279 18 35.6985 18Z" fill={bgGridDotColor}/>
            <path d="M61.6985 18C66.669 18 70.6985 13.9706 70.6985 9C70.6985 4.02944 66.669 0 61.6985 0C56.7279 0 52.6985 4.02944 52.6985 9C52.6985 13.9706 56.7279 18 61.6985 18Z" fill={bgGridDotColor}/>
            <path d="M87.6985 18C92.669 18 96.6985 13.9706 96.6985 9C96.6985 4.02944 92.669 0 87.6985 0C82.7279 0 78.6985 4.02944 78.6985 9C78.6985 13.9706 82.7279 18 87.6985 18Z" fill={bgGridDotColor}/>
            <path d="M113.698 18C118.669 18 122.698 13.9706 122.698 9C122.698 4.02944 118.669 0 113.698 0C108.728 0 104.698 4.02944 104.698 9C104.698 13.9706 108.728 18 113.698 18Z" fill={bgGridDotColor}/>
            <path d="M139.698 18C144.669 18 148.698 13.9706 148.698 9C148.698 4.02944 144.669 0 139.698 0C134.728 0 130.698 4.02944 130.698 9C130.698 13.9706 134.728 18 139.698 18Z" fill={bgGridDotColor}/>
            <path d="M165.698 18C170.669 18 174.698 13.9706 174.698 9C174.698 4.02944 170.669 0 165.698 0C160.728 0 156.698 4.02944 156.698 9C156.698 13.9706 160.728 18 165.698 18Z" fill={bgGridDotColor}/>
            <path d="M191.698 18C196.669 18 200.698 13.9706 200.698 9C200.698 4.02944 196.669 0 191.698 0C186.728 0 182.698 4.02944 182.698 9C182.698 13.9706 186.728 18 191.698 18Z" fill={bgGridDotColor}/>
            <path d="M217.698 18C222.669 18 226.698 13.9706 226.698 9C226.698 4.02944 222.669 0 217.698 0C212.728 0 208.698 4.02944 208.698 9C208.698 13.9706 212.728 18 217.698 18Z" fill={bgGridDotColor}/>
            <path d="M243.698 18C248.669 18 252.698 13.9706 252.698 9C252.698 4.02944 248.669 0 243.698 0C238.728 0 234.698 4.02944 234.698 9C234.698 13.9706 238.728 18 243.698 18Z" fill={bgGridDotColor}/>
            <path d="M269.698 18C274.669 18 278.698 13.9706 278.698 9C278.698 4.02944 274.669 0 269.698 0C264.728 0 260.698 4.02944 260.698 9C260.698 13.9706 264.728 18 269.698 18Z" fill={bgGridDotColor}/>
            <path d="M295.698 18C300.669 18 304.698 13.9706 304.698 9C304.698 4.02944 300.669 0 295.698 0C290.728 0 286.698 4.02944 286.698 9C286.698 13.9706 290.728 18 295.698 18Z" fill={bgGridDotColor}/>
            <path d="M321.698 18C326.669 18 330.698 13.9706 330.698 9C330.698 4.02944 326.669 0 321.698 0C316.728 0 312.698 4.02944 312.698 9C312.698 13.9706 316.728 18 321.698 18Z" fill={bgGridDotColor}/>
            <path d="M347.698 18C352.669 18 356.698 13.9706 356.698 9C356.698 4.02944 352.669 0 347.698 0C342.728 0 338.698 4.02944 338.698 9C338.698 13.9706 342.728 18 347.698 18Z" fill={bgGridDotColor}/>
            <path d="M373.698 18C378.669 18 382.698 13.9706 382.698 9C382.698 4.02944 378.669 0 373.698 0C368.728 0 364.698 4.02944 364.698 9C364.698 13.9706 368.728 18 373.698 18Z" fill={bgGridDotColor}/>
          </g>

          <m.path variants={pulseVariants} d="M321.698 44C326.669 44 330.698 39.9706 330.698 35C330.698 30.0294 326.669 26 321.698 26C316.728 26 312.698 30.0294 312.698 35C312.698 39.9706 316.728 44 321.698 44Z" fill={tealGridDotColor} fillOpacity="0.6"/>
          <m.path variants={finalVariants} d="M321.698 44C326.669 44 330.698 39.9706 330.698 35C330.698 30.0294 326.669 26 321.698 26C316.728 26 312.698 30.0294 312.698 35C312.698 39.9706 316.728 44 321.698 44Z" fill={greyGridDotColor}/>
        </m.svg>
      </LazyMotion>
    </div>
  )
}

export default OffersAnimation
