import {
  LazyMotion,
  domAnimation,
  m,
  AnimationControls,
  Variants,
} from 'framer-motion'
import { bgGridDotColor, greyGridDotColor, tealGridDotColor } from './constants'

const delays = [0, 0.09375, 0.21875, 0.34375, 0.40625, 0.45, 0.475, 0.5]

const checkVariants: Variants = {
  before: {
    fill: greyGridDotColor,
  },
  animate: (i) => ({
    transition: {
      duration: 0.5,
      delay: delays[i],
    },
    fill: tealGridDotColor,
  }),
}

const QualityAnimation = ({ controls }: { controls: AnimationControls }) => {
  return (
    <div>
      <LazyMotion features={domAnimation}>
        {/* prettier-ignore */}
        <m.svg
          initial="before"
          animate={controls}
          viewBox="0 0 382 330"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <m.path custom={0} variants={checkVariants} d="M87.3971 200C92.3677 200 96.3971 195.971 96.3971 191C96.3971 186.029 92.3677 182 87.3971 182C82.4265 182 78.3971 186.029 78.3971 191C78.3971 195.971 82.4265 200 87.3971 200Z" fill={greyGridDotColor}/>
          <m.path custom={1} variants={checkVariants} d="M113.397 226C118.368 226 122.397 221.971 122.397 217C122.397 212.029 118.368 208 113.397 208C108.427 208 104.397 212.029 104.397 217C104.397 221.971 108.427 226 113.397 226Z" fill={greyGridDotColor}/>
          <m.path custom={2} variants={checkVariants} d="M139.397 252C144.368 252 148.397 247.971 148.397 243C148.397 238.029 144.368 234 139.397 234C134.427 234 130.397 238.029 130.397 243C130.397 247.971 134.427 252 139.397 252Z" fill={greyGridDotColor}/>
          <m.path custom={3} variants={checkVariants} d="M165.397 226C170.368 226 174.397 221.971 174.397 217C174.397 212.029 170.368 208 165.397 208C160.427 208 156.397 212.029 156.397 217C156.397 221.971 160.427 226 165.397 226Z" fill={greyGridDotColor}/>
          <m.path custom={4} variants={checkVariants} d="M191.397 200C196.368 200 200.397 195.971 200.397 191C200.397 186.029 196.368 182 191.397 182C186.427 182 182.397 186.029 182.397 191C182.397 195.971 186.427 200 191.397 200Z" fill={greyGridDotColor}/>
          <m.path custom={5} variants={checkVariants} d="M217.397 174C222.368 174 226.397 169.971 226.397 165C226.397 160.029 222.368 156 217.397 156C212.427 156 208.397 160.029 208.397 165C208.397 169.971 212.427 174 217.397 174Z" fill={greyGridDotColor}/>
          <m.path custom={6} variants={checkVariants} d="M243.397 148C248.368 148 252.397 143.971 252.397 139C252.397 134.029 248.368 130 243.397 130C238.427 130 234.397 134.029 234.397 139C234.397 143.971 238.427 148 243.397 148Z" fill={greyGridDotColor}/>
          <m.path custom={7} variants={checkVariants} d="M269.397 122C274.368 122 278.397 117.971 278.397 113C278.397 108.029 274.368 104 269.397 104C264.427 104 260.397 108.029 260.397 113C260.397 117.971 264.427 122 269.397 122Z" fill={greyGridDotColor}/>

          <path d="M9.39697 330C14.3675 330 18.397 325.971 18.397 321C18.397 316.029 14.3675 312 9.39697 312C4.42641 312 0.396973 316.029 0.396973 321C0.396973 325.971 4.42641 330 9.39697 330Z" fill={bgGridDotColor}/>
          <path d="M35.397 330C40.3675 330 44.397 325.971 44.397 321C44.397 316.029 40.3675 312 35.397 312C30.4264 312 26.397 316.029 26.397 321C26.397 325.971 30.4264 330 35.397 330Z" fill={bgGridDotColor}/>
          <path d="M61.397 330C66.3675 330 70.397 325.971 70.397 321C70.397 316.029 66.3675 312 61.397 312C56.4264 312 52.397 316.029 52.397 321C52.397 325.971 56.4264 330 61.397 330Z" fill={bgGridDotColor}/>
          <path d="M87.397 330C92.3675 330 96.397 325.971 96.397 321C96.397 316.029 92.3675 312 87.397 312C82.4264 312 78.397 316.029 78.397 321C78.397 325.971 82.4264 330 87.397 330Z" fill={bgGridDotColor}/>
          <path d="M113.397 330C118.368 330 122.397 325.971 122.397 321C122.397 316.029 118.368 312 113.397 312C108.426 312 104.397 316.029 104.397 321C104.397 325.971 108.426 330 113.397 330Z" fill={bgGridDotColor}/>
          <path d="M139.397 330C144.368 330 148.397 325.971 148.397 321C148.397 316.029 144.368 312 139.397 312C134.426 312 130.397 316.029 130.397 321C130.397 325.971 134.426 330 139.397 330Z" fill={bgGridDotColor}/>
          <path d="M165.397 330C170.368 330 174.397 325.971 174.397 321C174.397 316.029 170.368 312 165.397 312C160.426 312 156.397 316.029 156.397 321C156.397 325.971 160.426 330 165.397 330Z" fill={bgGridDotColor}/>
          <path d="M191.397 330C196.368 330 200.397 325.971 200.397 321C200.397 316.029 196.368 312 191.397 312C186.426 312 182.397 316.029 182.397 321C182.397 325.971 186.426 330 191.397 330Z" fill={bgGridDotColor}/>
          <path d="M217.397 330C222.368 330 226.397 325.971 226.397 321C226.397 316.029 222.368 312 217.397 312C212.426 312 208.397 316.029 208.397 321C208.397 325.971 212.426 330 217.397 330Z" fill={bgGridDotColor}/>
          <path d="M243.397 330C248.368 330 252.397 325.971 252.397 321C252.397 316.029 248.368 312 243.397 312C238.426 312 234.397 316.029 234.397 321C234.397 325.971 238.426 330 243.397 330Z" fill={bgGridDotColor}/>
          <path d="M269.397 330C274.368 330 278.397 325.971 278.397 321C278.397 316.029 274.368 312 269.397 312C264.426 312 260.397 316.029 260.397 321C260.397 325.971 264.426 330 269.397 330Z" fill={bgGridDotColor}/>
          <path d="M295.397 330C300.368 330 304.397 325.971 304.397 321C304.397 316.029 300.368 312 295.397 312C290.426 312 286.397 316.029 286.397 321C286.397 325.971 290.426 330 295.397 330Z" fill={bgGridDotColor}/>
          <path d="M321.397 330C326.368 330 330.397 325.971 330.397 321C330.397 316.029 326.368 312 321.397 312C316.426 312 312.397 316.029 312.397 321C312.397 325.971 316.426 330 321.397 330Z" fill={bgGridDotColor}/>
          <path d="M347.397 330C352.368 330 356.397 325.971 356.397 321C356.397 316.029 352.368 312 347.397 312C342.426 312 338.397 316.029 338.397 321C338.397 325.971 342.426 330 347.397 330Z" fill={bgGridDotColor}/>
          <path d="M373.397 330C378.368 330 382.397 325.971 382.397 321C382.397 316.029 378.368 312 373.397 312C368.426 312 364.397 316.029 364.397 321C364.397 325.971 368.426 330 373.397 330Z" fill={bgGridDotColor}/>
          <path d="M9.39697 304C14.3675 304 18.397 299.971 18.397 295C18.397 290.029 14.3675 286 9.39697 286C4.42641 286 0.396973 290.029 0.396973 295C0.396973 299.971 4.42641 304 9.39697 304Z" fill={bgGridDotColor}/>
          <path d="M35.397 304C40.3675 304 44.397 299.971 44.397 295C44.397 290.029 40.3675 286 35.397 286C30.4264 286 26.397 290.029 26.397 295C26.397 299.971 30.4264 304 35.397 304Z" fill={bgGridDotColor}/>
          <path d="M61.397 304C66.3675 304 70.397 299.971 70.397 295C70.397 290.029 66.3675 286 61.397 286C56.4264 286 52.397 290.029 52.397 295C52.397 299.971 56.4264 304 61.397 304Z" fill={bgGridDotColor}/>
          <path d="M87.397 304C92.3675 304 96.397 299.971 96.397 295C96.397 290.029 92.3675 286 87.397 286C82.4264 286 78.397 290.029 78.397 295C78.397 299.971 82.4264 304 87.397 304Z" fill={bgGridDotColor}/>
          <path d="M113.397 304C118.368 304 122.397 299.971 122.397 295C122.397 290.029 118.368 286 113.397 286C108.426 286 104.397 290.029 104.397 295C104.397 299.971 108.426 304 113.397 304Z" fill={bgGridDotColor}/>
          <path d="M139.397 304C144.368 304 148.397 299.971 148.397 295C148.397 290.029 144.368 286 139.397 286C134.426 286 130.397 290.029 130.397 295C130.397 299.971 134.426 304 139.397 304Z" fill={bgGridDotColor}/>
          <path d="M165.397 304C170.368 304 174.397 299.971 174.397 295C174.397 290.029 170.368 286 165.397 286C160.426 286 156.397 290.029 156.397 295C156.397 299.971 160.426 304 165.397 304Z" fill={bgGridDotColor}/>
          <path d="M191.397 304C196.368 304 200.397 299.971 200.397 295C200.397 290.029 196.368 286 191.397 286C186.426 286 182.397 290.029 182.397 295C182.397 299.971 186.426 304 191.397 304Z" fill={bgGridDotColor}/>
          <path d="M217.397 304C222.368 304 226.397 299.971 226.397 295C226.397 290.029 222.368 286 217.397 286C212.426 286 208.397 290.029 208.397 295C208.397 299.971 212.426 304 217.397 304Z" fill={bgGridDotColor}/>
          <path d="M243.397 304C248.368 304 252.397 299.971 252.397 295C252.397 290.029 248.368 286 243.397 286C238.426 286 234.397 290.029 234.397 295C234.397 299.971 238.426 304 243.397 304Z" fill={bgGridDotColor}/>
          <path d="M269.397 304C274.368 304 278.397 299.971 278.397 295C278.397 290.029 274.368 286 269.397 286C264.426 286 260.397 290.029 260.397 295C260.397 299.971 264.426 304 269.397 304Z" fill={bgGridDotColor}/>
          <path d="M295.397 304C300.368 304 304.397 299.971 304.397 295C304.397 290.029 300.368 286 295.397 286C290.426 286 286.397 290.029 286.397 295C286.397 299.971 290.426 304 295.397 304Z" fill={bgGridDotColor}/>
          <path d="M321.397 304C326.368 304 330.397 299.971 330.397 295C330.397 290.029 326.368 286 321.397 286C316.426 286 312.397 290.029 312.397 295C312.397 299.971 316.426 304 321.397 304Z" fill={bgGridDotColor}/>
          <path d="M347.397 304C352.368 304 356.397 299.971 356.397 295C356.397 290.029 352.368 286 347.397 286C342.426 286 338.397 290.029 338.397 295C338.397 299.971 342.426 304 347.397 304Z" fill={bgGridDotColor}/>
          <path d="M373.397 304C378.368 304 382.397 299.971 382.397 295C382.397 290.029 378.368 286 373.397 286C368.426 286 364.397 290.029 364.397 295C364.397 299.971 368.426 304 373.397 304Z" fill={bgGridDotColor}/>
          <path d="M9.39697 278C14.3675 278 18.397 273.971 18.397 269C18.397 264.029 14.3675 260 9.39697 260C4.42641 260 0.396973 264.029 0.396973 269C0.396973 273.971 4.42641 278 9.39697 278Z" fill={bgGridDotColor}/>
          <path d="M35.397 278C40.3675 278 44.397 273.971 44.397 269C44.397 264.029 40.3675 260 35.397 260C30.4264 260 26.397 264.029 26.397 269C26.397 273.971 30.4264 278 35.397 278Z" fill={bgGridDotColor}/>
          <path d="M61.397 278C66.3675 278 70.397 273.971 70.397 269C70.397 264.029 66.3675 260 61.397 260C56.4264 260 52.397 264.029 52.397 269C52.397 273.971 56.4264 278 61.397 278Z" fill={bgGridDotColor}/>
          <path d="M87.397 278C92.3675 278 96.397 273.971 96.397 269C96.397 264.029 92.3675 260 87.397 260C82.4264 260 78.397 264.029 78.397 269C78.397 273.971 82.4264 278 87.397 278Z" fill={bgGridDotColor}/>
          <path d="M113.397 278C118.368 278 122.397 273.971 122.397 269C122.397 264.029 118.368 260 113.397 260C108.426 260 104.397 264.029 104.397 269C104.397 273.971 108.426 278 113.397 278Z" fill={bgGridDotColor}/>
          <path d="M139.397 278C144.368 278 148.397 273.971 148.397 269C148.397 264.029 144.368 260 139.397 260C134.426 260 130.397 264.029 130.397 269C130.397 273.971 134.426 278 139.397 278Z" fill={bgGridDotColor}/>
          <path d="M165.397 278C170.368 278 174.397 273.971 174.397 269C174.397 264.029 170.368 260 165.397 260C160.426 260 156.397 264.029 156.397 269C156.397 273.971 160.426 278 165.397 278Z" fill={bgGridDotColor}/>
          <path d="M191.397 278C196.368 278 200.397 273.971 200.397 269C200.397 264.029 196.368 260 191.397 260C186.426 260 182.397 264.029 182.397 269C182.397 273.971 186.426 278 191.397 278Z" fill={bgGridDotColor}/>
          <path d="M217.397 278C222.368 278 226.397 273.971 226.397 269C226.397 264.029 222.368 260 217.397 260C212.426 260 208.397 264.029 208.397 269C208.397 273.971 212.426 278 217.397 278Z" fill={bgGridDotColor}/>
          <path d="M243.397 278C248.368 278 252.397 273.971 252.397 269C252.397 264.029 248.368 260 243.397 260C238.426 260 234.397 264.029 234.397 269C234.397 273.971 238.426 278 243.397 278Z" fill={bgGridDotColor}/>
          <path d="M269.397 278C274.368 278 278.397 273.971 278.397 269C278.397 264.029 274.368 260 269.397 260C264.426 260 260.397 264.029 260.397 269C260.397 273.971 264.426 278 269.397 278Z" fill={bgGridDotColor}/>
          <path d="M295.397 278C300.368 278 304.397 273.971 304.397 269C304.397 264.029 300.368 260 295.397 260C290.426 260 286.397 264.029 286.397 269C286.397 273.971 290.426 278 295.397 278Z" fill={bgGridDotColor}/>
          <path d="M321.397 278C326.368 278 330.397 273.971 330.397 269C330.397 264.029 326.368 260 321.397 260C316.426 260 312.397 264.029 312.397 269C312.397 273.971 316.426 278 321.397 278Z" fill={bgGridDotColor}/>
          <path d="M347.397 278C352.368 278 356.397 273.971 356.397 269C356.397 264.029 352.368 260 347.397 260C342.426 260 338.397 264.029 338.397 269C338.397 273.971 342.426 278 347.397 278Z" fill={bgGridDotColor}/>
          <path d="M373.397 278C378.368 278 382.397 273.971 382.397 269C382.397 264.029 378.368 260 373.397 260C368.426 260 364.397 264.029 364.397 269C364.397 273.971 368.426 278 373.397 278Z" fill={bgGridDotColor}/>
          <path d="M9.39697 252C14.3675 252 18.397 247.971 18.397 243C18.397 238.029 14.3675 234 9.39697 234C4.42641 234 0.396973 238.029 0.396973 243C0.396973 247.971 4.42641 252 9.39697 252Z" fill={bgGridDotColor}/>
          <path d="M35.397 252C40.3675 252 44.397 247.971 44.397 243C44.397 238.029 40.3675 234 35.397 234C30.4264 234 26.397 238.029 26.397 243C26.397 247.971 30.4264 252 35.397 252Z" fill={bgGridDotColor}/>
          <path d="M61.397 252C66.3675 252 70.397 247.971 70.397 243C70.397 238.029 66.3675 234 61.397 234C56.4264 234 52.397 238.029 52.397 243C52.397 247.971 56.4264 252 61.397 252Z" fill={bgGridDotColor}/>
          <path d="M87.397 252C92.3675 252 96.397 247.971 96.397 243C96.397 238.029 92.3675 234 87.397 234C82.4264 234 78.397 238.029 78.397 243C78.397 247.971 82.4264 252 87.397 252Z" fill={bgGridDotColor}/>
          <path d="M113.397 252C118.368 252 122.397 247.971 122.397 243C122.397 238.029 118.368 234 113.397 234C108.426 234 104.397 238.029 104.397 243C104.397 247.971 108.426 252 113.397 252Z" fill={bgGridDotColor}/>
          <path d="M165.397 252C170.368 252 174.397 247.971 174.397 243C174.397 238.029 170.368 234 165.397 234C160.426 234 156.397 238.029 156.397 243C156.397 247.971 160.426 252 165.397 252Z" fill={bgGridDotColor}/>
          <path d="M191.397 252C196.368 252 200.397 247.971 200.397 243C200.397 238.029 196.368 234 191.397 234C186.426 234 182.397 238.029 182.397 243C182.397 247.971 186.426 252 191.397 252Z" fill={bgGridDotColor}/>
          <path d="M217.397 252C222.368 252 226.397 247.971 226.397 243C226.397 238.029 222.368 234 217.397 234C212.426 234 208.397 238.029 208.397 243C208.397 247.971 212.426 252 217.397 252Z" fill={bgGridDotColor}/>
          <path d="M243.397 252C248.368 252 252.397 247.971 252.397 243C252.397 238.029 248.368 234 243.397 234C238.426 234 234.397 238.029 234.397 243C234.397 247.971 238.426 252 243.397 252Z" fill={bgGridDotColor}/>
          <path d="M269.397 252C274.368 252 278.397 247.971 278.397 243C278.397 238.029 274.368 234 269.397 234C264.426 234 260.397 238.029 260.397 243C260.397 247.971 264.426 252 269.397 252Z" fill={bgGridDotColor}/>
          <path d="M295.397 252C300.368 252 304.397 247.971 304.397 243C304.397 238.029 300.368 234 295.397 234C290.426 234 286.397 238.029 286.397 243C286.397 247.971 290.426 252 295.397 252Z" fill={bgGridDotColor}/>
          <path d="M321.397 252C326.368 252 330.397 247.971 330.397 243C330.397 238.029 326.368 234 321.397 234C316.426 234 312.397 238.029 312.397 243C312.397 247.971 316.426 252 321.397 252Z" fill={bgGridDotColor}/>
          <path d="M347.397 252C352.368 252 356.397 247.971 356.397 243C356.397 238.029 352.368 234 347.397 234C342.426 234 338.397 238.029 338.397 243C338.397 247.971 342.426 252 347.397 252Z" fill={bgGridDotColor}/>
          <path d="M373.397 252C378.368 252 382.397 247.971 382.397 243C382.397 238.029 378.368 234 373.397 234C368.426 234 364.397 238.029 364.397 243C364.397 247.971 368.426 252 373.397 252Z" fill={bgGridDotColor}/>
          <path d="M9.39697 226C14.3675 226 18.397 221.971 18.397 217C18.397 212.029 14.3675 208 9.39697 208C4.42641 208 0.396973 212.029 0.396973 217C0.396973 221.971 4.42641 226 9.39697 226Z" fill={bgGridDotColor}/>
          <path d="M35.397 226C40.3675 226 44.397 221.971 44.397 217C44.397 212.029 40.3675 208 35.397 208C30.4264 208 26.397 212.029 26.397 217C26.397 221.971 30.4264 226 35.397 226Z" fill={bgGridDotColor}/>
          <path d="M61.397 226C66.3675 226 70.397 221.971 70.397 217C70.397 212.029 66.3675 208 61.397 208C56.4264 208 52.397 212.029 52.397 217C52.397 221.971 56.4264 226 61.397 226Z" fill={bgGridDotColor}/>
          <path d="M87.397 226C92.3675 226 96.397 221.971 96.397 217C96.397 212.029 92.3675 208 87.397 208C82.4264 208 78.397 212.029 78.397 217C78.397 221.971 82.4264 226 87.397 226Z" fill={bgGridDotColor}/>
          <path d="M139.397 226C144.368 226 148.397 221.971 148.397 217C148.397 212.029 144.368 208 139.397 208C134.426 208 130.397 212.029 130.397 217C130.397 221.971 134.426 226 139.397 226Z" fill={bgGridDotColor}/>
          <path d="M191.397 226C196.368 226 200.397 221.971 200.397 217C200.397 212.029 196.368 208 191.397 208C186.426 208 182.397 212.029 182.397 217C182.397 221.971 186.426 226 191.397 226Z" fill={bgGridDotColor}/>
          <path d="M217.397 226C222.368 226 226.397 221.971 226.397 217C226.397 212.029 222.368 208 217.397 208C212.426 208 208.397 212.029 208.397 217C208.397 221.971 212.426 226 217.397 226Z" fill={bgGridDotColor}/>
          <path d="M243.397 226C248.368 226 252.397 221.971 252.397 217C252.397 212.029 248.368 208 243.397 208C238.426 208 234.397 212.029 234.397 217C234.397 221.971 238.426 226 243.397 226Z" fill={bgGridDotColor}/>
          <path d="M269.397 226C274.368 226 278.397 221.971 278.397 217C278.397 212.029 274.368 208 269.397 208C264.426 208 260.397 212.029 260.397 217C260.397 221.971 264.426 226 269.397 226Z" fill={bgGridDotColor}/>
          <path d="M295.397 226C300.368 226 304.397 221.971 304.397 217C304.397 212.029 300.368 208 295.397 208C290.426 208 286.397 212.029 286.397 217C286.397 221.971 290.426 226 295.397 226Z" fill={bgGridDotColor}/>
          <path d="M321.397 226C326.368 226 330.397 221.971 330.397 217C330.397 212.029 326.368 208 321.397 208C316.426 208 312.397 212.029 312.397 217C312.397 221.971 316.426 226 321.397 226Z" fill={bgGridDotColor}/>
          <path d="M347.397 226C352.368 226 356.397 221.971 356.397 217C356.397 212.029 352.368 208 347.397 208C342.426 208 338.397 212.029 338.397 217C338.397 221.971 342.426 226 347.397 226Z" fill={bgGridDotColor}/>
          <path d="M373.397 226C378.368 226 382.397 221.971 382.397 217C382.397 212.029 378.368 208 373.397 208C368.426 208 364.397 212.029 364.397 217C364.397 221.971 368.426 226 373.397 226Z" fill={bgGridDotColor}/>
          <path d="M9.39697 200C14.3675 200 18.397 195.971 18.397 191C18.397 186.029 14.3675 182 9.39697 182C4.42641 182 0.396973 186.029 0.396973 191C0.396973 195.971 4.42641 200 9.39697 200Z" fill={bgGridDotColor}/>
          <path d="M35.397 200C40.3675 200 44.397 195.971 44.397 191C44.397 186.029 40.3675 182 35.397 182C30.4264 182 26.397 186.029 26.397 191C26.397 195.971 30.4264 200 35.397 200Z" fill={bgGridDotColor}/>
          <path d="M61.397 200C66.3675 200 70.397 195.971 70.397 191C70.397 186.029 66.3675 182 61.397 182C56.4264 182 52.397 186.029 52.397 191C52.397 195.971 56.4264 200 61.397 200Z" fill={bgGridDotColor}/>
          <path d="M113.397 200C118.368 200 122.397 195.971 122.397 191C122.397 186.029 118.368 182 113.397 182C108.426 182 104.397 186.029 104.397 191C104.397 195.971 108.426 200 113.397 200Z" fill={bgGridDotColor}/>
          <path d="M139.397 200C144.368 200 148.397 195.971 148.397 191C148.397 186.029 144.368 182 139.397 182C134.426 182 130.397 186.029 130.397 191C130.397 195.971 134.426 200 139.397 200Z" fill={bgGridDotColor}/>
          <path d="M165.397 200C170.368 200 174.397 195.971 174.397 191C174.397 186.029 170.368 182 165.397 182C160.426 182 156.397 186.029 156.397 191C156.397 195.971 160.426 200 165.397 200Z" fill={bgGridDotColor}/>
          <path d="M217.397 200C222.368 200 226.397 195.971 226.397 191C226.397 186.029 222.368 182 217.397 182C212.426 182 208.397 186.029 208.397 191C208.397 195.971 212.426 200 217.397 200Z" fill={bgGridDotColor}/>
          <path d="M243.397 200C248.368 200 252.397 195.971 252.397 191C252.397 186.029 248.368 182 243.397 182C238.426 182 234.397 186.029 234.397 191C234.397 195.971 238.426 200 243.397 200Z" fill={bgGridDotColor}/>
          <path d="M269.397 200C274.368 200 278.397 195.971 278.397 191C278.397 186.029 274.368 182 269.397 182C264.426 182 260.397 186.029 260.397 191C260.397 195.971 264.426 200 269.397 200Z" fill={bgGridDotColor}/>
          <path d="M295.397 200C300.368 200 304.397 195.971 304.397 191C304.397 186.029 300.368 182 295.397 182C290.426 182 286.397 186.029 286.397 191C286.397 195.971 290.426 200 295.397 200Z" fill={bgGridDotColor}/>
          <path d="M321.397 200C326.368 200 330.397 195.971 330.397 191C330.397 186.029 326.368 182 321.397 182C316.426 182 312.397 186.029 312.397 191C312.397 195.971 316.426 200 321.397 200Z" fill={bgGridDotColor}/>
          <path d="M347.397 200C352.368 200 356.397 195.971 356.397 191C356.397 186.029 352.368 182 347.397 182C342.426 182 338.397 186.029 338.397 191C338.397 195.971 342.426 200 347.397 200Z" fill={bgGridDotColor}/>
          <path d="M373.397 200C378.368 200 382.397 195.971 382.397 191C382.397 186.029 378.368 182 373.397 182C368.426 182 364.397 186.029 364.397 191C364.397 195.971 368.426 200 373.397 200Z" fill={bgGridDotColor}/>
          <path d="M9.39697 174C14.3675 174 18.397 169.971 18.397 165C18.397 160.029 14.3675 156 9.39697 156C4.42641 156 0.396973 160.029 0.396973 165C0.396973 169.971 4.42641 174 9.39697 174Z" fill={bgGridDotColor}/>
          <path d="M35.397 174C40.3675 174 44.397 169.971 44.397 165C44.397 160.029 40.3675 156 35.397 156C30.4264 156 26.397 160.029 26.397 165C26.397 169.971 30.4264 174 35.397 174Z" fill={bgGridDotColor}/>
          <path d="M61.397 174C66.3675 174 70.397 169.971 70.397 165C70.397 160.029 66.3675 156 61.397 156C56.4264 156 52.397 160.029 52.397 165C52.397 169.971 56.4264 174 61.397 174Z" fill={bgGridDotColor}/>
          <path d="M87.397 174C92.3675 174 96.397 169.971 96.397 165C96.397 160.029 92.3675 156 87.397 156C82.4264 156 78.397 160.029 78.397 165C78.397 169.971 82.4264 174 87.397 174Z" fill={bgGridDotColor}/>
          <path d="M113.397 174C118.368 174 122.397 169.971 122.397 165C122.397 160.029 118.368 156 113.397 156C108.426 156 104.397 160.029 104.397 165C104.397 169.971 108.426 174 113.397 174Z" fill={bgGridDotColor}/>
          <path d="M139.397 174C144.368 174 148.397 169.971 148.397 165C148.397 160.029 144.368 156 139.397 156C134.426 156 130.397 160.029 130.397 165C130.397 169.971 134.426 174 139.397 174Z" fill={bgGridDotColor}/>
          <path d="M165.397 174C170.368 174 174.397 169.971 174.397 165C174.397 160.029 170.368 156 165.397 156C160.426 156 156.397 160.029 156.397 165C156.397 169.971 160.426 174 165.397 174Z" fill={bgGridDotColor}/>
          <path d="M191.397 174C196.368 174 200.397 169.971 200.397 165C200.397 160.029 196.368 156 191.397 156C186.426 156 182.397 160.029 182.397 165C182.397 169.971 186.426 174 191.397 174Z" fill={bgGridDotColor}/>
          <path d="M243.397 174C248.368 174 252.397 169.971 252.397 165C252.397 160.029 248.368 156 243.397 156C238.426 156 234.397 160.029 234.397 165C234.397 169.971 238.426 174 243.397 174Z" fill={bgGridDotColor}/>
          <path d="M269.397 174C274.368 174 278.397 169.971 278.397 165C278.397 160.029 274.368 156 269.397 156C264.426 156 260.397 160.029 260.397 165C260.397 169.971 264.426 174 269.397 174Z" fill={bgGridDotColor}/>
          <path d="M295.397 174C300.368 174 304.397 169.971 304.397 165C304.397 160.029 300.368 156 295.397 156C290.426 156 286.397 160.029 286.397 165C286.397 169.971 290.426 174 295.397 174Z" fill={bgGridDotColor}/>
          <path d="M321.397 174C326.368 174 330.397 169.971 330.397 165C330.397 160.029 326.368 156 321.397 156C316.426 156 312.397 160.029 312.397 165C312.397 169.971 316.426 174 321.397 174Z" fill={bgGridDotColor}/>
          <path d="M347.397 174C352.368 174 356.397 169.971 356.397 165C356.397 160.029 352.368 156 347.397 156C342.426 156 338.397 160.029 338.397 165C338.397 169.971 342.426 174 347.397 174Z" fill={bgGridDotColor}/>
          <path d="M373.397 174C378.368 174 382.397 169.971 382.397 165C382.397 160.029 378.368 156 373.397 156C368.426 156 364.397 160.029 364.397 165C364.397 169.971 368.426 174 373.397 174Z" fill={bgGridDotColor}/>
          <path d="M9.39697 148C14.3675 148 18.397 143.971 18.397 139C18.397 134.029 14.3675 130 9.39697 130C4.42641 130 0.396973 134.029 0.396973 139C0.396973 143.971 4.42641 148 9.39697 148Z" fill={bgGridDotColor}/>
          <path d="M35.397 148C40.3675 148 44.397 143.971 44.397 139C44.397 134.029 40.3675 130 35.397 130C30.4264 130 26.397 134.029 26.397 139C26.397 143.971 30.4264 148 35.397 148Z" fill={bgGridDotColor}/>
          <path d="M61.397 148C66.3675 148 70.397 143.971 70.397 139C70.397 134.029 66.3675 130 61.397 130C56.4264 130 52.397 134.029 52.397 139C52.397 143.971 56.4264 148 61.397 148Z" fill={bgGridDotColor}/>
          <path d="M87.397 148C92.3675 148 96.397 143.971 96.397 139C96.397 134.029 92.3675 130 87.397 130C82.4264 130 78.397 134.029 78.397 139C78.397 143.971 82.4264 148 87.397 148Z" fill={bgGridDotColor}/>
          <path d="M113.397 148C118.368 148 122.397 143.971 122.397 139C122.397 134.029 118.368 130 113.397 130C108.426 130 104.397 134.029 104.397 139C104.397 143.971 108.426 148 113.397 148Z" fill={bgGridDotColor}/>
          <path d="M139.397 148C144.368 148 148.397 143.971 148.397 139C148.397 134.029 144.368 130 139.397 130C134.426 130 130.397 134.029 130.397 139C130.397 143.971 134.426 148 139.397 148Z" fill={bgGridDotColor}/>
          <path d="M165.397 148C170.368 148 174.397 143.971 174.397 139C174.397 134.029 170.368 130 165.397 130C160.426 130 156.397 134.029 156.397 139C156.397 143.971 160.426 148 165.397 148Z" fill={bgGridDotColor}/>
          <path d="M191.397 148C196.368 148 200.397 143.971 200.397 139C200.397 134.029 196.368 130 191.397 130C186.426 130 182.397 134.029 182.397 139C182.397 143.971 186.426 148 191.397 148Z" fill={bgGridDotColor}/>
          <path d="M217.397 148C222.368 148 226.397 143.971 226.397 139C226.397 134.029 222.368 130 217.397 130C212.426 130 208.397 134.029 208.397 139C208.397 143.971 212.426 148 217.397 148Z" fill={bgGridDotColor}/>
          <path d="M269.397 148C274.368 148 278.397 143.971 278.397 139C278.397 134.029 274.368 130 269.397 130C264.426 130 260.397 134.029 260.397 139C260.397 143.971 264.426 148 269.397 148Z" fill={bgGridDotColor}/>
          <path d="M295.397 148C300.368 148 304.397 143.971 304.397 139C304.397 134.029 300.368 130 295.397 130C290.426 130 286.397 134.029 286.397 139C286.397 143.971 290.426 148 295.397 148Z" fill={bgGridDotColor}/>
          <path d="M321.397 148C326.368 148 330.397 143.971 330.397 139C330.397 134.029 326.368 130 321.397 130C316.426 130 312.397 134.029 312.397 139C312.397 143.971 316.426 148 321.397 148Z" fill={bgGridDotColor}/>
          <path d="M347.397 148C352.368 148 356.397 143.971 356.397 139C356.397 134.029 352.368 130 347.397 130C342.426 130 338.397 134.029 338.397 139C338.397 143.971 342.426 148 347.397 148Z" fill={bgGridDotColor}/>
          <path d="M373.397 148C378.368 148 382.397 143.971 382.397 139C382.397 134.029 378.368 130 373.397 130C368.426 130 364.397 134.029 364.397 139C364.397 143.971 368.426 148 373.397 148Z" fill={bgGridDotColor}/>
          <path d="M9.39697 122C14.3675 122 18.397 117.971 18.397 113C18.397 108.029 14.3675 104 9.39697 104C4.42641 104 0.396973 108.029 0.396973 113C0.396973 117.971 4.42641 122 9.39697 122Z" fill={bgGridDotColor}/>
          <path d="M35.397 122C40.3675 122 44.397 117.971 44.397 113C44.397 108.029 40.3675 104 35.397 104C30.4264 104 26.397 108.029 26.397 113C26.397 117.971 30.4264 122 35.397 122Z" fill={bgGridDotColor}/>
          <path d="M61.397 122C66.3675 122 70.397 117.971 70.397 113C70.397 108.029 66.3675 104 61.397 104C56.4264 104 52.397 108.029 52.397 113C52.397 117.971 56.4264 122 61.397 122Z" fill={bgGridDotColor}/>
          <path d="M87.397 122C92.3675 122 96.397 117.971 96.397 113C96.397 108.029 92.3675 104 87.397 104C82.4264 104 78.397 108.029 78.397 113C78.397 117.971 82.4264 122 87.397 122Z" fill={bgGridDotColor}/>
          <path d="M113.397 122C118.368 122 122.397 117.971 122.397 113C122.397 108.029 118.368 104 113.397 104C108.426 104 104.397 108.029 104.397 113C104.397 117.971 108.426 122 113.397 122Z" fill={bgGridDotColor}/>
          <path d="M139.397 122C144.368 122 148.397 117.971 148.397 113C148.397 108.029 144.368 104 139.397 104C134.426 104 130.397 108.029 130.397 113C130.397 117.971 134.426 122 139.397 122Z" fill={bgGridDotColor}/>
          <path d="M165.397 122C170.368 122 174.397 117.971 174.397 113C174.397 108.029 170.368 104 165.397 104C160.426 104 156.397 108.029 156.397 113C156.397 117.971 160.426 122 165.397 122Z" fill={bgGridDotColor}/>
          <path d="M191.397 122C196.368 122 200.397 117.971 200.397 113C200.397 108.029 196.368 104 191.397 104C186.426 104 182.397 108.029 182.397 113C182.397 117.971 186.426 122 191.397 122Z" fill={bgGridDotColor}/>
          <path d="M217.397 122C222.368 122 226.397 117.971 226.397 113C226.397 108.029 222.368 104 217.397 104C212.426 104 208.397 108.029 208.397 113C208.397 117.971 212.426 122 217.397 122Z" fill={bgGridDotColor}/>
          <path d="M243.397 122C248.368 122 252.397 117.971 252.397 113C252.397 108.029 248.368 104 243.397 104C238.426 104 234.397 108.029 234.397 113C234.397 117.971 238.426 122 243.397 122Z" fill={bgGridDotColor}/>
          <path d="M295.397 122C300.368 122 304.397 117.971 304.397 113C304.397 108.029 300.368 104 295.397 104C290.426 104 286.397 108.029 286.397 113C286.397 117.971 290.426 122 295.397 122Z" fill={bgGridDotColor}/>
          <path d="M321.397 122C326.368 122 330.397 117.971 330.397 113C330.397 108.029 326.368 104 321.397 104C316.426 104 312.397 108.029 312.397 113C312.397 117.971 316.426 122 321.397 122Z" fill={bgGridDotColor}/>
          <path d="M347.397 122C352.368 122 356.397 117.971 356.397 113C356.397 108.029 352.368 104 347.397 104C342.426 104 338.397 108.029 338.397 113C338.397 117.971 342.426 122 347.397 122Z" fill={bgGridDotColor}/>
          <path d="M373.397 122C378.368 122 382.397 117.971 382.397 113C382.397 108.029 378.368 104 373.397 104C368.426 104 364.397 108.029 364.397 113C364.397 117.971 368.426 122 373.397 122Z" fill={bgGridDotColor}/>
          <path d="M9.39697 96C14.3675 96 18.397 91.9706 18.397 87C18.397 82.0294 14.3675 78 9.39697 78C4.42641 78 0.396973 82.0294 0.396973 87C0.396973 91.9706 4.42641 96 9.39697 96Z" fill={bgGridDotColor}/>
          <path d="M35.397 96C40.3675 96 44.397 91.9706 44.397 87C44.397 82.0294 40.3675 78 35.397 78C30.4264 78 26.397 82.0294 26.397 87C26.397 91.9706 30.4264 96 35.397 96Z" fill={bgGridDotColor}/>
          <path d="M61.397 96C66.3675 96 70.397 91.9706 70.397 87C70.397 82.0294 66.3675 78 61.397 78C56.4264 78 52.397 82.0294 52.397 87C52.397 91.9706 56.4264 96 61.397 96Z" fill={bgGridDotColor}/>
          <path d="M87.397 96C92.3675 96 96.397 91.9706 96.397 87C96.397 82.0294 92.3675 78 87.397 78C82.4264 78 78.397 82.0294 78.397 87C78.397 91.9706 82.4264 96 87.397 96Z" fill={bgGridDotColor}/>
          <path d="M113.397 96C118.368 96 122.397 91.9706 122.397 87C122.397 82.0294 118.368 78 113.397 78C108.426 78 104.397 82.0294 104.397 87C104.397 91.9706 108.426 96 113.397 96Z" fill={bgGridDotColor}/>
          <path d="M139.397 96C144.368 96 148.397 91.9706 148.397 87C148.397 82.0294 144.368 78 139.397 78C134.426 78 130.397 82.0294 130.397 87C130.397 91.9706 134.426 96 139.397 96Z" fill={bgGridDotColor}/>
          <path d="M165.397 96C170.368 96 174.397 91.9706 174.397 87C174.397 82.0294 170.368 78 165.397 78C160.426 78 156.397 82.0294 156.397 87C156.397 91.9706 160.426 96 165.397 96Z" fill={bgGridDotColor}/>
          <path d="M191.397 96C196.368 96 200.397 91.9706 200.397 87C200.397 82.0294 196.368 78 191.397 78C186.426 78 182.397 82.0294 182.397 87C182.397 91.9706 186.426 96 191.397 96Z" fill={bgGridDotColor}/>
          <path d="M217.397 96C222.368 96 226.397 91.9706 226.397 87C226.397 82.0294 222.368 78 217.397 78C212.426 78 208.397 82.0294 208.397 87C208.397 91.9706 212.426 96 217.397 96Z" fill={bgGridDotColor}/>
          <path d="M243.397 96C248.368 96 252.397 91.9706 252.397 87C252.397 82.0294 248.368 78 243.397 78C238.426 78 234.397 82.0294 234.397 87C234.397 91.9706 238.426 96 243.397 96Z" fill={bgGridDotColor}/>
          <path d="M269.397 96C274.368 96 278.397 91.9706 278.397 87C278.397 82.0294 274.368 78 269.397 78C264.426 78 260.397 82.0294 260.397 87C260.397 91.9706 264.426 96 269.397 96Z" fill={bgGridDotColor}/>
          <path d="M295.397 96C300.368 96 304.397 91.9706 304.397 87C304.397 82.0294 300.368 78 295.397 78C290.426 78 286.397 82.0294 286.397 87C286.397 91.9706 290.426 96 295.397 96Z" fill={bgGridDotColor}/>
          <path d="M321.397 96C326.368 96 330.397 91.9706 330.397 87C330.397 82.0294 326.368 78 321.397 78C316.426 78 312.397 82.0294 312.397 87C312.397 91.9706 316.426 96 321.397 96Z" fill={bgGridDotColor}/>
          <path d="M347.397 96C352.368 96 356.397 91.9706 356.397 87C356.397 82.0294 352.368 78 347.397 78C342.426 78 338.397 82.0294 338.397 87C338.397 91.9706 342.426 96 347.397 96Z" fill={bgGridDotColor}/>
          <path d="M373.397 96C378.368 96 382.397 91.9706 382.397 87C382.397 82.0294 378.368 78 373.397 78C368.426 78 364.397 82.0294 364.397 87C364.397 91.9706 368.426 96 373.397 96Z" fill={bgGridDotColor}/>
          <path d="M9.39697 70C14.3675 70 18.397 65.9706 18.397 61C18.397 56.0294 14.3675 52 9.39697 52C4.42641 52 0.396973 56.0294 0.396973 61C0.396973 65.9706 4.42641 70 9.39697 70Z" fill={bgGridDotColor}/>
          <path d="M35.397 70C40.3675 70 44.397 65.9706 44.397 61C44.397 56.0294 40.3675 52 35.397 52C30.4264 52 26.397 56.0294 26.397 61C26.397 65.9706 30.4264 70 35.397 70Z" fill={bgGridDotColor}/>
          <path d="M61.397 70C66.3675 70 70.397 65.9706 70.397 61C70.397 56.0294 66.3675 52 61.397 52C56.4264 52 52.397 56.0294 52.397 61C52.397 65.9706 56.4264 70 61.397 70Z" fill={bgGridDotColor}/>
          <path d="M87.397 70C92.3675 70 96.397 65.9706 96.397 61C96.397 56.0294 92.3675 52 87.397 52C82.4264 52 78.397 56.0294 78.397 61C78.397 65.9706 82.4264 70 87.397 70Z" fill={bgGridDotColor}/>
          <path d="M113.397 70C118.368 70 122.397 65.9706 122.397 61C122.397 56.0294 118.368 52 113.397 52C108.426 52 104.397 56.0294 104.397 61C104.397 65.9706 108.426 70 113.397 70Z" fill={bgGridDotColor}/>
          <path d="M139.397 70C144.368 70 148.397 65.9706 148.397 61C148.397 56.0294 144.368 52 139.397 52C134.426 52 130.397 56.0294 130.397 61C130.397 65.9706 134.426 70 139.397 70Z" fill={bgGridDotColor}/>
          <path d="M165.397 70C170.368 70 174.397 65.9706 174.397 61C174.397 56.0294 170.368 52 165.397 52C160.426 52 156.397 56.0294 156.397 61C156.397 65.9706 160.426 70 165.397 70Z" fill={bgGridDotColor}/>
          <path d="M191.397 70C196.368 70 200.397 65.9706 200.397 61C200.397 56.0294 196.368 52 191.397 52C186.426 52 182.397 56.0294 182.397 61C182.397 65.9706 186.426 70 191.397 70Z" fill={bgGridDotColor}/>
          <path d="M217.397 70C222.368 70 226.397 65.9706 226.397 61C226.397 56.0294 222.368 52 217.397 52C212.426 52 208.397 56.0294 208.397 61C208.397 65.9706 212.426 70 217.397 70Z" fill={bgGridDotColor}/>
          <path d="M243.397 70C248.368 70 252.397 65.9706 252.397 61C252.397 56.0294 248.368 52 243.397 52C238.426 52 234.397 56.0294 234.397 61C234.397 65.9706 238.426 70 243.397 70Z" fill={bgGridDotColor}/>
          <path d="M269.397 70C274.368 70 278.397 65.9706 278.397 61C278.397 56.0294 274.368 52 269.397 52C264.426 52 260.397 56.0294 260.397 61C260.397 65.9706 264.426 70 269.397 70Z" fill={bgGridDotColor}/>
          <path d="M295.397 70C300.368 70 304.397 65.9706 304.397 61C304.397 56.0294 300.368 52 295.397 52C290.426 52 286.397 56.0294 286.397 61C286.397 65.9706 290.426 70 295.397 70Z" fill={bgGridDotColor}/>
          <path d="M321.397 70C326.368 70 330.397 65.9706 330.397 61C330.397 56.0294 326.368 52 321.397 52C316.426 52 312.397 56.0294 312.397 61C312.397 65.9706 316.426 70 321.397 70Z" fill={bgGridDotColor}/>
          <path d="M347.397 70C352.368 70 356.397 65.9706 356.397 61C356.397 56.0294 352.368 52 347.397 52C342.426 52 338.397 56.0294 338.397 61C338.397 65.9706 342.426 70 347.397 70Z" fill={bgGridDotColor}/>
          <path d="M373.397 70C378.368 70 382.397 65.9706 382.397 61C382.397 56.0294 378.368 52 373.397 52C368.426 52 364.397 56.0294 364.397 61C364.397 65.9706 368.426 70 373.397 70Z" fill={bgGridDotColor}/>
          <path d="M9.39697 44C14.3675 44 18.397 39.9706 18.397 35C18.397 30.0294 14.3675 26 9.39697 26C4.42641 26 0.396973 30.0294 0.396973 35C0.396973 39.9706 4.42641 44 9.39697 44Z" fill={bgGridDotColor}/>
          <path d="M35.397 44C40.3675 44 44.397 39.9706 44.397 35C44.397 30.0294 40.3675 26 35.397 26C30.4264 26 26.397 30.0294 26.397 35C26.397 39.9706 30.4264 44 35.397 44Z" fill={bgGridDotColor}/>
          <path d="M61.397 44C66.3675 44 70.397 39.9706 70.397 35C70.397 30.0294 66.3675 26 61.397 26C56.4264 26 52.397 30.0294 52.397 35C52.397 39.9706 56.4264 44 61.397 44Z" fill={bgGridDotColor}/>
          <path d="M87.397 44C92.3675 44 96.397 39.9706 96.397 35C96.397 30.0294 92.3675 26 87.397 26C82.4264 26 78.397 30.0294 78.397 35C78.397 39.9706 82.4264 44 87.397 44Z" fill={bgGridDotColor}/>
          <path d="M113.397 44C118.368 44 122.397 39.9706 122.397 35C122.397 30.0294 118.368 26 113.397 26C108.426 26 104.397 30.0294 104.397 35C104.397 39.9706 108.426 44 113.397 44Z" fill={bgGridDotColor}/>
          <path d="M139.397 44C144.368 44 148.397 39.9706 148.397 35C148.397 30.0294 144.368 26 139.397 26C134.426 26 130.397 30.0294 130.397 35C130.397 39.9706 134.426 44 139.397 44Z" fill={bgGridDotColor}/>
          <path d="M165.397 44C170.368 44 174.397 39.9706 174.397 35C174.397 30.0294 170.368 26 165.397 26C160.426 26 156.397 30.0294 156.397 35C156.397 39.9706 160.426 44 165.397 44Z" fill={bgGridDotColor}/>
          <path d="M191.397 44C196.368 44 200.397 39.9706 200.397 35C200.397 30.0294 196.368 26 191.397 26C186.426 26 182.397 30.0294 182.397 35C182.397 39.9706 186.426 44 191.397 44Z" fill={bgGridDotColor}/>
          <path d="M217.397 44C222.368 44 226.397 39.9706 226.397 35C226.397 30.0294 222.368 26 217.397 26C212.426 26 208.397 30.0294 208.397 35C208.397 39.9706 212.426 44 217.397 44Z" fill={bgGridDotColor}/>
          <path d="M243.397 44C248.368 44 252.397 39.9706 252.397 35C252.397 30.0294 248.368 26 243.397 26C238.426 26 234.397 30.0294 234.397 35C234.397 39.9706 238.426 44 243.397 44Z" fill={bgGridDotColor}/>
          <path d="M269.397 44C274.368 44 278.397 39.9706 278.397 35C278.397 30.0294 274.368 26 269.397 26C264.426 26 260.397 30.0294 260.397 35C260.397 39.9706 264.426 44 269.397 44Z" fill={bgGridDotColor}/>
          <path d="M295.397 44C300.368 44 304.397 39.9706 304.397 35C304.397 30.0294 300.368 26 295.397 26C290.426 26 286.397 30.0294 286.397 35C286.397 39.9706 290.426 44 295.397 44Z" fill={bgGridDotColor}/>
          <path d="M321.397 44C326.368 44 330.397 39.9706 330.397 35C330.397 30.0294 326.368 26 321.397 26C316.426 26 312.397 30.0294 312.397 35C312.397 39.9706 316.426 44 321.397 44Z" fill={bgGridDotColor}/>
          <path d="M347.397 44C352.368 44 356.397 39.9706 356.397 35C356.397 30.0294 352.368 26 347.397 26C342.426 26 338.397 30.0294 338.397 35C338.397 39.9706 342.426 44 347.397 44Z" fill={bgGridDotColor}/>
          <path d="M373.397 44C378.368 44 382.397 39.9706 382.397 35C382.397 30.0294 378.368 26 373.397 26C368.426 26 364.397 30.0294 364.397 35C364.397 39.9706 368.426 44 373.397 44Z" fill={bgGridDotColor}/>
          <path d="M9.39697 18C14.3675 18 18.397 13.9706 18.397 9C18.397 4.02944 14.3675 0 9.39697 0C4.42641 0 0.396973 4.02944 0.396973 9C0.396973 13.9706 4.42641 18 9.39697 18Z" fill={bgGridDotColor}/>
          <path d="M35.397 18C40.3675 18 44.397 13.9706 44.397 9C44.397 4.02944 40.3675 0 35.397 0C30.4264 0 26.397 4.02944 26.397 9C26.397 13.9706 30.4264 18 35.397 18Z" fill={bgGridDotColor}/>
          <path d="M61.397 18C66.3675 18 70.397 13.9706 70.397 9C70.397 4.02944 66.3675 0 61.397 0C56.4264 0 52.397 4.02944 52.397 9C52.397 13.9706 56.4264 18 61.397 18Z" fill={bgGridDotColor}/>
          <path d="M87.397 18C92.3675 18 96.397 13.9706 96.397 9C96.397 4.02944 92.3675 0 87.397 0C82.4264 0 78.397 4.02944 78.397 9C78.397 13.9706 82.4264 18 87.397 18Z" fill={bgGridDotColor}/>
          <path d="M113.397 18C118.368 18 122.397 13.9706 122.397 9C122.397 4.02944 118.368 0 113.397 0C108.426 0 104.397 4.02944 104.397 9C104.397 13.9706 108.426 18 113.397 18Z" fill={bgGridDotColor}/>
          <path d="M139.397 18C144.368 18 148.397 13.9706 148.397 9C148.397 4.02944 144.368 0 139.397 0C134.426 0 130.397 4.02944 130.397 9C130.397 13.9706 134.426 18 139.397 18Z" fill={bgGridDotColor}/>
          <path d="M165.397 18C170.368 18 174.397 13.9706 174.397 9C174.397 4.02944 170.368 0 165.397 0C160.426 0 156.397 4.02944 156.397 9C156.397 13.9706 160.426 18 165.397 18Z" fill={bgGridDotColor}/>
          <path d="M191.397 18C196.368 18 200.397 13.9706 200.397 9C200.397 4.02944 196.368 0 191.397 0C186.426 0 182.397 4.02944 182.397 9C182.397 13.9706 186.426 18 191.397 18Z" fill={bgGridDotColor}/>
          <path d="M217.397 18C222.368 18 226.397 13.9706 226.397 9C226.397 4.02944 222.368 0 217.397 0C212.426 0 208.397 4.02944 208.397 9C208.397 13.9706 212.426 18 217.397 18Z" fill={bgGridDotColor}/>
          <path d="M243.397 18C248.368 18 252.397 13.9706 252.397 9C252.397 4.02944 248.368 0 243.397 0C238.426 0 234.397 4.02944 234.397 9C234.397 13.9706 238.426 18 243.397 18Z" fill={bgGridDotColor}/>
          <path d="M269.397 18C274.368 18 278.397 13.9706 278.397 9C278.397 4.02944 274.368 0 269.397 0C264.426 0 260.397 4.02944 260.397 9C260.397 13.9706 264.426 18 269.397 18Z" fill={bgGridDotColor}/>
          <path d="M295.397 18C300.368 18 304.397 13.9706 304.397 9C304.397 4.02944 300.368 0 295.397 0C290.426 0 286.397 4.02944 286.397 9C286.397 13.9706 290.426 18 295.397 18Z" fill={bgGridDotColor}/>
          <path d="M321.397 18C326.368 18 330.397 13.9706 330.397 9C330.397 4.02944 326.368 0 321.397 0C316.426 0 312.397 4.02944 312.397 9C312.397 13.9706 316.426 18 321.397 18Z" fill={bgGridDotColor}/>
          <path d="M347.397 18C352.368 18 356.397 13.9706 356.397 9C356.397 4.02944 352.368 0 347.397 0C342.426 0 338.397 4.02944 338.397 9C338.397 13.9706 342.426 18 347.397 18Z" fill={bgGridDotColor}/>
          <path d="M373.397 18C378.368 18 382.397 13.9706 382.397 9C382.397 4.02944 378.368 0 373.397 0C368.426 0 364.397 4.02944 364.397 9C364.397 13.9706 368.426 18 373.397 18Z" fill={bgGridDotColor}/>
        </m.svg>
      </LazyMotion>
    </div>
  )
}

export default QualityAnimation
