import { API } from '@aws-amplify/api'
import { useQuery } from 'react-query'

export interface IPayment {
  acreMonthly: number
  mortgage: number
  homeValue: number
}

interface PaymentsMap {
  [percent: string]: IPayment
}

const calculatePrice = async ({
  valueShare,
  apr,
  percentStart,
  percentEnd,
  steps,
  signal,
}: {
  valueShare: number
  apr: number
  percentStart?: number
  percentEnd?: number
  steps?: number
  signal?: AbortSignal
}): Promise<PaymentsMap> => {
  try {
    const promise = API.get('listing', '/price_calculator', {
      queryStringParameters: {
        value_share: valueShare,
        apr,
        percent_start: percentStart,
        percent_end: percentEnd,
        steps,
      },
    })

    signal?.addEventListener('abort', () =>
      API.cancel(promise, 'aborted by react-query')
    )

    const res = await promise

    return res.payments
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    throw new Error(error.response?.data?.error || 'Something went wrong')
  }
}

export const PRICE_CALCULATOR_QUERY_KEY = 'calculatePrice'

export const usePriceCalculator = ({
  valueShare,
  apr,
  percentStart,
  percentEnd,
  steps,
}: {
  valueShare: number
  apr: number
  percentStart?: number
  percentEnd?: number
  steps?: number
}) => {
  return useQuery(
    PRICE_CALCULATOR_QUERY_KEY,
    ({ signal }) =>
      calculatePrice({
        valueShare,
        apr,
        percentStart,
        percentEnd,
        steps,
        signal,
      }),
    {
      enabled: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  )
}
