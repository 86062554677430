import { useCallback, useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import Player from '@vimeo/player'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'

import Nl2br from '../../Nl2br'

import { PlayIcon } from 'icons'

import { useTracking } from '../../../services/context/TrackingContext'

export interface IVideoContent {
  heading: string
  description: string
  videoId: number
  image: {
    url: string
  }
}

interface IVideoProps {
  content: IVideoContent
}

const Video = ({ content }: IVideoProps) => {
  const { track } = useTracking()
  const { t } = useTranslation('common')
  const playerRef = useRef<Player | null>(null)
  const initialPlayRef = useRef(true)
  const [isPlaying, setIsPlaying] = useState(false)

  const trackVideo = useCallback(
    ({ event, seconds, duration, volume, title }) => {
      track({
        event,
        properties: {
          position: seconds,
          total_length: duration,
          sound: volume,
          title,
          video_player: 'Vimeo',
        },
      })
    },
    [track]
  )

  const loadAndPlayVideo = useCallback(async () => {
    playerRef.current = new Player(`video-${content.videoId}`, {
      id: content.videoId,
      width: 640,
    })

    const title = await playerRef.current?.getVideoTitle()
    playerRef.current.on('play', async (event) => {
      try {
        if (event) {
          const { seconds, duration } = event // conditionally destructure because play event can be undefined
          const volume = await playerRef.current?.getVolume()

          trackVideo({
            event: initialPlayRef.current
              ? 'Video Playback Started'
              : 'Video Playback Resumed',
            title,
            duration,
            seconds,
            volume,
          })

          if (initialPlayRef.current) {
            trackVideo({
              event: 'Video Content Started',
              duration,
              seconds,
              volume,
              title,
            })
            initialPlayRef.current = false
          }
        }
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      } catch (error: any) {
        throw new Error(error)
      }
    })

    playerRef.current.on('ended', async ({ seconds, duration }) => {
      try {
        const volume = await playerRef.current?.getVolume()
        trackVideo({
          event: 'Video Playback Completed',
          title,
          seconds,
          duration,
          volume,
        })
        trackVideo({
          event: 'Video Content Completed',
          title,
          seconds,
          duration,
          volume,
        })
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      } catch (error: any) {
        throw new Error(error)
      }
    })

    playerRef.current.on('pause', async ({ seconds, duration, percent }) => {
      try {
        const volume = await playerRef.current?.getVolume()
        if (percent !== 1) {
          trackVideo({
            event: 'Video Playback Paused',
            title,
            seconds,
            duration,
            volume,
          })
        }
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      } catch (error: any) {
        throw new Error(error)
      }
    })

    playerRef.current.play()
    setIsPlaying(true)
  }, [content.videoId, trackVideo])

  return (
    <section id="video" className="overflow-hidden rounded bg-white">
      <article className="py-48 px-20 text-center md:pt-62 md:pb-80">
        <h2 className="text-[28px] font-semibold leading-snug md:text-[36px] md:leading-tight">
          <Nl2br str={content.heading} />
        </h2>
        {content.description ? (
          <p className="mx-auto mt-10 text-20">{content.description}</p>
        ) : null}

        <div
          id={`video-${content.videoId}`}
          className="relative mx-auto mt-48 aspect-video max-w-[960px] overflow-hidden rounded-md shadow-video iframe-child:absolute iframe-child:top-0 iframe-child:left-0 iframe-child:h-full iframe-child:w-full"
        >
          <AnimatePresence>
            {!isPlaying ? (
              <motion.div
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="absolute inset-0 grid place-content-center bg-grey-3"
              >
                <Image
                  className="z-10"
                  src={content.image.url}
                  layout="fill"
                  objectFit="cover"
                  alt=""
                />
                <button
                  className="z-20 grid h-80 w-80 place-content-center rounded-full bg-white transition-transform duration-200 hover:scale-110"
                  onClick={loadAndPlayVideo}
                >
                  <span className="sr-only">{t('ui.play')}</span>
                  <PlayIcon
                    aria-hidden="true"
                    className="h-18 w-14 stroke-teal-3"
                  />
                </button>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>
      </article>
    </section>
  )
}

export default Video
