import TextImageSplit, { ITextImageSplitContent } from './heroes/TextImageSplit'

type TextImageSplit = ITextImageSplitContent & {
  __typename: 'HeroTextImageSplit'
}

export type IHero = TextImageSplit

interface IHeroProps {
  hero?: IHero
}

const Hero = ({ hero }: IHeroProps) => {
  if (!hero) {
    return null
  }

  const { __typename: type, ...content } = hero

  switch (type) {
    case 'HeroTextImageSplit':
      return <TextImageSplit content={content as ITextImageSplitContent} />

    default:
      return null
  }
}

export default Hero
