import { numberToCurrency } from 'utils'

export const abbreviateCurrency = (price: number, symbol = '$' ): string => {
  const prefix = price < 0 ? `-${symbol}` : `${symbol}`

  if (price >= 0 && price < 1000) {
    return numberToCurrency(price)
  }

  if (price > -1000000 && price < 1000000) {
    return `${prefix}${Math.round(Math.abs(price) / 100) / 10}K`
  }

  if (price > -1000000000 && price < 1000000000) {
    return `${prefix}${Math.round(Math.abs(price) / 100000) / 10}M`
  }

  return `${prefix}${Math.round(Math.abs(price) / 100000000) / 10}B`
}
