import cx from 'classnames'
import * as Accordion from '@radix-ui/react-accordion'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'

import Nl2br from '../../Nl2br'

import { ChevronIcon } from 'icons'
import { useTracking } from '../../../services/context/TrackingContext'

const faqSlugify = (sectionHeading: string, index: number) => {
  return `faq-${sectionHeading.replace(/\s/g, '-').toLowerCase()}-${index}`
}

export interface IFaqContent {
  heading: string
  sectionsCollection: {
    items: {
      heading: string
      questionsCollection: {
        items: {
          question: string
          answer: {
            json: Document
          }
        }[]
      }
    }[]
  }
}

interface IFaqProps {
  content: IFaqContent
}

const Faq = ({ content }: IFaqProps) => {
  const sections = content.sectionsCollection?.items ?? []
  const { track } = useTracking()

  return (
    <section id="faq" className="overflow-hidden rounded bg-white lg:flex">
      <aside className="hidden w-1/4 space-y-24 border-r border-[#DEE4E4] bg-grey-2 px-32 py-62 lg:block">
        {sections.map((section) => (
          <div key={section.heading}>
            <h3 className="font-semibold tracking-1 text-web-darkest">
              {section.heading}
            </h3>
            <ul className="mt-14 space-y-14">
              {section.questionsCollection?.items.map((item, i) => (
                <li key={item.question}>
                  <a
                    className="block font-semibold tracking-1 text-teal-3"
                    href={`#${faqSlugify(section.heading, i)}`}
                    onClick={() => {
                      track({
                        event: 'FAQ Item Selected',
                        properties: {
                          question_name: item.question,
                        },
                      })
                    }}
                  >
                    {item.question}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </aside>
      <article className="px-20 pt-40 pb-40 lg:w-3/4 lg:px-48 lg:pt-62">
        <h2 className="text-center text-[28px] font-semibold leading-tight text-web-darkest lg:text-left lg:text-[36px]">
          <Nl2br brClassName="lg:hidden" str={content.heading} />
        </h2>
        <ul className="mt-48 space-y-32 lg:mt-32">
          {sections.map((section, si) => (
            <li key={section.heading}>
              <h3 className="uppercase text-tan-3 util-eyebrow">
                {section.heading}
              </h3>

              <Accordion.Root
                type="multiple"
                onValueChange={(event) => {
                  track({
                    event: 'FAQ Item Opened',
                    properties: {
                      question_name: event[0],
                    },
                  })
                }}
              >
                {section.questionsCollection?.items.map((item, i) => (
                  <Accordion.Item
                    key={item.question}
                    value={item.question}
                    id={faqSlugify(section.heading, i)}
                    className={cx('border-b border-green-1 py-10', {
                      'last:border-0': si + 1 === sections.length,
                    })}
                  >
                    <Accordion.Header asChild>
                      <h4>
                        <Accordion.Trigger
                          className={cx(
                            'group flex w-full items-center justify-between py-6 text-left text-18 font-semibold tracking-1 text-web-darkest transition-colors duration-200',
                            'hover:bg-grey-2 hover:text-teal-3',
                            'state-open:text-teal-3'
                          )}
                        >
                          {item.question}
                          <ChevronIcon className="ml-8 stroke-grey-8 transition duration-200 rect-22 group-state-open:rotate-180 group-state-open:stroke-teal-3" />
                        </Accordion.Trigger>
                      </h4>
                    </Accordion.Header>
                    <Accordion.Content
                      className={cx(
                        'overflow-hidden [--animation-duration:200ms]',
                        'state-open:animate-radix-accordion-open state-closed:animate-radix-accordion-close'
                      )}
                    >
                      <div className="text space-y-24 py-6 text-web-darkest">
                        {documentToReactComponents(item.answer.json)}
                      </div>
                    </Accordion.Content>
                  </Accordion.Item>
                ))}
              </Accordion.Root>
            </li>
          ))}
        </ul>
      </article>
    </section>
  )
}

export default Faq
