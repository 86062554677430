import { Fragment } from 'react'

const Nl2br = ({ str, brClassName }: { str: string; brClassName?: string }) => {
  const parts = str.split(/\n/g)

  return (
    <>
      {parts.map((part, i) => {
        return i + 1 === parts.length ? (
          part
        ) : (
          <Fragment key={i}>
            {part} <br className={brClassName} />
          </Fragment>
        )
      })}
    </>
  )
}

export default Nl2br
