import Image from 'next/image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'

import { Highlight4Up } from './4UpHighlights'

export interface IHighlightsImageSplitContent {
  heading: string
  richDescription?: {
    json: Document
  }
  image: {
    url: string
  }
  highlightsCollection: {
    items: Highlight4Up[]
  }
}

type IHighlightsImageSplitProps = {
  content: IHighlightsImageSplitContent
}

const HighlightsImageSplit = ({ content }: IHighlightsImageSplitProps) => {
  const highlights = content.highlightsCollection?.items ?? []

  return (
    <section className="overflow-hidden rounded bg-white md:grid md:grid-cols-2">
      <div className="relative aspect-video sm:order-last md:col-span-1 md:aspect-auto">
        <Image src={content.image.url} layout="fill" objectFit="cover" alt="" />
      </div>

      <div className="px-20 pt-32 pb-20 md:col-span-1 md:px-40 md:pb-40 md:pt-80 lg:px-48 lg:pt-100 lg:pb-48">
        <h2 className="text-center hd-xl sm:text-left">{content.heading}</h2>
        <div className="space-y-24 text-center body sm:text-left">
          {content.richDescription?.json
            ? documentToReactComponents(content.richDescription?.json)
            : null}
        </div>
        <ul className="mt-24 space-y-16">
          {highlights.map((highlight) => (
            <li
              className="flex items-center rounded-md border border-green-1 bg-grey-2 py-10 pl-10 pr-20"
              key={highlight.heading}
            >
              <figure className="block shrink-0 rounded-sm border border-green-1 bg-white p-24">
                <span className="relative block h-[54px] w-[54px]">
                  <Image src={highlight.iconFile.url} alt="" layout="fill" />
                </span>
              </figure>
              <div className="ml-16">
                <p className="util-label">{highlight.heading}</p>
                {highlight.description ? (
                  <p className="body">{highlight.description}</p>
                ) : null}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default HighlightsImageSplit
