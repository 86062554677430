import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import Image from 'next/image'

const responsive = {
  mobile: {
    breakpoint: { max: Infinity, min: 0 },
    items: 1,
  },
}

type PressCard = {
  title: string;
  body: string;
  articleUrl: string;
  logoImage: {
    url: string;
  }
  logoImageDimensions: { width: number, height: number }
}

const PressCards = (props: { cards: Array<PressCard> }) => {
  return (
    <section className="overflow-hidden rounded bg-white">
      <ul className="list-none py-12 sm:py-32">
        <Carousel infinite showDots responsive={responsive}>
          {props.cards.map((card, idx) => (
            <li key={idx} className="h-full flex flex-col justify-center items-center px-80 py-10 sm:py-40">
              <Image
                src={card.logoImage.url}
                alt=""
                width={card.logoImageDimensions.width}
                height={card.logoImageDimensions.height}
              />
              <p className="mt-32 max-w-[800px] text-pretty text-center text-18 leading-8 text-grey-8 sm:text-24">
                {card.body}
              </p>
              <a
                className="mt-32"
                href={card.articleUrl}
              >
                <p className="text-16 font-semibold text-teal-5 underline hover:text-teal-3">
                  {card.title}
                </p>
              </a>
            </li>
          ))}
        </Carousel>
      </ul>
    </section>
  )
}

export default PressCards
