import { API } from '@aws-amplify/api'
import { useMutation, UseMutationResult } from 'react-query'
import * as Sentry from '@sentry/nextjs'

export type FormData = {
  name: string
  email: string
  reason: string
  message: string
}

const contactUs = async (data: FormData): Promise<void> => {
  try {
    const res = await API.post('customer', '/contact_us', {
      body: data,
    })

    return res
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  } catch (error: any) {
    Sentry.captureException(error)
    throw new Error(
      `Could not submit Contact Us form because: ${
        error.response?.data?.error || error.message || 'Something went wrong.'
      }`
    )
  }
}

export const useContactUs = (): UseMutationResult<
  void,
  Error,
  FormData,
  unknown
> => {
  return useMutation('contactUs', (data: FormData) => contactUs(data))
}
