import cx from 'classnames'
import Image from 'next/image'

import Nl2br from '../../Nl2br'
import { BlockMetadata } from '../PageBlocks'

import { AcreMarkIcon } from 'icons'

import { useTracking } from '../../../services/context/TrackingContext'

export interface ILocationsContent {
  heading: string
  ctaTextLine1?: string
  ctaTextLine2?: string
  ctaUrl?: string
  image: {
    url: string
  }
  locationsCollection: {
    items: {
      city: string
      state: string
      image: {
        url: string
      }
      showComingSoon: boolean
    }[]
  }
}

type LocationsProps = {
  content: ILocationsContent
} & BlockMetadata

const Locations = ({ content, trackingPosition }: LocationsProps) => {
  const { track } = useTracking()
  const locations = content.locationsCollection?.items ?? []

  return (
    <section
      id="locations"
      className="overflow-hidden rounded bg-white md:grid md:grid-cols-2"
    >
      <aside className="relative aspect-square w-full md:order-last md:aspect-auto md:h-full">
        <Image src={content.image.url} alt="" layout="fill" objectFit="cover" />
      </aside>

      <article className="px-20 pb-32 md:pt-60 lg:px-40 lg:pb-62 lg:pt-100">
        <h2 className="mt-24 text-center text-[28px] font-semibold leading-tight md:text-left lg:text-[36px]">
          <Nl2br str={content.heading} />
        </h2>
        <ul className="mt-32 grid gap-16 sm:grid-cols-2 lg:gap-32">
          {locations.map((location) => (
            <li key={location.city}>
              <div className="relative overflow-hidden rounded-md">
                <Image
                  className={cx('w-full', {
                    'saturate-0': location.showComingSoon,
                  })}
                  layout="responsive"
                  src={location.image.url}
                  width={278}
                  height={187}
                  alt=""
                />
                {location.showComingSoon ? (
                  <span className="absolute bottom-0 right-0 rounded-tl-md bg-teal-3 p-8 text-14 font-semibold leading-extra-loose text-white">
                    Coming Soon
                  </span>
                ) : null}
              </div>
              <p className="mt-16 text-18 font-semibold">{location.city}</p>
              <p className="text-18">{location.state}</p>
            </li>
          ))}
          {(content.ctaTextLine1 || content.ctaTextLine2) && content.ctaUrl ? (
            <li>
              <a
                href={content.ctaUrl}
                className="block"
                onClick={() => {
                  track({
                    event: 'New Metro Form Opened',
                    properties: {
                      link_type: 'Tile',
                      link_location: `Section ${trackingPosition}`,
                      link_text: `${content.ctaTextLine1} - ${content.ctaTextLine2}`,
                      link_position: 'Bottom Right',
                      link_priority: 1,
                    },
                  })
                }}
              >
                <div className="relative aspect-[303/204] rounded-md bg-web-background">
                  <AcreMarkIcon
                    aria-hidden="true"
                    className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 fill-tan-3 rect-mark-logo"
                  />
                </div>
                {content.ctaTextLine1 ? (
                  <p className="mt-16 text-18 font-semibold">
                    {content.ctaTextLine1}
                  </p>
                ) : null}
                {content.ctaTextLine2 ? (
                  <p className="text-18 text-teal-3 underline">
                    {content.ctaTextLine2}
                  </p>
                ) : null}
              </a>
            </li>
          ) : null}
        </ul>
      </article>
    </section>
  )
}

export default Locations
