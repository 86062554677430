import React, { FC, forwardRef, ReactNode } from 'react'
import cx from 'classnames'
import * as Dialog from '@radix-ui/react-dialog'
import { useTranslation } from 'next-i18next'

import { CloseIcon } from 'icons'

export const DialogOverlay = forwardRef<
  HTMLDivElement,
  { children: ReactNode }
>(({ children }, ref) => {
  return (
    <Dialog.Overlay
      ref={ref}
      className="fixed inset-0 z-modal grid place-items-center bg-black/70 p-16"
    >
      {children}
    </Dialog.Overlay>
  )
})

DialogOverlay.displayName = 'DialogOverlay'

export const DialogContent: FC<{
  className?: string
}> = ({ children, className }) => {
  return (
    <Dialog.Content
      className={cx(
        className,
        'max-h-fill flex max-h-[95vh] flex-col rounded bg-white'
      )}
    >
      {children}
    </Dialog.Content>
  )
}

export const DialogTitle: FC<{ hideClose?: boolean }> = ({
  children,
  hideClose,
}) => {
  const { t } = useTranslation('common')
  return (
    <Dialog.Title className="flex items-center justify-between border-b border-green-1 py-18 px-24 util-label lg:px-48">
      {children}
      {!hideClose ? (
        <Dialog.Close>
          <span className="sr-only">{t('ui.close')}</span>
          <CloseIcon aria-hidden="true" className="stroke-grey-8 rect-24" />
        </Dialog.Close>
      ) : null}
    </Dialog.Title>
  )
}

export const DialogBody: FC<{ noMinHeight?: boolean; className?: string }> = ({
  children,
  noMinHeight,
  className,
}) => {
  return (
    <div className={cx(className, 'grow overflow-y-auto p-24')}>
      <div
        className={cx({
          'min-h-[400px]': !noMinHeight,
        })}
      >
        {children}
      </div>
    </div>
  )
}

export const DialogFooter: FC<{ className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cx(
        className,
        'flex justify-end border-t border-green-1 py-18 px-24'
      )}
    >
      {children}
    </div>
  )
}
