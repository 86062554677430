import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'

const responsive = {
  mobile: {
    breakpoint: { max: Infinity, min: 0 },
    items: 1,
  },
}

type CustomerValueCard = {
  title: string;
  body: string;
  caption: string;
  customerImage: {
    url: string
  }
  homeImage: {
    url: string
  }
}

// const cards: Array<CustomerValueCard> = [
//   {
//     title: 'The Sharpes saved $13,404 in closing costs',
//     text: 'This family of 3 moved to get their 9th grader into a fantastic high school. Buying with Acre, these experienced homeowners can easily move to their empty nest mountain home when she graduates.',
//     caption: 'Acre covers inspection, appraisal, legal costs, and even make ready costs.'
//   },
//   {
//     title: 'The Sloans are saving $645 per month',
//     text: 'They used Acre to move closer to their church and save monthly. They are planning to upgrade again in a few years, prioritizing their evolving needs rather than waiting for rates to drop.',
//     caption: 'Acre’s Saver product is about 10% lower than a 5% prime rate mortgage.'
//   },
//   {
//     title: 'Talia earned $6,500 in home appreciation in just 7 months',
//     text: ' As a first-time home buyer and small business owner, she qualified for a mortgage, but at a higher rate because of her variable income. With Acre, she shares in the home’s appreciation and is never underwater, even in a down market.',
//     caption: 'Acre’s Boost product provides the resident 50% of the home’s appreciation.'
//   },
//   {
//     title: 'Renee skipped $7,891 in unexpected maintenance',
//     text: 'She kept her low mortgage rate on her existing home and rented it out. She used Acre to purchase her new primary home. With Acre she skips the need for a second mortgage and only has to focus on the maintenance for her rental property.',
//     caption: 'Acre covers all the major systems and resident is responsible for the day to day.'
//   },
//   {
//     title: 'Alan kept over $700k in his investment accounts',
//     text: 'He relocated from NY while his son attends college in the Triangle. He planned to buy the home in cash, but chose Acre instead because of the strong financial outcome for their shorter time frame.',
//     caption: 'Acre welcomes your legal or financial advisor’s review and provides transparent pricing.'
//   },
// ]

const CustomerValueCards = (props: { cards: Array<CustomerValueCard> }) => {
  const [isInteractingWithTouchEvents, setIsInteractingWithTouchEvents] = useState(false)

  return (
    <section className="overflow-hidden rounded bg-white">
      <ul className="list-none py-40 sm:py-48" onTouchStart={() => setIsInteractingWithTouchEvents(true)} onTouchEnd={() => setIsInteractingWithTouchEvents(false)} onTouchCancel={() => setIsInteractingWithTouchEvents(false)}>
        <Carousel autoPlay={!isInteractingWithTouchEvents} infinite showDots responsive={responsive}>
          {props.cards.map((card, idx) => (
            <li key={idx} className="h-full flex justify-center">

              {/* xs */}
              <div className="flex flex-col items-stretch px-[28px] pb-[40px] sm:hidden">
                <div className="flex justify-center">
                  <Image
                    src={card.customerImage.url}
                    alt=""
                    width={91}
                    height={91}
                  />
                </div>
                <p className="mt-[16px] leading-6 font-semibold text-16 text-teal-3 text-center">Customer Stories</p>
                <p className="mt-[8px] leading-10 font-semibold text-[28px] sm:text-[32px] text-grey-8 text-center">{card.title}</p>
                <div className="mt-[16px] flex justify-center">
                  <Image
                    src={card.homeImage.url}
                    alt=""
                    width={318}
                    height={223}
                  />
                </div>
                <p className="mt-[16px] leading-6 text-16 text-grey-8">{card.body}</p>
                <Link href="/how-it-works">
                  <div className="mt-12 rounded-sm py-8 flex justify-center bg-teal-4 cursor-pointer">
                    <p className="leading-6 text-16 text-white">
                      See How
                    </p>
                  </div>
                </Link>
              </div>

              {/* sm */}
              <div className="hidden flex-col items-stretch px-[96px] pb-[40px] sm:flex lg:hidden">
                <p className="leading-6 font-semibold text-16 text-teal-3 text-center">Customer Stories</p>
                <p className="mt-[22px] leading-10 font-semibold text-[32px] text-grey-8 text-center">{card.title}</p>
                <div className="mt-[22px] flex justify-center">
                  <div className="relative w-[489px] h-[337px]">
                    <div className="absolute left-[52px]">
                      <Image
                        src={card.homeImage.url}
                        alt=""
                        width={437}
                        height={312}
                      />
                      <p className="leading-4 text-12 text-grey-5 text-center">{card.caption}</p>
                    </div>
                    <div className="absolute top-[88px]">
                      <Image
                        src={card.customerImage.url}
                        alt=""
                        width={133}
                        height={133}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-[22px] flex flex-col justify-center items-start">
                  <p className="leading-6 text-16 text-grey-8">{card.body}</p>
                  <Link href="/how-it-works">
                    <div className="mt-24 rounded-sm bg-teal-4 px-16 py-8 cursor-pointer">
                      <p className="leading-6 text-16 text-white">
                        See How
                      </p>
                    </div>
                  </Link>
                </div>
              </div>

              {/* lg */}
              <div className="mx-auto hidden flex-col justify-center items-center lg:flex px-[144px] pb-[72px]">
                <p className="leading-6 font-semibold text-16 text-teal-3">Customer Stories</p>
                <p className="mt-[22px] leading-10 font-semibold text-[32px] text-grey-8">{card.title}</p>
                <div className="mt-24 grid grid-cols-[300px_400px] gap-x-[40px]">
                  <div className="flex flex-col justify-center items-start">
                    <p className="leading-6 text-16 text-grey-8">{card.body}</p>
                    <Link href="/how-it-works">
                      <div className="mt-24 rounded-sm bg-teal-4 px-16 py-8 cursor-pointer">
                        <p className="leading-6 text-16 text-white">
                          See How
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="flex flex-col">
                    <div className="relative w-[489px] h-[337px]">
                      <div className="absolute left-[52px]">
                        <Image
                          src={card.homeImage.url}
                          alt=""
                          width={437}
                          height={312}
                        />
                        <p className="ml-16 leading-4 text-12 text-grey-5">{card.caption}</p>
                      </div>
                      <div className="absolute top-[88px]">
                        <Image
                          src={card.customerImage.url}
                          alt=""
                          width={133}
                          height={133}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </Carousel>
      </ul>
    </section>
  )
}

export default CustomerValueCards
