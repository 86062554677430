import cx from 'classnames'
import Image from 'next/image'

import Nl2br from '../../Nl2br'
import { PrimaryButtonLink, SecondaryButtonLink } from 'ui'

import { ChevronIcon } from 'icons'

import { useTracking } from '../../../services/context/TrackingContext'

export enum HeroHeadingSize {
  large = 'Large',
  medium = 'Medium',
}

export interface ITextImageSplitContent {
  heading: string
  headingSize: HeroHeadingSize
  description?: string
  image: {
    url: string
  }
  ctaText?: string
  ctaUrl?: string
  secondaryCtaText?: string
  secondaryCtaUrl?: string
}

interface ITextImageSplitProps {
  content: ITextImageSplitContent
}

const TextImageSplit = ({ content }: ITextImageSplitProps) => {
  const { track } = useTracking()

  const useLinkStyle =
    content.ctaUrl &&
    content.ctaUrl.startsWith('#') &&
    (!content.secondaryCtaText || !content.secondaryCtaUrl)
  return (
    <section
      className={cx(
        'relative bg-white',
        'after:absolute after:left-0 after:right-1/2 after:bottom-24 after:hidden after:border-y after:border-green-1 after:pb-24 after:md:block'
      )}
    >
      <div className="mx-auto flex flex-col md:grid md:grid-cols-2">
        <article className="w-full max-w-[756px] justify-self-end px-24 py-32 md:px-48 md:pb-[168px] md:pt-200">
          <h1
            className={cx(
              'text-center text-[48px] font-semibold leading-tight md:text-left ',
              {
                'lg:text-[80px]': content.headingSize === HeroHeadingSize.large,
                'lg:text-[56px]':
                  content.headingSize === HeroHeadingSize.medium,
              }
            )}
          >
            <Nl2br str={content.heading} brClassName="hidden lg:block" />
          </h1>
          {content.description ? (
            <p className="mx-auto mt-16 max-w-[445px] text-center text-20 md:mx-0 md:text-left">
              {content.description}
            </p>
          ) : null}

          {content.ctaText && content.ctaUrl && useLinkStyle ? (
            <a
              href={content.ctaUrl}
              className="mt-20 flex items-center justify-center font-semibold text-teal-3 md:mt-24 md:inline-flex"
              onClick={() => {
                track({
                  event: 'CTA Clicked',
                  properties: {
                    link_type: 'Text',
                    link_location: 'Hero',
                    link_text: content.ctaText,
                    link_position: 'Bottom Left',
                    link_priority: 1,
                  },
                })
              }}
            >
              <span className="underline">{content.ctaText}</span>

              <ChevronIcon
                role="img"
                aria-hidden="true"
                className="ml-14 stroke-teal-3 rect-24"
              />
            </a>
          ) : null}

          {content.ctaText && content.ctaUrl && !useLinkStyle ? (
            <div className="mt-20 flex flex-wrap justify-center gap-16 md:justify-start">
              <PrimaryButtonLink
                className="whitespace-nowrap"
                href={content.ctaUrl}
                onClick={() => {
                  track({
                    event: 'CTA Clicked',
                    properties: {
                      link_type: 'Button',
                      link_location: 'Hero',
                      link_text: content.ctaText,
                      link_position: 'Bottom Left',
                      link_priority: 1,
                    },
                  })
                }}
              >
                {content.ctaText}
              </PrimaryButtonLink>
              {content.secondaryCtaText && content.secondaryCtaUrl ? (
                <SecondaryButtonLink href={content.secondaryCtaUrl}>
                  {content.secondaryCtaText}
                </SecondaryButtonLink>
              ) : null}
            </div>
          ) : null}
        </article>
        <div className="relative -order-1 aspect-square sm:aspect-3/2 md:-order-none md:aspect-auto">
          <Image
            src={content.image.url}
            alt=""
            layout="fill"
            objectFit="cover"
            priority
          />
        </div>
      </div>
    </section>
  )
}

export default TextImageSplit
