import { FC } from 'react'
import cx from 'classnames'
import Link from 'next/link'

import { AcreMarkIcon, FeaturesIcons } from 'icons'
import { PrimaryButtonLink } from 'ui'
import { BlockMetadata } from '../PageBlocks'

import { useTracking } from '../../../services/context/TrackingContext'

export interface IFeaturesContent {
  heading: string
  description?: string
  hideAcreLogo?: boolean
  ctaText?: string
  ctaLink?: string
  featuresCollection: {
    items: {
      heading: string
      description?: string
      icon?: string
    }[]
  }
}

type FeaturesProps = {
  content: IFeaturesContent
} & BlockMetadata

const TrackedFooterCta: FC<{ link: string; text: string } & BlockMetadata> = ({
  link,
  text,
  trackingPosition,
}) => {
  const { track } = useTracking()

  return (
    <footer className="mt-48 flex justify-center">
      <Link href={link} passHref>
        <PrimaryButtonLink
          onClick={() =>
            track({
              event: 'CTA Clicked',
              properties: {
                link_type: 'Text',
                link_location: `Section ${trackingPosition}`,
                link_text: text,
                link_position: 'Bottom Center',
                link_priority: 1,
              },
            })
          }
        >
          {text}
        </PrimaryButtonLink>
      </Link>
    </footer>
  )
}

const Features = ({ content, trackingPosition }: FeaturesProps) => {
  const features = content.featuresCollection?.items ?? []

  return (
    <section className="rounded bg-white px-40 py-48">
      <FeaturesIcons className="sr-only" aria-hidden="true" />
      <header className="mx-auto max-w-420 text-center">
        {!content.hideAcreLogo ? (
          <AcreMarkIcon
            className="mx-auto fill-tan-3 rect-mark-logo-sm"
            aria-hidden="true"
          />
        ) : null}
        <h2
          className={cx('hd-xl', {
            'mt-20': !content.hideAcreLogo,
          })}
        >
          {content.heading}
        </h2>
        {content.description ? (
          <p className="mx-auto mt-6 text-20">{content.description}</p>
        ) : null}
      </header>
      <ul className="mx-auto mt-48 max-w-[980px] space-y-24 sm:grid sm:grid-cols-3 sm:gap-48 sm:space-y-0 md:mt-62">
        {features.map((feature) => (
          <li key={feature.heading}>
            <h3 className="flex items-center justify-between border-b-2 border-grey-8 pb-10 util-label">
              {feature.heading}
              {feature.icon ? (
                <svg
                  className="w-32 shrink-0 fill-transparent stroke-teal-3"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <use xlinkHref={`#${feature.icon}`} />
                </svg>
              ) : null}
            </h3>
            {feature.description ? (
              <p className="mt-20 text-grey-8 body">{feature.description}</p>
            ) : null}
          </li>
        ))}
      </ul>
      {content.ctaText && content.ctaLink ? (
        <TrackedFooterCta
          link={content.ctaLink}
          text={content.ctaText}
          trackingPosition={trackingPosition}
        />
      ) : null}
    </section>
  )
}

export default Features
