import Image from 'next/image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'

export interface IHowItWorksContent {
  heading: string
  description?: string
  image: {
    url: string
  }
  stepsCollection: {
    items: {
      icon: string
      heading: string
      description: string
      richDescription: {
        json: Document
      }
    }[]
  }
}

interface IHowItWorksProps {
  content: IHowItWorksContent
}

const HowItWorks = ({ content }: IHowItWorksProps) => {
  const steps = content.stepsCollection?.items ?? []
  return (
    <section id="how-it-works" className="overflow-hidden rounded bg-white">
      <aside className="relative aspect-[1058/500] w-full overflow-hidden rounded-t">
        <Image src={content.image.url} alt="" layout="fill" objectFit="cover" />
      </aside>
      <article className="p-48">
        <h2 className="text-center hd-lg">{content.heading}</h2>
        {content.description ? (
          <p className="mx-auto mt-10 text-center body">
            {content.description}
          </p>
        ) : null}
        <ol className="mt-32 space-y-32 sm:grid sm:grid-cols-2 sm:gap-x-32 sm:gap-y-32 sm:space-y-0 xl:grid-cols-3">
          {steps.map((step, i) => (
            <li key={step.heading}>
              <div
                aria-hidden="true"
                className="grid h-[40px] w-[40px] items-center justify-center rounded-full border border-teal-2 text-teal-3 util-label"
              >
                {i + 1}
              </div>
              <h3 className="mt-24 border-b-2 border-grey-8 pb-8 util-label">
                {step.heading}
              </h3>
              <div className="text mt-8">
                {documentToReactComponents(step.richDescription.json)}
              </div>
            </li>
          ))}
        </ol>
      </article>
    </section>
  )
}

export default HowItWorks
