import { FC } from 'react'
import cx from 'classnames'
import type { Merge } from 'type-fest'

import Meta from './meta'
import Footer from './Footer'
import SkipToContent from './SkipToContent'
import Navbar from './Navbar'
import { IBasicPageProps } from '../pages/[slug]'
import ChatLauncher from './ChatLauncher'

import { useCustomer } from '../services/customer/loadAttributes'
import { useAppState } from '../services/context/AppContext'

type WebsiteLayoutProps = Merge<
  IBasicPageProps,
  {
    className?: string
    seoTitle?: string
    seoDescription?: string
    seoImage?: string
    hideChat?: boolean
  }
>

const WebsiteLayout: FC<WebsiteLayoutProps> = ({
  children,
  className,
  nav = {},
  footer,
  siteBanner,
  marketingBanner,
  seoTitle,
  seoDescription,
  seoImage,
  hideChat,
}) => {
  const {
    state: { errorMsg },
  } = useAppState()
  const { data: customerData } = useCustomer()

  const bannerIsShowing =
    !!errorMsg || customerData?.homeOffer?.status === 'open' || marketingBanner?.active

  return (
    <>
      {!hideChat ? <ChatLauncher /> : null}
      <Meta
        seoTitle={seoTitle}
        seoDescription={seoDescription}
        seoImage={seoImage}
      />
      <SkipToContent />
      <header
        className={cx({
          'pt-site-banner': siteBanner,
          'bg-white desktop-nav:pt-site-header': bannerIsShowing && siteBanner,
          'bg-white pt-nav': bannerIsShowing && !siteBanner,
        })}
      >
        <Navbar
          primaryCta={{
            text: nav.navPrimaryCtaText,
            url: nav.navPrimaryCtaUrl,
          }}
          scrollTriggerClassName={cx('mobile-nav:-mt-nav', {
            'md:top-0': bannerIsShowing,
          })}
          showBorder={bannerIsShowing}
          siteBanner={siteBanner}
          marketingBanner={marketingBanner?.active ? marketingBanner.message : undefined}
        />
      </header>
      <main
        id="content"
        className={cx(
          className,
          'h-full grow bg-web-background text-web-darkest'
        )}
      >
        {children}
      </main>
      {footer ? (
        <Footer
          footerSiteLinks={footer.footerSiteLinks}
          footerCurrentLocations={footer.footerCurrentLocations}
          footerFutureLocations={footer.footerFutureLocations}
          footerAdditionalLinksHeading={footer.footerAdditionalLinksHeading}
          footerAdditionalLinks={footer.footerAdditionalLinks}
          footerConnectText={footer.footerConnectText}
        />
      ) : null}
    </>
  )
}

export default WebsiteLayout
