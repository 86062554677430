import cx from 'classnames'
import Image from 'next/image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Document } from '@contentful/rich-text-types'

import { PrimaryButtonLink, SecondaryButtonLink } from 'ui'

import { AcreMarkIcon } from 'icons'
import { useTracking } from '../../../services/context/TrackingContext'
import { BlockMetadata } from '../PageBlocks'

enum ImagePosition {
  left = 'Left',
  right = 'Right',
}

export interface IImageTextSplitCtaContent {
  heading: string
  richDescription?: {
    json: Document
  }
  primaryCtaText?: string
  primaryCtaUrl?: string
  secondaryCtaText?: string
  secondaryCtaUrl?: string
  image: {
    url: string
  }
  imagePosition: ImagePosition
  showDecorativeAcreLogo: boolean
}

type IImageTextSplitCtaProps = {
  content: IImageTextSplitCtaContent
} & BlockMetadata

const ImageTextSplitCta = ({
  content,
  trackingPosition,
}: IImageTextSplitCtaProps) => {
  const { track } = useTracking()

  return (
    <section className="overflow-hidden rounded bg-white md:grid md:grid-cols-9">
      <div
        className={cx(
          'relative aspect-square sm:aspect-video md:col-span-4 md:aspect-auto',
          {
            'order-last': content.imagePosition === ImagePosition.right,
          }
        )}
      >
        <Image src={content.image.url} layout="fill" objectFit="cover" alt="" />
      </div>

      <div className="px-20 pt-32 pb-20 md:col-span-5 md:px-40 md:pt-100 md:pb-48 lg:px-40 lg:pt-200">
        {content.showDecorativeAcreLogo ? (
          <AcreMarkIcon
            className="mb-32 hidden fill-tan-3 rect-mark-logo-sm md:block"
            aria-hidden="true"
          />
        ) : null}
        <h2 className="text-center text-[28px] font-semibold leading-tight text-web-darkest md:text-left md:text-[36px]">
          {content.heading}
        </h2>
        <div className="text-large mt-12 space-y-24 text-center text-web-darkest md:mt-16 md:text-left">
          {content.richDescription?.json
            ? documentToReactComponents(content.richDescription?.json)
            : null}
        </div>

        <div className="mt-16 space-y-16 md:mt-32 md:flex md:space-y-0 md:space-x-16">
          {content.primaryCtaText && content.primaryCtaUrl ? (
            <PrimaryButtonLink
              className="block text-center"
              href={content.primaryCtaUrl}
              onClick={() => {
                track({
                  event: 'CTA Clicked',
                  properties: {
                    link_type: 'Button',
                    link_location: `Section ${trackingPosition}`,
                    link_text: content.primaryCtaText,
                    link_position: 'Bottom Left',
                    link_priority: 1,
                  },
                })
              }}
            >
              {content.primaryCtaText}
            </PrimaryButtonLink>
          ) : null}
          {content.secondaryCtaText && content.secondaryCtaUrl ? (
            <SecondaryButtonLink
              className="block text-center"
              href={content.secondaryCtaUrl}
              onClick={() => {
                track({
                  event: 'CTA Clicked',
                  properties: {
                    link_type: 'Button',
                    link_location: `Section ${trackingPosition}`,
                    link_text: content.secondaryCtaText,
                    link_position: 'Bottom Left',
                    link_priority: 2,
                  },
                })
              }}
            >
              {content.secondaryCtaText}
            </SecondaryButtonLink>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default ImageTextSplitCta
