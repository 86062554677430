import Image from 'next/image'

import { AcreMarkIcon, FeaturesIcons } from 'icons'
import { TertiaryButtonLink } from 'ui'
import Link from 'next/link'

import { BlockMetadata } from '../PageBlocks'

import { useTracking } from '../../../services/context/TrackingContext'

export interface IFeaturedHomesContent {
  heading: string
  description?: string
  ctaText: string
  ctaLink: string
}

type FeaturedHomesProps = {
  content: IFeaturedHomesContent
} & BlockMetadata

const TEMP_FEATURED_HOMES = [
  {
    id: 0,
    lineOne: '5 beds • 4 baths • 3,490 Sq. Ft.',
    lineTwo: '1002 Crescent Moon Court, Durham, NC, 27712-2992',
    img: '/website-assets/temp-featured-home-1.jpg',
  },
  {
    id: 1,
    lineOne: '4 beds • 3.5 baths • 3,328 Sq. Ft.',
    lineTwo: '208 Vervain Way, Holly Springs, NC, 2754',
    img: '/website-assets/temp-featured-home-2.jpg',
  },
  {
    id: 2,
    lineOne: '4 beds • 3.5 baths • 2,472 Sq. Ft.',
    lineTwo: '213 Wildspring Court, Apex, NC, 27539-6264',
    img: '/website-assets/temp-featured-home-3.jpg',
  },
]

const FeaturedHomes = ({ content, trackingPosition }: FeaturedHomesProps) => {
  const { track } = useTracking()

  return (
    <section
      className="rounded bg-cover bg-bottom bg-no-repeat px-24 pt-48 pb-62 md:pb-80 md:pt-62"
      style={{ backgroundImage: 'url(./website-assets/roof.jpg)' }}
    >
      <FeaturesIcons className="sr-only" aria-hidden="true" />
      <header className="mx-auto max-w-420 text-center">
        <AcreMarkIcon
          className="mx-auto h-[35px] w-[33px] fill-white"
          aria-hidden="true"
        />
        <h2 className="mt-20 hd-xl">{content.heading}</h2>
        {content.description ? (
          <p className="mx-auto mt-8 max-w-320 body">{content.description}</p>
        ) : null}
      </header>

      <ul className="mx-auto mt-24 grid max-w-320 grid-cols-1 gap-24 sm:max-w-[775px] sm:grid-cols-3">
        {TEMP_FEATURED_HOMES.map(({ img, lineOne, lineTwo, id }) => {
          return (
            <li key={id} className="rounded-md bg-white shadow-sm">
              <div className="text-grey text-left">
                <figure className="relative h-[138px]">
                  <Image
                    src={img}
                    className="rounded-t-md"
                    quality={100}
                    alt=""
                    layout="fill"
                    objectFit="cover"
                  />
                </figure>

                <div className="w-full grow py-8 px-16 text-center lg:text-left">
                  <p>
                    <strong className="block truncate text-14 font-semibold text-black">
                      {lineOne}
                    </strong>
                    <span className="mt-4 block truncate text-12 text-grey-7">
                      {lineTwo}
                    </span>
                  </p>
                </div>
              </div>
            </li>
          )
        })}
      </ul>
      <footer className="mt-40 text-center">
        <Link href={content.ctaLink} passHref>
          <TertiaryButtonLink
            onClick={() =>
              track({
                event: 'CTA Clicked',
                properties: {
                  link_type: 'Text',
                  link_location: `Section ${trackingPosition}`,
                  link_text: content.ctaText,
                  link_position: 'Bottom Center',
                  link_priority: 1,
                },
              })
            }
          >
            {content.ctaText}
          </TertiaryButtonLink>
        </Link>
      </footer>
    </section>
  )
}

export default FeaturedHomes
