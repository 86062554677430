import cx from 'classnames'
import Image from 'next/image'

export type Highlight4Up = {
  heading: string
  description?: string
  iconFile: {
    url: string
  }
}

export interface I4UpHighlightsContent {
  highlightsCollection: {
    items: Highlight4Up[]
  }
}

interface I4UpHighlightsProps {
  content: I4UpHighlightsContent
}

const FourUpHighlights = ({ content }: I4UpHighlightsProps) => {
  const highlights = content.highlightsCollection?.items ?? []

  return (
    <section>
      <ul
        className={cx('grid gap-48 px-48 py-24 lg:py-40', {
          'sm:grid-cols-2 lg:grid-cols-4': highlights.length === 4,
          'lg:grid-cols-3': highlights.length === 3,
        })}
      >
        {highlights.map((highlight) => (
          <li
            className="mx-auto max-w-[360px] space-y-8 text-center text-white"
            key={highlight.heading}
          >
            <Image src={highlight.iconFile.url} width="56" height="56" alt="" />
            <p className="text-24 font-semibold leading-tight">
              {highlight.heading}
            </p>
            {highlight.description ? (
              <p className="text-16 leading-snug">{highlight.description}</p>
            ) : null}
          </li>
        ))}
      </ul>
    </section>
  )
}

export default FourUpHighlights
