import Image from 'next/image'

import { CheckIcon, XIcon } from 'icons'

export interface IComparisonContent {
  heading: string
  description: string
  rentingPros: string[]
  rentingCons: string[]
  mortgagePros: string[]
  mortgageCons: string[]
  acrePros: string[]
  image: {
    url: string
  }
  mobileImage: {
    url: string
  }
}

interface IComparisonProps {
  content: IComparisonContent
}

const Comparison = ({ content }: IComparisonProps) => {
  return (
    <section
      id="comparison"
      className="items-center rounded bg-white p-32 xl:flex"
    >
      <div className="mr-40 2xl:mr-60 xl-d:hidden">
        <Image
          className="rounded-md"
          src={content.image.url}
          width={447}
          height={702}
          alt=""
        />
      </div>
      <div className="-m-32 mb-20 xl:hidden">
        <Image
          className="rounded-t-md"
          src={content.mobileImage.url}
          width={1280}
          height={800}
          alt=""
        />
      </div>
      <div>
        <div className="max-w-500">
          <h2 className="teal-5 text-[28px] font-semibold sm:text-[36px]">
            {content.heading}
          </h2>
          <p className="mt-10 text-[20px] leading-normal">
            {content.description}
          </p>
        </div>
        <div className="mt-40 grid grid-cols-1 gap-40 md:grid-cols-3 2xl:gap-60">
          <ComparisonList
            heading="Renting"
            pros={content.rentingPros}
            cons={content.rentingCons}
          />
          <ComparisonList
            heading="Traditional Mortgage"
            pros={content.mortgagePros}
            cons={content.mortgageCons}
          />
          <ComparisonList heading="Acre" pros={content.acrePros} />
        </div>
      </div>
    </section>
  )
}

export default Comparison

const ComparisonList = ({
  heading,
  pros = [],
  cons = [],
}: {
  heading: string
  pros?: string[]
  cons?: string[]
}) => {
  return (
    <div>
      <h3 className="border-b-2 border-teal-5 pb-10 hd-md-bold">{heading}</h3>
      <ul className="mt-24 space-y-8">
        {pros.map((pro) => (
          <li key={pro} className="flex space-x-8">
            <span>
              <CheckIcon className="stroke-teal-3 rect-24" />
              <span className="sr-only">Pro:</span>
            </span>
            <span>{pro}</span>
          </li>
        ))}
        {cons.map((con) => (
          <li key={con} className="flex space-x-8">
            <span>
              <XIcon className="stroke-maroon-1 rect-24" />
              <span className="sr-only">Con:</span>
            </span>
            <span>{con}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}
