import { useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import Player from '@vimeo/player'
import Image from 'next/image'
import * as Dialog from '@radix-ui/react-dialog'

import { DialogOverlay, DialogContent, DialogTitle } from '../../Dialog'
import { IVideoContent } from './Video'

import { PlayIcon } from 'icons'

import { useTracking } from '../../../services/context/TrackingContext'

export interface IVideoGridContent {
  heading: string
  description: string
  videosCollection: {
    items: IVideoContent[]
  }
}

interface IVideoProps {
  content: IVideoGridContent
}

const Video = ({ videoId }: { videoId: number }) => {
  const { track } = useTracking()
  const playerRef = useRef<Player | null>(null)
  const initialPlayRef = useRef(true)

  const trackVideo = useCallback(
    ({ event, seconds, duration, volume, title }) => {
      track({
        event,
        properties: {
          position: seconds,
          total_length: duration,
          sound: volume,
          title,
          video_player: 'Vimeo',
        },
      })
    },
    [track]
  )

  const loadAndPlayVideo = useCallback(
    async (videoId: number) => {
      playerRef.current = new Player(`video-grid-${videoId}`, {
        id: videoId,
        width: 640,
      })
      const title = await playerRef.current?.getVideoTitle()

      playerRef.current.on('play', async (event) => {
        try {
          if (event) {
            const { seconds, duration } = event // conditionally destructure because play event can be undefined
            const volume = await playerRef.current?.getVolume()

            trackVideo({
              event: initialPlayRef.current
                ? 'Video Playback Started'
                : 'Video Playback Resumed',
              title,
              duration,
              seconds,
              volume,
            })

            if (initialPlayRef.current) {
              trackVideo({
                event: 'Video Content Started',
                duration,
                seconds,
                volume,
                title,
              })
              initialPlayRef.current = false
            }
          }
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        } catch (error: any) {
          throw new Error(error)
        }
      })

      playerRef.current.on('ended', async ({ seconds, duration }) => {
        try {
          const volume = await playerRef.current?.getVolume()
          trackVideo({
            event: 'Video Playback Completed',
            title,
            seconds,
            duration,
            volume,
          })
          trackVideo({
            event: 'Video Content Completed',
            title,
            seconds,
            duration,
            volume,
          })
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        } catch (error: any) {
          throw new Error(error)
        }
      })

      playerRef.current.on('pause', async ({ seconds, duration, percent }) => {
        try {
          const volume = await playerRef.current?.getVolume()
          if (percent !== 1) {
            trackVideo({
              event: 'Video Playback Paused',
              title,
              seconds,
              duration,
              volume,
            })
          }
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        } catch (error: any) {
          throw new Error(error)
        }
      })

      playerRef.current.play()
    },
    [trackVideo]
  )

  useEffect(() => {
    loadAndPlayVideo(videoId)
  }, [loadAndPlayVideo, videoId])

  return <div id={`video-grid-${videoId}`} className="absolute inset-0"></div>
}

const VideoGrid = ({ content }: IVideoProps) => {
  const [activeVideo, setActiveVideo] = useState<IVideoContent | null>(null)
  const { t } = useTranslation('common')

  return (
    <Dialog.Root open={!!activeVideo} onOpenChange={() => setActiveVideo(null)}>
      <section id="video" className="rounded bg-white p-16 sm:p-48">
        {content.heading || content.description ? (
          <div className="mb-32 space-y-8 text-center">
            {content.heading ? (
              <h2 className="hd-xl">{content.heading}</h2>
            ) : null}
            {content.description ? (
              <p className="hd-md">{content.description}</p>
            ) : null}
          </div>
        ) : null}
        <ul
          className={cx('grid grid-cols-1 gap-48 md:gap-32 lg:gap-48', {
            'md:grid-cols-2': content.videosCollection.items.length === 2,
            'md:grid-cols-3': content.videosCollection.items.length > 2,
          })}
        >
          {content.videosCollection.items.map((video) => (
            <li key={video.videoId}>
              <div className="relative aspect-video">
                <Image
                  src={video.image.url}
                  className="rounded"
                  objectFit="cover"
                  layout="fill"
                  alt=""
                />
                <button
                  className="group absolute top-0 left-0 z-20 grid h-full w-full place-content-center"
                  onClick={() => setActiveVideo(video)}
                >
                  <div className="grid h-60 w-60 place-content-center rounded-full bg-white transition-transform duration-300 group-hover:scale-110">
                    <span className="sr-only">{t('ui.play')}</span>
                    <PlayIcon
                      aria-hidden="true"
                      className="h-18 w-14 stroke-teal-3"
                    />
                  </div>
                </button>
              </div>
              <p className="mt-16 font-semibold">{video.heading}</p>
              <p className="mt-4 body">{video.description}</p>
            </li>
          ))}
        </ul>
      </section>
      <AnimatePresence>
        <Dialog.Portal>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: 'tween', duration: 0.2, ease: 'easeInOut' }}
            className="relative z-modal"
          >
            <DialogOverlay>
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{
                  type: 'tween',
                  duration: 0.2,
                  ease: 'easeInOut',
                  delay: 0.2,
                }}
                className="relative z-modal w-full max-w-800"
              >
                <DialogContent className="w-full overflow-hidden">
                  <DialogTitle>{activeVideo?.heading}</DialogTitle>
                  <div className="iframe-child:absoltue relative aspect-video iframe-child:top-0 iframe-child:left-0 iframe-child:h-full iframe-child:w-full">
                    {activeVideo ? (
                      <Video videoId={activeVideo.videoId} />
                    ) : null}
                  </div>
                </DialogContent>
              </motion.div>
            </DialogOverlay>
          </motion.div>
        </Dialog.Portal>
      </AnimatePresence>
    </Dialog.Root>
  )
}

export default VideoGrid
