import {
  LazyMotion,
  domAnimation,
  m,
  AnimationControls,
  Variants,
} from 'framer-motion'
import { bgGridDotColor, greyGridDotColor, tealGridDotColor } from './constants'

const borderGroupVariants: Variants = {
  animate: {
    transition: {
      delayChildren: 0.5,
    },
  },
}

const borderVariants: Variants = {
  before: {
    fill: greyGridDotColor,
  },
  animate: {
    transition: {
      duration: 0.5,
    },
    fill: tealGridDotColor,
  },
}

const fillerGroupVariants: Variants = {
  animate: {
    transition: {
      delayChildren: 0.7,
      staggerChildren: 0.03,
    },
  },
}

const fillerVariants: Variants = {
  before: {
    fill: bgGridDotColor,
  },
  animate: {
    fill: tealGridDotColor,
    transition: {
      duration: 0.5,
    },
  },
}

const PossibilitesAnimation = ({
  controls,
}: {
  controls: AnimationControls
}) => {
  return (
    <div>
      <LazyMotion features={domAnimation}>
        {/* prettier-ignore */}
        <m.svg
          initial="before"
          animate={controls}
          viewBox="0 0 382 330"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <m.g variants={borderGroupVariants}>
            <m.path variants={borderVariants} d="M191 304C195.971 304 200 299.971 200 295C200 290.029 195.971 286 191 286C186.029 286 182 290.029 182 295C182 299.971 186.029 304 191 304Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M165 278C169.971 278 174 273.971 174 269C174 264.029 169.971 260 165 260C160.029 260 156 264.029 156 269C156 273.971 160.029 278 165 278Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M217 278C221.971 278 226 273.971 226 269C226 264.029 221.971 260 217 260C212.029 260 208 264.029 208 269C208 273.971 212.029 278 217 278Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M139 252C143.971 252 148 247.971 148 243C148 238.029 143.971 234 139 234C134.029 234 130 238.029 130 243C130 247.971 134.029 252 139 252Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M243 252C247.971 252 252 247.971 252 243C252 238.029 247.971 234 243 234C238.029 234 234 238.029 234 243C234 247.971 238.029 252 243 252Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M113 226C117.971 226 122 221.971 122 217C122 212.029 117.971 208 113 208C108.029 208 104 212.029 104 217C104 221.971 108.029 226 113 226Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M269 226C273.971 226 278 221.971 278 217C278 212.029 273.971 208 269 208C264.029 208 260 212.029 260 217C260 221.971 264.029 226 269 226Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M87 200C91.9706 200 96 195.971 96 191C96 186.029 91.9706 182 87 182C82.0294 182 78 186.029 78 191C78 195.971 82.0294 200 87 200Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M295 200C299.971 200 304 195.971 304 191C304 186.029 299.971 182 295 182C290.029 182 286 186.029 286 191C286 195.971 290.029 200 295 200Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M61 174C65.9706 174 70 169.971 70 165C70 160.029 65.9706 156 61 156C56.0294 156 52 160.029 52 165C52 169.971 56.0294 174 61 174Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M321 174C325.971 174 330 169.971 330 165C330 160.029 325.971 156 321 156C316.029 156 312 160.029 312 165C312 169.971 316.029 174 321 174Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M87 148C91.9706 148 96 143.971 96 139C96 134.029 91.9706 130 87 130C82.0294 130 78 134.029 78 139C78 143.971 82.0294 148 87 148Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M295 148C299.971 148 304 143.971 304 139C304 134.029 299.971 130 295 130C290.029 130 286 134.029 286 139C286 143.971 290.029 148 295 148Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M113 122C117.971 122 122 117.971 122 113C122 108.029 117.971 104 113 104C108.029 104 104 108.029 104 113C104 117.971 108.029 122 113 122Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M269 122C273.971 122 278 117.971 278 113C278 108.029 273.971 104 269 104C264.029 104 260 108.029 260 113C260 117.971 264.029 122 269 122Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M139 96C143.971 96 148 91.9706 148 87C148 82.0294 143.971 78 139 78C134.029 78 130 82.0294 130 87C130 91.9706 134.029 96 139 96Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M243 96C247.971 96 252 91.9706 252 87C252 82.0294 247.971 78 243 78C238.029 78 234 82.0294 234 87C234 91.9706 238.029 96 243 96Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M165 70C169.971 70 174 65.9706 174 61C174 56.0294 169.971 52 165 52C160.029 52 156 56.0294 156 61C156 65.9706 160.029 70 165 70Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M217 70C221.971 70 226 65.9706 226 61C226 56.0294 221.971 52 217 52C212.029 52 208 56.0294 208 61C208 65.9706 212.029 70 217 70Z" fill={greyGridDotColor}/>
            <m.path variants={borderVariants} d="M191 44C195.971 44 200 39.9706 200 35C200 30.0294 195.971 26 191 26C186.029 26 182 30.0294 182 35C182 39.9706 186.029 44 191 44Z" fill={greyGridDotColor}/>
          </m.g>

          <m.g variants={fillerGroupVariants}>
            <m.path variants={fillerVariants} d="M191 278C195.971 278 200 273.971 200 269C200 264.029 195.971 260 191 260C186.029 260 182 264.029 182 269C182 273.971 186.029 278 191 278Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M139 226C143.971 226 148 221.971 148 217C148 212.029 143.971 208 139 208C134.029 208 130 212.029 130 217C130 221.971 134.029 226 139 226Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M165 226C169.971 226 174 221.971 174 217C174 212.029 169.971 208 165 208C160.029 208 156 212.029 156 217C156 221.971 160.029 226 165 226Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M217 226C221.971 226 226 221.971 226 217C226 212.029 221.971 208 217 208C212.029 208 208 212.029 208 217C208 221.971 212.029 226 217 226Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M243 226C247.971 226 252 221.971 252 217C252 212.029 247.971 208 243 208C238.029 208 234 212.029 234 217C234 221.971 238.029 226 243 226Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M113 200C117.971 200 122 195.971 122 191C122 186.029 117.971 182 113 182C108.029 182 104 186.029 104 191C104 195.971 108.029 200 113 200Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M217 200C221.971 200 226 195.971 226 191C226 186.029 221.971 182 217 182C212.029 182 208 186.029 208 191C208 195.971 212.029 200 217 200Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M87 174C91.9706 174 96 169.971 96 165C96 160.029 91.9706 156 87 156C82.0294 156 78 160.029 78 165C78 169.971 82.0294 174 87 174Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M113 174C117.971 174 122 169.971 122 165C122 160.029 117.971 156 113 156C108.029 156 104 160.029 104 165C104 169.971 108.029 174 113 174Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M139 174C143.971 174 148 169.971 148 165C148 160.029 143.971 156 139 156C134.029 156 130 160.029 130 165C130 169.971 134.029 174 139 174Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M191 174C195.971 174 200 169.971 200 165C200 160.029 195.971 156 191 156C186.029 156 182 160.029 182 165C182 169.971 186.029 174 191 174Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M243 174C247.971 174 252 169.971 252 165C252 160.029 247.971 156 243 156C238.029 156 234 160.029 234 165C234 169.971 238.029 174 243 174Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M269 174C273.971 174 278 169.971 278 165C278 160.029 273.971 156 269 156C264.029 156 260 160.029 260 165C260 169.971 264.029 174 269 174Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M165 148C169.971 148 174 143.971 174 139C174 134.029 169.971 130 165 130C160.029 130 156 134.029 156 139C156 143.971 160.029 148 165 148Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M191 148C195.971 148 200 143.971 200 139C200 134.029 195.971 130 191 130C186.029 130 182 134.029 182 139C182 143.971 186.029 148 191 148Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M139 122C143.971 122 148 117.971 148 113C148 108.029 143.971 104 139 104C134.029 104 130 108.029 130 113C130 117.971 134.029 122 139 122Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M217 122C221.971 122 226 117.971 226 113C226 108.029 221.971 104 217 104C212.029 104 208 108.029 208 113C208 117.971 212.029 122 217 122Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M243 122C247.971 122 252 117.971 252 113C252 108.029 247.971 104 243 104C238.029 104 234 108.029 234 113C234 117.971 238.029 122 243 122Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M165 96C169.971 96 174 91.9706 174 87C174 82.0294 169.971 78 165 78C160.029 78 156 82.0294 156 87C156 91.9706 160.029 96 165 96Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M217 96C221.971 96 226 91.9706 226 87C226 82.0294 221.971 78 217 78C212.029 78 208 82.0294 208 87C208 91.9706 212.029 96 217 96Z" fill={bgGridDotColor}/>
            <m.path variants={fillerVariants} d="M191 70C195.971 70 200 65.9706 200 61C200 56.0294 195.971 52 191 52C186.029 52 182 56.0294 182 61C182 65.9706 186.029 70 191 70Z" fill={bgGridDotColor}/>
          </m.g>

          <path d="M9 330C13.9706 330 18 325.971 18 321C18 316.029 13.9706 312 9 312C4.02944 312 0 316.029 0 321C0 325.971 4.02944 330 9 330Z" fill={bgGridDotColor}/>
          <path d="M35 330C39.9706 330 44 325.971 44 321C44 316.029 39.9706 312 35 312C30.0294 312 26 316.029 26 321C26 325.971 30.0294 330 35 330Z" fill={bgGridDotColor}/>
          <path d="M61 330C65.9706 330 70 325.971 70 321C70 316.029 65.9706 312 61 312C56.0294 312 52 316.029 52 321C52 325.971 56.0294 330 61 330Z" fill={bgGridDotColor}/>
          <path d="M87 330C91.9706 330 96 325.971 96 321C96 316.029 91.9706 312 87 312C82.0294 312 78 316.029 78 321C78 325.971 82.0294 330 87 330Z" fill={bgGridDotColor}/>
          <path d="M113 330C117.971 330 122 325.971 122 321C122 316.029 117.971 312 113 312C108.029 312 104 316.029 104 321C104 325.971 108.029 330 113 330Z" fill={bgGridDotColor}/>
          <path d="M139 330C143.971 330 148 325.971 148 321C148 316.029 143.971 312 139 312C134.029 312 130 316.029 130 321C130 325.971 134.029 330 139 330Z" fill={bgGridDotColor}/>
          <path d="M165 330C169.971 330 174 325.971 174 321C174 316.029 169.971 312 165 312C160.029 312 156 316.029 156 321C156 325.971 160.029 330 165 330Z" fill={bgGridDotColor}/>
          <path d="M191 330C195.971 330 200 325.971 200 321C200 316.029 195.971 312 191 312C186.029 312 182 316.029 182 321C182 325.971 186.029 330 191 330Z" fill={bgGridDotColor}/>
          <path d="M217 330C221.971 330 226 325.971 226 321C226 316.029 221.971 312 217 312C212.029 312 208 316.029 208 321C208 325.971 212.029 330 217 330Z" fill={bgGridDotColor}/>
          <path d="M243 330C247.971 330 252 325.971 252 321C252 316.029 247.971 312 243 312C238.029 312 234 316.029 234 321C234 325.971 238.029 330 243 330Z" fill={bgGridDotColor}/>
          <path d="M269 330C273.971 330 278 325.971 278 321C278 316.029 273.971 312 269 312C264.029 312 260 316.029 260 321C260 325.971 264.029 330 269 330Z" fill={bgGridDotColor}/>
          <path d="M295 330C299.971 330 304 325.971 304 321C304 316.029 299.971 312 295 312C290.029 312 286 316.029 286 321C286 325.971 290.029 330 295 330Z" fill={bgGridDotColor}/>
          <path d="M321 330C325.971 330 330 325.971 330 321C330 316.029 325.971 312 321 312C316.029 312 312 316.029 312 321C312 325.971 316.029 330 321 330Z" fill={bgGridDotColor}/>
          <path d="M347 330C351.971 330 356 325.971 356 321C356 316.029 351.971 312 347 312C342.029 312 338 316.029 338 321C338 325.971 342.029 330 347 330Z" fill={bgGridDotColor}/>
          <path d="M373 330C377.971 330 382 325.971 382 321C382 316.029 377.971 312 373 312C368.029 312 364 316.029 364 321C364 325.971 368.029 330 373 330Z" fill={bgGridDotColor}/>
          <path d="M9 304C13.9706 304 18 299.971 18 295C18 290.029 13.9706 286 9 286C4.02944 286 0 290.029 0 295C0 299.971 4.02944 304 9 304Z" fill={bgGridDotColor}/>
          <path d="M35 304C39.9706 304 44 299.971 44 295C44 290.029 39.9706 286 35 286C30.0294 286 26 290.029 26 295C26 299.971 30.0294 304 35 304Z" fill={bgGridDotColor}/>
          <path d="M61 304C65.9706 304 70 299.971 70 295C70 290.029 65.9706 286 61 286C56.0294 286 52 290.029 52 295C52 299.971 56.0294 304 61 304Z" fill={bgGridDotColor}/>
          <path d="M87 304C91.9706 304 96 299.971 96 295C96 290.029 91.9706 286 87 286C82.0294 286 78 290.029 78 295C78 299.971 82.0294 304 87 304Z" fill={bgGridDotColor}/>
          <path d="M113 304C117.971 304 122 299.971 122 295C122 290.029 117.971 286 113 286C108.029 286 104 290.029 104 295C104 299.971 108.029 304 113 304Z" fill={bgGridDotColor}/>
          <path d="M139 304C143.971 304 148 299.971 148 295C148 290.029 143.971 286 139 286C134.029 286 130 290.029 130 295C130 299.971 134.029 304 139 304Z" fill={bgGridDotColor}/>
          <path d="M165 304C169.971 304 174 299.971 174 295C174 290.029 169.971 286 165 286C160.029 286 156 290.029 156 295C156 299.971 160.029 304 165 304Z" fill={bgGridDotColor}/>
          <path d="M217 304C221.971 304 226 299.971 226 295C226 290.029 221.971 286 217 286C212.029 286 208 290.029 208 295C208 299.971 212.029 304 217 304Z" fill={bgGridDotColor}/>
          <path d="M243 304C247.971 304 252 299.971 252 295C252 290.029 247.971 286 243 286C238.029 286 234 290.029 234 295C234 299.971 238.029 304 243 304Z" fill={bgGridDotColor}/>
          <path d="M269 304C273.971 304 278 299.971 278 295C278 290.029 273.971 286 269 286C264.029 286 260 290.029 260 295C260 299.971 264.029 304 269 304Z" fill={bgGridDotColor}/>
          <path d="M295 304C299.971 304 304 299.971 304 295C304 290.029 299.971 286 295 286C290.029 286 286 290.029 286 295C286 299.971 290.029 304 295 304Z" fill={bgGridDotColor}/>
          <path d="M321 304C325.971 304 330 299.971 330 295C330 290.029 325.971 286 321 286C316.029 286 312 290.029 312 295C312 299.971 316.029 304 321 304Z" fill={bgGridDotColor}/>
          <path d="M347 304C351.971 304 356 299.971 356 295C356 290.029 351.971 286 347 286C342.029 286 338 290.029 338 295C338 299.971 342.029 304 347 304Z" fill={bgGridDotColor}/>
          <path d="M373 304C377.971 304 382 299.971 382 295C382 290.029 377.971 286 373 286C368.029 286 364 290.029 364 295C364 299.971 368.029 304 373 304Z" fill={bgGridDotColor}/>
          <path d="M9 278C13.9706 278 18 273.971 18 269C18 264.029 13.9706 260 9 260C4.02944 260 0 264.029 0 269C0 273.971 4.02944 278 9 278Z" fill={bgGridDotColor}/>
          <path d="M35 278C39.9706 278 44 273.971 44 269C44 264.029 39.9706 260 35 260C30.0294 260 26 264.029 26 269C26 273.971 30.0294 278 35 278Z" fill={bgGridDotColor}/>
          <path d="M61 278C65.9706 278 70 273.971 70 269C70 264.029 65.9706 260 61 260C56.0294 260 52 264.029 52 269C52 273.971 56.0294 278 61 278Z" fill={bgGridDotColor}/>
          <path d="M87 278C91.9706 278 96 273.971 96 269C96 264.029 91.9706 260 87 260C82.0294 260 78 264.029 78 269C78 273.971 82.0294 278 87 278Z" fill={bgGridDotColor}/>
          <path d="M113 278C117.971 278 122 273.971 122 269C122 264.029 117.971 260 113 260C108.029 260 104 264.029 104 269C104 273.971 108.029 278 113 278Z" fill={bgGridDotColor}/>
          <path d="M139 278C143.971 278 148 273.971 148 269C148 264.029 143.971 260 139 260C134.029 260 130 264.029 130 269C130 273.971 134.029 278 139 278Z" fill={bgGridDotColor}/>
          <path d="M243 278C247.971 278 252 273.971 252 269C252 264.029 247.971 260 243 260C238.029 260 234 264.029 234 269C234 273.971 238.029 278 243 278Z" fill={bgGridDotColor}/>
          <path d="M269 278C273.971 278 278 273.971 278 269C278 264.029 273.971 260 269 260C264.029 260 260 264.029 260 269C260 273.971 264.029 278 269 278Z" fill={bgGridDotColor}/>
          <path d="M295 278C299.971 278 304 273.971 304 269C304 264.029 299.971 260 295 260C290.029 260 286 264.029 286 269C286 273.971 290.029 278 295 278Z" fill={bgGridDotColor}/>
          <path d="M321 278C325.971 278 330 273.971 330 269C330 264.029 325.971 260 321 260C316.029 260 312 264.029 312 269C312 273.971 316.029 278 321 278Z" fill={bgGridDotColor}/>
          <path d="M347 278C351.971 278 356 273.971 356 269C356 264.029 351.971 260 347 260C342.029 260 338 264.029 338 269C338 273.971 342.029 278 347 278Z" fill={bgGridDotColor}/>
          <path d="M373 278C377.971 278 382 273.971 382 269C382 264.029 377.971 260 373 260C368.029 260 364 264.029 364 269C364 273.971 368.029 278 373 278Z" fill={bgGridDotColor}/>
          <path d="M9 252C13.9706 252 18 247.971 18 243C18 238.029 13.9706 234 9 234C4.02944 234 0 238.029 0 243C0 247.971 4.02944 252 9 252Z" fill={bgGridDotColor}/>
          <path d="M35 252C39.9706 252 44 247.971 44 243C44 238.029 39.9706 234 35 234C30.0294 234 26 238.029 26 243C26 247.971 30.0294 252 35 252Z" fill={bgGridDotColor}/>
          <path d="M61 252C65.9706 252 70 247.971 70 243C70 238.029 65.9706 234 61 234C56.0294 234 52 238.029 52 243C52 247.971 56.0294 252 61 252Z" fill={bgGridDotColor}/>
          <path d="M87 252C91.9706 252 96 247.971 96 243C96 238.029 91.9706 234 87 234C82.0294 234 78 238.029 78 243C78 247.971 82.0294 252 87 252Z" fill={bgGridDotColor}/>
          <path d="M113 252C117.971 252 122 247.971 122 243C122 238.029 117.971 234 113 234C108.029 234 104 238.029 104 243C104 247.971 108.029 252 113 252Z" fill={bgGridDotColor}/>
          <path d="M165 252C169.971 252 174 247.971 174 243C174 238.029 169.971 234 165 234C160.029 234 156 238.029 156 243C156 247.971 160.029 252 165 252Z" fill={greyGridDotColor}/>
          <path d="M191 252C195.971 252 200 247.971 200 243C200 238.029 195.971 234 191 234C186.029 234 182 238.029 182 243C182 247.971 186.029 252 191 252Z" fill={greyGridDotColor}/>
          <path d="M217 252C221.971 252 226 247.971 226 243C226 238.029 221.971 234 217 234C212.029 234 208 238.029 208 243C208 247.971 212.029 252 217 252Z" fill={greyGridDotColor}/>
          <path d="M269 252C273.971 252 278 247.971 278 243C278 238.029 273.971 234 269 234C264.029 234 260 238.029 260 243C260 247.971 264.029 252 269 252Z" fill={bgGridDotColor}/>
          <path d="M295 252C299.971 252 304 247.971 304 243C304 238.029 299.971 234 295 234C290.029 234 286 238.029 286 243C286 247.971 290.029 252 295 252Z" fill={bgGridDotColor}/>
          <path d="M321 252C325.971 252 330 247.971 330 243C330 238.029 325.971 234 321 234C316.029 234 312 238.029 312 243C312 247.971 316.029 252 321 252Z" fill={bgGridDotColor}/>
          <path d="M347 252C351.971 252 356 247.971 356 243C356 238.029 351.971 234 347 234C342.029 234 338 238.029 338 243C338 247.971 342.029 252 347 252Z" fill={bgGridDotColor}/>
          <path d="M373 252C377.971 252 382 247.971 382 243C382 238.029 377.971 234 373 234C368.029 234 364 238.029 364 243C364 247.971 368.029 252 373 252Z" fill={bgGridDotColor}/>
          <path d="M9 226C13.9706 226 18 221.971 18 217C18 212.029 13.9706 208 9 208C4.02944 208 0 212.029 0 217C0 221.971 4.02944 226 9 226Z" fill={bgGridDotColor}/>
          <path d="M35 226C39.9706 226 44 221.971 44 217C44 212.029 39.9706 208 35 208C30.0294 208 26 212.029 26 217C26 221.971 30.0294 226 35 226Z" fill={bgGridDotColor}/>
          <path d="M61 226C65.9706 226 70 221.971 70 217C70 212.029 65.9706 208 61 208C56.0294 208 52 212.029 52 217C52 221.971 56.0294 226 61 226Z" fill={bgGridDotColor}/>
          <path d="M87 226C91.9706 226 96 221.971 96 217C96 212.029 91.9706 208 87 208C82.0294 208 78 212.029 78 217C78 221.971 82.0294 226 87 226Z" fill={bgGridDotColor}/>
          <path d="M191 226C195.971 226 200 221.971 200 217C200 212.029 195.971 208 191 208C186.029 208 182 212.029 182 217C182 221.971 186.029 226 191 226Z" fill={greyGridDotColor}/>
          <path d="M295 226C299.971 226 304 221.971 304 217C304 212.029 299.971 208 295 208C290.029 208 286 212.029 286 217C286 221.971 290.029 226 295 226Z" fill={bgGridDotColor}/>
          <path d="M321 226C325.971 226 330 221.971 330 217C330 212.029 325.971 208 321 208C316.029 208 312 212.029 312 217C312 221.971 316.029 226 321 226Z" fill={bgGridDotColor}/>
          <path d="M347 226C351.971 226 356 221.971 356 217C356 212.029 351.971 208 347 208C342.029 208 338 212.029 338 217C338 221.971 342.029 226 347 226Z" fill={bgGridDotColor}/>
          <path d="M373 226C377.971 226 382 221.971 382 217C382 212.029 377.971 208 373 208C368.029 208 364 212.029 364 217C364 221.971 368.029 226 373 226Z" fill={bgGridDotColor}/>
          <path d="M9 200C13.9706 200 18 195.971 18 191C18 186.029 13.9706 182 9 182C4.02944 182 0 186.029 0 191C0 195.971 4.02944 200 9 200Z" fill={bgGridDotColor}/>
          <path d="M35 200C39.9706 200 44 195.971 44 191C44 186.029 39.9706 182 35 182C30.0294 182 26 186.029 26 191C26 195.971 30.0294 200 35 200Z" fill={bgGridDotColor}/>
          <path d="M61 200C65.9706 200 70 195.971 70 191C70 186.029 65.9706 182 61 182C56.0294 182 52 186.029 52 191C52 195.971 56.0294 200 61 200Z" fill={bgGridDotColor}/>
          <path d="M139 200C143.971 200 148 195.971 148 191C148 186.029 143.971 182 139 182C134.029 182 130 186.029 130 191C130 195.971 134.029 200 139 200Z" fill={greyGridDotColor}/>
          <path d="M165 200C169.971 200 174 195.971 174 191C174 186.029 169.971 182 165 182C160.029 182 156 186.029 156 191C156 195.971 160.029 200 165 200Z" fill={greyGridDotColor}/>
          <path d="M191 200C195.971 200 200 195.971 200 191C200 186.029 195.971 182 191 182C186.029 182 182 186.029 182 191C182 195.971 186.029 200 191 200Z" fill={greyGridDotColor}/>
          <path d="M243 200C247.971 200 252 195.971 252 191C252 186.029 247.971 182 243 182C238.029 182 234 186.029 234 191C234 195.971 238.029 200 243 200Z" fill={greyGridDotColor}/>
          <path d="M269 200C273.971 200 278 195.971 278 191C278 186.029 273.971 182 269 182C264.029 182 260 186.029 260 191C260 195.971 264.029 200 269 200Z" fill={greyGridDotColor}/>
          <path d="M321 200C325.971 200 330 195.971 330 191C330 186.029 325.971 182 321 182C316.029 182 312 186.029 312 191C312 195.971 316.029 200 321 200Z" fill={bgGridDotColor}/>
          <path d="M347 200C351.971 200 356 195.971 356 191C356 186.029 351.971 182 347 182C342.029 182 338 186.029 338 191C338 195.971 342.029 200 347 200Z" fill={bgGridDotColor}/>
          <path d="M373 200C377.971 200 382 195.971 382 191C382 186.029 377.971 182 373 182C368.029 182 364 186.029 364 191C364 195.971 368.029 200 373 200Z" fill={bgGridDotColor}/>
          <path d="M9 174C13.9706 174 18 169.971 18 165C18 160.029 13.9706 156 9 156C4.02944 156 0 160.029 0 165C0 169.971 4.02944 174 9 174Z" fill={bgGridDotColor}/>
          <path d="M35 174C39.9706 174 44 169.971 44 165C44 160.029 39.9706 156 35 156C30.0294 156 26 160.029 26 165C26 169.971 30.0294 174 35 174Z" fill={bgGridDotColor}/>
          <path d="M165 174C169.971 174 174 169.971 174 165C174 160.029 169.971 156 165 156C160.029 156 156 160.029 156 165C156 169.971 160.029 174 165 174Z" fill={greyGridDotColor}/>
          <path d="M217 174C221.971 174 226 169.971 226 165C226 160.029 221.971 156 217 156C212.029 156 208 160.029 208 165C208 169.971 212.029 174 217 174Z" fill={greyGridDotColor}/>
          <path d="M295 174C299.971 174 304 169.971 304 165C304 160.029 299.971 156 295 156C290.029 156 286 160.029 286 165C286 169.971 290.029 174 295 174Z" fill={greyGridDotColor}/>
          <path d="M347 174C351.971 174 356 169.971 356 165C356 160.029 351.971 156 347 156C342.029 156 338 160.029 338 165C338 169.971 342.029 174 347 174Z" fill={bgGridDotColor}/>
          <path d="M373 174C377.971 174 382 169.971 382 165C382 160.029 377.971 156 373 156C368.029 156 364 160.029 364 165C364 169.971 368.029 174 373 174Z" fill={bgGridDotColor}/>
          <path d="M9 148C13.9706 148 18 143.971 18 139C18 134.029 13.9706 130 9 130C4.02944 130 0 134.029 0 139C0 143.971 4.02944 148 9 148Z" fill={bgGridDotColor}/>
          <path d="M35 148C39.9706 148 44 143.971 44 139C44 134.029 39.9706 130 35 130C30.0294 130 26 134.029 26 139C26 143.971 30.0294 148 35 148Z" fill={bgGridDotColor}/>
          <path d="M61 148C65.9706 148 70 143.971 70 139C70 134.029 65.9706 130 61 130C56.0294 130 52 134.029 52 139C52 143.971 56.0294 148 61 148Z" fill={bgGridDotColor}/>
          <path d="M113 148C117.971 148 122 143.971 122 139C122 134.029 117.971 130 113 130C108.029 130 104 134.029 104 139C104 143.971 108.029 148 113 148Z" fill={greyGridDotColor}/>
          <path d="M139 148C143.971 148 148 143.971 148 139C148 134.029 143.971 130 139 130C134.029 130 130 134.029 130 139C130 143.971 134.029 148 139 148Z" fill={greyGridDotColor}/>
          <path d="M217 148C221.971 148 226 143.971 226 139C226 134.029 221.971 130 217 130C212.029 130 208 134.029 208 139C208 143.971 212.029 148 217 148Z" fill={greyGridDotColor}/>
          <path d="M243 148C247.971 148 252 143.971 252 139C252 134.029 247.971 130 243 130C238.029 130 234 134.029 234 139C234 143.971 238.029 148 243 148Z" fill={greyGridDotColor}/>
          <path d="M269 148C273.971 148 278 143.971 278 139C278 134.029 273.971 130 269 130C264.029 130 260 134.029 260 139C260 143.971 264.029 148 269 148Z" fill={greyGridDotColor}/>
          <path d="M321 148C325.971 148 330 143.971 330 139C330 134.029 325.971 130 321 130C316.029 130 312 134.029 312 139C312 143.971 316.029 148 321 148Z" fill={bgGridDotColor}/>
          <path d="M347 148C351.971 148 356 143.971 356 139C356 134.029 351.971 130 347 130C342.029 130 338 134.029 338 139C338 143.971 342.029 148 347 148Z" fill={bgGridDotColor}/>
          <path d="M373 148C377.971 148 382 143.971 382 139C382 134.029 377.971 130 373 130C368.029 130 364 134.029 364 139C364 143.971 368.029 148 373 148Z" fill={bgGridDotColor}/>
          <path d="M9 122C13.9706 122 18 117.971 18 113C18 108.029 13.9706 104 9 104C4.02944 104 0 108.029 0 113C0 117.971 4.02944 122 9 122Z" fill={bgGridDotColor}/>
          <path d="M35 122C39.9706 122 44 117.971 44 113C44 108.029 39.9706 104 35 104C30.0294 104 26 108.029 26 113C26 117.971 30.0294 122 35 122Z" fill={bgGridDotColor}/>
          <path d="M61 122C65.9706 122 70 117.971 70 113C70 108.029 65.9706 104 61 104C56.0294 104 52 108.029 52 113C52 117.971 56.0294 122 61 122Z" fill={bgGridDotColor}/>
          <path d="M87 122C91.9706 122 96 117.971 96 113C96 108.029 91.9706 104 87 104C82.0294 104 78 108.029 78 113C78 117.971 82.0294 122 87 122Z" fill={bgGridDotColor}/>
          <path d="M165 122C169.971 122 174 117.971 174 113C174 108.029 169.971 104 165 104C160.029 104 156 108.029 156 113C156 117.971 160.029 122 165 122Z" fill={greyGridDotColor}/>
          <path d="M191 122C195.971 122 200 117.971 200 113C200 108.029 195.971 104 191 104C186.029 104 182 108.029 182 113C182 117.971 186.029 122 191 122Z" fill={greyGridDotColor}/>
          <path d="M295 122C299.971 122 304 117.971 304 113C304 108.029 299.971 104 295 104C290.029 104 286 108.029 286 113C286 117.971 290.029 122 295 122Z" fill={bgGridDotColor}/>
          <path d="M321 122C325.971 122 330 117.971 330 113C330 108.029 325.971 104 321 104C316.029 104 312 108.029 312 113C312 117.971 316.029 122 321 122Z" fill={bgGridDotColor}/>
          <path d="M347 122C351.971 122 356 117.971 356 113C356 108.029 351.971 104 347 104C342.029 104 338 108.029 338 113C338 117.971 342.029 122 347 122Z" fill={bgGridDotColor}/>
          <path d="M373 122C377.971 122 382 117.971 382 113C382 108.029 377.971 104 373 104C368.029 104 364 108.029 364 113C364 117.971 368.029 122 373 122Z" fill={bgGridDotColor}/>
          <path d="M9 96C13.9706 96 18 91.9706 18 87C18 82.0294 13.9706 78 9 78C4.02944 78 0 82.0294 0 87C0 91.9706 4.02944 96 9 96Z" fill={bgGridDotColor}/>
          <path d="M35 96C39.9706 96 44 91.9706 44 87C44 82.0294 39.9706 78 35 78C30.0294 78 26 82.0294 26 87C26 91.9706 30.0294 96 35 96Z" fill={bgGridDotColor}/>
          <path d="M61 96C65.9706 96 70 91.9706 70 87C70 82.0294 65.9706 78 61 78C56.0294 78 52 82.0294 52 87C52 91.9706 56.0294 96 61 96Z" fill={bgGridDotColor}/>
          <path d="M87 96C91.9706 96 96 91.9706 96 87C96 82.0294 91.9706 78 87 78C82.0294 78 78 82.0294 78 87C78 91.9706 82.0294 96 87 96Z" fill={bgGridDotColor}/>
          <path d="M113 96C117.971 96 122 91.9706 122 87C122 82.0294 117.971 78 113 78C108.029 78 104 82.0294 104 87C104 91.9706 108.029 96 113 96Z" fill={bgGridDotColor}/>
          <path d="M191 96C195.971 96 200 91.9706 200 87C200 82.0294 195.971 78 191 78C186.029 78 182 82.0294 182 87C182 91.9706 186.029 96 191 96Z" fill={greyGridDotColor}/>
          <path d="M269 96C273.971 96 278 91.9706 278 87C278 82.0294 273.971 78 269 78C264.029 78 260 82.0294 260 87C260 91.9706 264.029 96 269 96Z" fill={bgGridDotColor}/>
          <path d="M295 96C299.971 96 304 91.9706 304 87C304 82.0294 299.971 78 295 78C290.029 78 286 82.0294 286 87C286 91.9706 290.029 96 295 96Z" fill={bgGridDotColor}/>
          <path d="M321 96C325.971 96 330 91.9706 330 87C330 82.0294 325.971 78 321 78C316.029 78 312 82.0294 312 87C312 91.9706 316.029 96 321 96Z" fill={bgGridDotColor}/>
          <path d="M347 96C351.971 96 356 91.9706 356 87C356 82.0294 351.971 78 347 78C342.029 78 338 82.0294 338 87C338 91.9706 342.029 96 347 96Z" fill={bgGridDotColor}/>
          <path d="M373 96C377.971 96 382 91.9706 382 87C382 82.0294 377.971 78 373 78C368.029 78 364 82.0294 364 87C364 91.9706 368.029 96 373 96Z" fill={bgGridDotColor}/>
          <path d="M9 70C13.9706 70 18 65.9706 18 61C18 56.0294 13.9706 52 9 52C4.02944 52 0 56.0294 0 61C0 65.9706 4.02944 70 9 70Z" fill={bgGridDotColor}/>
          <path d="M35 70C39.9706 70 44 65.9706 44 61C44 56.0294 39.9706 52 35 52C30.0294 52 26 56.0294 26 61C26 65.9706 30.0294 70 35 70Z" fill={bgGridDotColor}/>
          <path d="M61 70C65.9706 70 70 65.9706 70 61C70 56.0294 65.9706 52 61 52C56.0294 52 52 56.0294 52 61C52 65.9706 56.0294 70 61 70Z" fill={bgGridDotColor}/>
          <path d="M87 70C91.9706 70 96 65.9706 96 61C96 56.0294 91.9706 52 87 52C82.0294 52 78 56.0294 78 61C78 65.9706 82.0294 70 87 70Z" fill={bgGridDotColor}/>
          <path d="M113 70C117.971 70 122 65.9706 122 61C122 56.0294 117.971 52 113 52C108.029 52 104 56.0294 104 61C104 65.9706 108.029 70 113 70Z" fill={bgGridDotColor}/>
          <path d="M139 70C143.971 70 148 65.9706 148 61C148 56.0294 143.971 52 139 52C134.029 52 130 56.0294 130 61C130 65.9706 134.029 70 139 70Z" fill={bgGridDotColor}/>
          <path d="M243 70C247.971 70 252 65.9706 252 61C252 56.0294 247.971 52 243 52C238.029 52 234 56.0294 234 61C234 65.9706 238.029 70 243 70Z" fill={bgGridDotColor}/>
          <path d="M269 70C273.971 70 278 65.9706 278 61C278 56.0294 273.971 52 269 52C264.029 52 260 56.0294 260 61C260 65.9706 264.029 70 269 70Z" fill={bgGridDotColor}/>
          <path d="M295 70C299.971 70 304 65.9706 304 61C304 56.0294 299.971 52 295 52C290.029 52 286 56.0294 286 61C286 65.9706 290.029 70 295 70Z" fill={bgGridDotColor}/>
          <path d="M321 70C325.971 70 330 65.9706 330 61C330 56.0294 325.971 52 321 52C316.029 52 312 56.0294 312 61C312 65.9706 316.029 70 321 70Z" fill={bgGridDotColor}/>
          <path d="M347 70C351.971 70 356 65.9706 356 61C356 56.0294 351.971 52 347 52C342.029 52 338 56.0294 338 61C338 65.9706 342.029 70 347 70Z" fill={bgGridDotColor}/>
          <path d="M373 70C377.971 70 382 65.9706 382 61C382 56.0294 377.971 52 373 52C368.029 52 364 56.0294 364 61C364 65.9706 368.029 70 373 70Z" fill={bgGridDotColor}/>
          <path d="M9 44C13.9706 44 18 39.9706 18 35C18 30.0294 13.9706 26 9 26C4.02944 26 0 30.0294 0 35C0 39.9706 4.02944 44 9 44Z" fill={bgGridDotColor}/>
          <path d="M35 44C39.9706 44 44 39.9706 44 35C44 30.0294 39.9706 26 35 26C30.0294 26 26 30.0294 26 35C26 39.9706 30.0294 44 35 44Z" fill={bgGridDotColor}/>
          <path d="M61 44C65.9706 44 70 39.9706 70 35C70 30.0294 65.9706 26 61 26C56.0294 26 52 30.0294 52 35C52 39.9706 56.0294 44 61 44Z" fill={bgGridDotColor}/>
          <path d="M87 44C91.9706 44 96 39.9706 96 35C96 30.0294 91.9706 26 87 26C82.0294 26 78 30.0294 78 35C78 39.9706 82.0294 44 87 44Z" fill={bgGridDotColor}/>
          <path d="M113 44C117.971 44 122 39.9706 122 35C122 30.0294 117.971 26 113 26C108.029 26 104 30.0294 104 35C104 39.9706 108.029 44 113 44Z" fill={bgGridDotColor}/>
          <path d="M139 44C143.971 44 148 39.9706 148 35C148 30.0294 143.971 26 139 26C134.029 26 130 30.0294 130 35C130 39.9706 134.029 44 139 44Z" fill={bgGridDotColor}/>
          <path d="M165 44C169.971 44 174 39.9706 174 35C174 30.0294 169.971 26 165 26C160.029 26 156 30.0294 156 35C156 39.9706 160.029 44 165 44Z" fill={bgGridDotColor}/>
          <path d="M217 44C221.971 44 226 39.9706 226 35C226 30.0294 221.971 26 217 26C212.029 26 208 30.0294 208 35C208 39.9706 212.029 44 217 44Z" fill={bgGridDotColor}/>
          <path d="M243 44C247.971 44 252 39.9706 252 35C252 30.0294 247.971 26 243 26C238.029 26 234 30.0294 234 35C234 39.9706 238.029 44 243 44Z" fill={bgGridDotColor}/>
          <path d="M269 44C273.971 44 278 39.9706 278 35C278 30.0294 273.971 26 269 26C264.029 26 260 30.0294 260 35C260 39.9706 264.029 44 269 44Z" fill={bgGridDotColor}/>
          <path d="M295 44C299.971 44 304 39.9706 304 35C304 30.0294 299.971 26 295 26C290.029 26 286 30.0294 286 35C286 39.9706 290.029 44 295 44Z" fill={bgGridDotColor}/>
          <path d="M321 44C325.971 44 330 39.9706 330 35C330 30.0294 325.971 26 321 26C316.029 26 312 30.0294 312 35C312 39.9706 316.029 44 321 44Z" fill={bgGridDotColor}/>
          <path d="M347 44C351.971 44 356 39.9706 356 35C356 30.0294 351.971 26 347 26C342.029 26 338 30.0294 338 35C338 39.9706 342.029 44 347 44Z" fill={bgGridDotColor}/>
          <path d="M373 44C377.971 44 382 39.9706 382 35C382 30.0294 377.971 26 373 26C368.029 26 364 30.0294 364 35C364 39.9706 368.029 44 373 44Z" fill={bgGridDotColor}/>
          <path d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill={bgGridDotColor}/>
          <path d="M35 18C39.9706 18 44 13.9706 44 9C44 4.02944 39.9706 0 35 0C30.0294 0 26 4.02944 26 9C26 13.9706 30.0294 18 35 18Z" fill={bgGridDotColor}/>
          <path d="M61 18C65.9706 18 70 13.9706 70 9C70 4.02944 65.9706 0 61 0C56.0294 0 52 4.02944 52 9C52 13.9706 56.0294 18 61 18Z" fill={bgGridDotColor}/>
          <path d="M87 18C91.9706 18 96 13.9706 96 9C96 4.02944 91.9706 0 87 0C82.0294 0 78 4.02944 78 9C78 13.9706 82.0294 18 87 18Z" fill={bgGridDotColor}/>
          <path d="M113 18C117.971 18 122 13.9706 122 9C122 4.02944 117.971 0 113 0C108.029 0 104 4.02944 104 9C104 13.9706 108.029 18 113 18Z" fill={bgGridDotColor}/>
          <path d="M139 18C143.971 18 148 13.9706 148 9C148 4.02944 143.971 0 139 0C134.029 0 130 4.02944 130 9C130 13.9706 134.029 18 139 18Z" fill={bgGridDotColor}/>
          <path d="M165 18C169.971 18 174 13.9706 174 9C174 4.02944 169.971 0 165 0C160.029 0 156 4.02944 156 9C156 13.9706 160.029 18 165 18Z" fill={bgGridDotColor}/>
          <path d="M191 18C195.971 18 200 13.9706 200 9C200 4.02944 195.971 0 191 0C186.029 0 182 4.02944 182 9C182 13.9706 186.029 18 191 18Z" fill={bgGridDotColor}/>
          <path d="M217 18C221.971 18 226 13.9706 226 9C226 4.02944 221.971 0 217 0C212.029 0 208 4.02944 208 9C208 13.9706 212.029 18 217 18Z" fill={bgGridDotColor}/>
          <path d="M243 18C247.971 18 252 13.9706 252 9C252 4.02944 247.971 0 243 0C238.029 0 234 4.02944 234 9C234 13.9706 238.029 18 243 18Z" fill={bgGridDotColor}/>
          <path d="M269 18C273.971 18 278 13.9706 278 9C278 4.02944 273.971 0 269 0C264.029 0 260 4.02944 260 9C260 13.9706 264.029 18 269 18Z" fill={bgGridDotColor}/>
          <path d="M295 18C299.971 18 304 13.9706 304 9C304 4.02944 299.971 0 295 0C290.029 0 286 4.02944 286 9C286 13.9706 290.029 18 295 18Z" fill={bgGridDotColor}/>
          <path d="M321 18C325.971 18 330 13.9706 330 9C330 4.02944 325.971 0 321 0C316.029 0 312 4.02944 312 9C312 13.9706 316.029 18 321 18Z" fill={bgGridDotColor}/>
          <path d="M347 18C351.971 18 356 13.9706 356 9C356 4.02944 351.971 0 347 0C342.029 0 338 4.02944 338 9C338 13.9706 342.029 18 347 18Z" fill={bgGridDotColor}/>
          <path d="M373 18C377.971 18 382 13.9706 382 9C382 4.02944 377.971 0 373 0C368.029 0 364 4.02944 364 9C364 13.9706 368.029 18 373 18Z" fill={bgGridDotColor}/>
        </m.svg>
      </LazyMotion>
    </div>
  )
}

export default PossibilitesAnimation
