import { AnimationControls, useAnimation } from 'framer-motion'
import { useCallback, useEffect, useRef } from 'react'
import { useIntersectionObserver } from 'usehooks-ts'
import Nl2br from '../../Nl2br'
import OffersAnimation from './highlight-card-animations/OffersAnimation'
import PossibilitesAnimation from './highlight-card-animations/PossibilitiesAnimation'
import QualityAnimation from './highlight-card-animations/QualityAnimation'

type Visual = 'possibilities' | 'offers' | 'quality'

export interface I3UpHighlightCardsContent {
  highlightsCollection: {
    items: {
      heading: string
      description?: string
      visual: Visual
    }[]
  }
}

interface I3UpHighlightCardsProps {
  content: I3UpHighlightCardsContent
}

const ThreeUpHighlightCards = ({ content }: I3UpHighlightCardsProps) => {
  const highlights = content.highlightsCollection?.items ?? []

  const firstCardRef = useRef(null)
  const entry = useIntersectionObserver(firstCardRef, {
    threshold: 0.75,
    freezeOnceVisible: true,
  })
  const isVisible = !!entry?.isIntersecting

  const animation1Controls = useAnimation()
  const animation2Controls = useAnimation()
  const animation3Controls = useAnimation()
  const allControls = [
    animation1Controls,
    animation2Controls,
    animation3Controls,
  ]

  const sequence = useCallback(async () => {
    await animation1Controls.start('animate')
    await animation2Controls.start('animate')
    await animation3Controls.start('animate')
  }, [animation1Controls, animation2Controls, animation3Controls])

  useEffect(() => {
    if (isVisible) {
      sequence()
    }
  }, [isVisible, sequence])

  return (
    <section>
      <ul className="grid gap-32 md:grid-cols-3 md:gap-20 lg:gap-32">
        {highlights.map((highlight, i) => (
          <li
            key={highlight.heading}
            className="rounded bg-white sm:flex md:block"
            ref={i === 0 ? firstCardRef : null}
          >
            <div className="p-20 sm:w-[250px] sm:shrink-0 md:w-auto lg:px-40 lg:pt-40">
              <AnimationComponent
                slug={highlight.visual}
                controls={allControls[i]}
              />
            </div>
            <div className="px-20 pb-32 pt-6 text-center xs:text-left sm:pt-32 lg:px-40 lg:pb-40 lg:pt-0">
              <p className="text-[28px] font-semibold leading-tight md:text-24 lg:text-[28px] xl:text-[36px]">
                <Nl2br str={highlight.heading} brClassName="md-d:hidden" />
              </p>
              {highlight.description ? (
                <p className="mt-10 text-18">{highlight.description}</p>
              ) : null}
            </div>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default ThreeUpHighlightCards

const AnimationComponent = ({
  slug,
  controls,
}: {
  slug: Visual
  controls: AnimationControls
}) => {
  switch (slug) {
    case 'possibilities':
      return <PossibilitesAnimation controls={controls} />

    case 'offers':
      return <OffersAnimation controls={controls} />

    case 'quality':
      return <QualityAnimation controls={controls} />

    default:
      return null
  }
}
