import { FC, useCallback, useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import * as Slider from '@radix-ui/react-slider'
import { useQueryClient } from 'react-query'

import { FancySelect, SliderThumb, SliderTrack } from 'ui'

import {
  IPayment,
  PRICE_CALCULATOR_QUERY_KEY,
  usePriceCalculator,
} from '../../../services/website/priceCalculator'
import { useTracking } from '../../../services/context/TrackingContext'

import { numberToCurrency } from 'utils'
import { abbreviateCurrency } from '../../../utils/abbreviateCurrency'

import { HomeCalculatorIcon } from 'icons'

export interface ICalculatorContent {
  heading: string
  purchaseAmounts: string[]
  apr: number
  percentStart: number
  percentEnd: number
  percentSteps: number
  homeValueMin: number
  homeValueMax: number
}

interface ICalculatorProps {
  content: ICalculatorContent
}

export const roundTo2Decimal = (input: number): number => {
  return Math.round((input + Number.EPSILON) * 100) / 100
}

const Calculator = ({ content }: ICalculatorProps) => {
  const [percentStep, setPercentStep] = useState(
    roundTo2Decimal(
      (content.percentEnd - content.percentStart) / content.percentSteps
    )
  )
  const [percentStart, setPercentStart] = useState(content.percentStart)
  const [percentEnd, setPercentEnd] = useState(content.percentEnd)
  const [valueSharePercent, setValueSharePercent] = useState(
    content.percentStart
  )
  const [purchaseAmount, setPurchaseAmount] = useState(
    content?.purchaseAmounts[0] ?? null
  )
  const [updateStatus, setUpdateStatus] = useState<
    'idle' | 'loading' | 'success'
  >('idle')
  const isInitialRender = useRef(true)

  const purchaseAmounts = content.purchaseAmounts.map((amount) => ({
    title: numberToCurrency(Number(amount)),
    value: amount,
  }))

  const queryClient = useQueryClient()

  const {
    data: priceData,
    isFetching: isFetchingPrice,
    refetch: reloadPrice,
  } = usePriceCalculator({
    valueShare: Number(purchaseAmount),
    apr: content.apr,
    percentStart: percentStart,
    percentEnd: percentEnd,
    steps: content.percentSteps,
  })

  useEffect(() => {
    setUpdateStatus('loading')
    const newPercentStart = roundTo2Decimal(
      Math.ceil(
        Math.max(
          Number(purchaseAmount) / content.homeValueMax,
          content.percentStart / 100
        ) * 1000
      ) / 10
    )

    const newPercentEnd = roundTo2Decimal(
      Math.floor(
        Math.min(
          Number(purchaseAmount) / content.homeValueMin,
          content.percentEnd / 100
        ) * 1000
      ) / 10
    )

    const newPercentStep = roundTo2Decimal(
      (newPercentEnd - newPercentStart) / content.percentSteps
    )

    setPercentStart(newPercentStart)
    setPercentEnd(newPercentEnd)
    setPercentStep(newPercentStep)
    setValueSharePercent(
      newPercentStart + newPercentStep * Math.floor(content.percentSteps / 2)
    )
    setUpdateStatus('success')
  }, [
    content.homeValueMax,
    content.homeValueMin,
    content.percentEnd,
    content.percentStart,
    content.percentSteps,
    purchaseAmount,
  ])

  useEffect(() => {
    queryClient.cancelQueries(PRICE_CALCULATOR_QUERY_KEY)
    reloadPrice()
  }, [percentEnd, percentStart, purchaseAmount, queryClient, reloadPrice])

  let rangeStart: IPayment = {
    acreMonthly: 0,
    mortgage: 0,
    homeValue: 0,
  }
  let rangeEnd: IPayment = {
    acreMonthly: 0,
    mortgage: 0,
    homeValue: 0,
  }
  let selection: IPayment = {
    acreMonthly: 0,
    mortgage: 0,
    homeValue: 0,
  }
  let savings = 0

  let prices = []
  let sliderPercentStart = content.percentStart
  let sliderPercentEnd = content.percentEnd

  const minPurchasingPower = Math.max(
    Number(purchaseAmount) / (content.percentEnd / 100),
    content.homeValueMin
  )
  const maxPurchasingPower = Math.min(
    Number(purchaseAmount) / (content.percentStart / 100),
    content.homeValueMax
  )

  if (priceData) {
    prices = Object.entries(priceData).sort((a, b) =>
      Number(a[0]) < Number(b[0]) ? -1 : 1
    )
    rangeStart = prices[prices.length - 1][1]
    sliderPercentStart = Number(prices[0][0])
    rangeEnd = prices[0][1]
    sliderPercentEnd = Number(prices[prices.length - 1][0])
    selection = priceData[valueSharePercent.toFixed(2)] ?? selection
    savings = selection.mortgage - selection.acreMonthly
  }

  const { track } = useTracking()
  const localTrack = useCallback(
    (adjustmentTarget: string, adjustmentMethod: string) =>
      track({
        event: 'Property Purchase Calculator Adjusted',
        properties: {
          calculator_type: 'Landing Page',
          adjustment_target: adjustmentTarget,
          adjustment_method: adjustmentMethod,
          user_ownership_share_amount: Number(purchaseAmount),
          acre_ownership_share_amount:
            selection.homeValue - Number(purchaseAmount),
          user_ownership_share_percentage: valueSharePercent / 100,
          monthly_payment_with_acre: selection.acreMonthly,
          monthly_payment_with_mortgage: selection.mortgage,
          monthly_savings_with_acre: savings,
          interest_rate: content.apr / 100,
          min_property_value_afforded: minPurchasingPower,
          max_property_value_afforded: maxPurchasingPower,
        },
      }),
    [
      content.apr,
      maxPurchasingPower,
      minPurchasingPower,
      valueSharePercent,
      purchaseAmount,
      savings,
      selection.acreMonthly,
      selection.homeValue,
      selection.mortgage,
      track,
    ]
  )

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false
      setUpdateStatus('idle')
      return
    }

    if (updateStatus === 'success' && savings !== 0) {
      localTrack('Purchase Amount', 'Dropdown')
      setUpdateStatus('idle')
    }
  }, [localTrack, savings, updateStatus])

  return (
    <section
      id="calculator"
      className="overflow-hidden rounded bg-white md:p-20"
    >
      <h2 className="border-b border-green-1 py-16 px-12 text-center text-[28px] font-semibold text-web-darkest md:hidden">
        {content.heading}
      </h2>
      <div className="flex items-center md-d:flex-col">
        <div className="w-full overflow-hidden md:max-w-[380px] md:rounded-md md:border md:border-green-1 lg:max-w-[436px]">
          <h2 className="py-24 px-12 text-center text-[36px] font-semibold text-web-darkest md-d:hidden">
            {content.heading}
          </h2>
          <form>
            <fieldset className="border-y border-green-1 p-24">
              <div className="mx-auto max-w-420">
                <FancySelect
                  id="purchase-amount"
                  label="Home Value Share Amount"
                  items={purchaseAmounts}
                  value={purchaseAmount}
                  onChange={(_, value) => setPurchaseAmount(value)}
                />
                <p className="mt-6 text-grey-6 util-caption">
                  Your share in the value of the house. Adjust to see your
                  buying power.
                </p>
                <p className="mt-24 text-center">
                  Your value share unlocks a<br />
                  <strong>
                    <LoadingPlaceholder
                      className="min-w-[70px]"
                      isLoading={isFetchingPrice}
                      value={minPurchasingPower}
                      attrs={{
                        'data-testid': 'min-power',
                      }}
                    />{' '}
                    -{' '}
                    <LoadingPlaceholder
                      className="min-w-[70px]"
                      isLoading={isFetchingPrice}
                      value={maxPurchasingPower}
                      attrs={{
                        'data-testid': 'max-power',
                      }}
                    />
                  </strong>{' '}
                  home
                </p>
              </div>
            </fieldset>
            <fieldset className="border-b border-green-1 p-24 pb-80">
              <div className="mx-auto max-w-420">
                <label
                  className="block text-center text-14 font-semibold"
                  id="monthly-payment-label"
                >
                  Adjust Monthly Payment
                </label>
                <div className="mt-16 flex items-center space-x-20">
                  <SliderLabel className="text-right">
                    <LoadingPlaceholder
                      className="min-w-[51px]"
                      isLoading={isFetchingPrice}
                      value={rangeStart.acreMonthly}
                      attrs={{
                        'data-testid': 'min-monthly',
                      }}
                    />
                  </SliderLabel>
                  <Slider.Root
                    min={sliderPercentStart}
                    max={sliderPercentEnd}
                    dir="rtl"
                    step={percentStep}
                    value={[valueSharePercent]}
                    onValueChange={(values) => {
                      setValueSharePercent(values[0])
                    }}
                    onPointerUp={() => localTrack('Monthly Payment', 'Slider')}
                    className="relative flex grow py-8"
                  >
                    <SliderTrack />
                    <SliderThumb
                      className={cx({ 'opacity-0': isFetchingPrice })}
                      ariaLabelledby="monthly-payment-label"
                      ariaValueText={numberToCurrency(selection.acreMonthly)}
                    >
                      <LoadingPlaceholder
                        isLoading={isFetchingPrice}
                        value={selection.acreMonthly}
                        isDark
                      />
                    </SliderThumb>
                  </Slider.Root>
                  <SliderLabel>
                    <LoadingPlaceholder
                      className="min-w-[51px]"
                      isLoading={isFetchingPrice}
                      value={rangeEnd.acreMonthly}
                      attrs={{
                        'data-testid': 'max-monthly',
                      }}
                    />
                  </SliderLabel>
                </div>
              </div>
            </fieldset>
            <div className="bg-grey-2 p-24">
              <div className="mx-auto max-w-420">
                <p className="text-center">
                  <strong className="text-teal-3">
                    <LoadingPlaceholder
                      isLoading={isFetchingPrice}
                      value={selection.acreMonthly}
                      attrs={{
                        'data-testid': 'acre-monthly',
                      }}
                    />
                  </strong>{' '}
                  a month means you can afford up to a{' '}
                  <strong className="text-teal-3">
                    <LoadingPlaceholder
                      isLoading={isFetchingPrice}
                      value={selection.homeValue}
                      attrs={{
                        'data-testid': 'buying-power',
                      }}
                    />
                  </strong>{' '}
                  home and saves you{' '}
                  <strong className="text-teal-3">
                    <LoadingPlaceholder
                      isLoading={isFetchingPrice}
                      value={savings}
                      attrs={{
                        'data-testid': 'savings-monthly',
                      }}
                    />
                    *
                  </strong>{' '}
                  a month over a traditional mortgage.
                </p>
                <p className="mt-16 text-center text-green-3 util-caption">
                  *vs a mortgage payment of{' '}
                  <LoadingPlaceholder
                    isLoading={isFetchingPrice}
                    value={selection.mortgage}
                    attrs={{
                      'data-testid': 'mortgage-monthly',
                    }}
                  />{' '}
                  per month at {content.apr}% APR
                </p>
              </div>
            </div>
          </form>
        </div>
        <div className="grow md:flex md:flex-col md:items-center xl:flex-row xl:justify-center md-d:order-first md-d:py-24">
          <div className="relative">
            <HomeCalculatorIcon
              className="w-[225px] stroke-[#BACDD1] text-teal-1 xs:w-[300px] md:w-[225px] lg:w-[350px] xl:w-[411px]"
              aria-hidden="true"
            />
            <div
              className="absolute inset-0 overflow-hidden transition-[width] duration-75 ease-linear"
              style={{ width: `${valueSharePercent * 2.5}%` }}
            >
              <HomeCalculatorIcon
                className="w-[225px] stroke-[#08696A] text-teal-3 xs:w-[300px] md:w-[225px] lg:w-[350px] xl:w-[411px]"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="mt-20 ml-12 min-w-[170px] space-y-20 xl:mt-0 xl:mb-12 xl:ml-62 xl:self-end">
            <p className="text-web-darkest hd-md-bold">The Acre Program</p>
            <p className="flex items-center before:inline-block before:rounded-full before:border before:border-teal-5 before:bg-teal-3 before:rect-24">
              <span className="ml-12">
                You pay {abbreviateCurrency(Number(purchaseAmount))}
              </span>
            </p>
            <p className="flex items-center before:inline-block before:rounded-full before:border before:border-teal-5 before:bg-teal-1 before:rect-24">
              <span className="ml-12">
                Acre pays{' '}
                <LoadingPlaceholder
                  isLoading={isFetchingPrice}
                  formattedValue={abbreviateCurrency(
                    Math.max(selection.homeValue - Number(purchaseAmount), 0)
                  )}
                />
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Calculator

const SliderLabel: FC<{ className?: string }> = ({ children, className }) => {
  return (
    <div
      className={cx(className, 'min-w-[51px] text-14 font-bold text-[#4E6C73]')}
    >
      {children}
    </div>
  )
}

export const LoadingPlaceholder = ({
  className,
  displayClassName = 'inline-block',
  value,
  formattedValue,
  isLoading,
  isDark,
  attrs,
}: {
  className?: string
  displayClassName?: string
  value?: number
  formattedValue?: string
  isLoading: boolean
  isDark?: boolean
  attrs?: object
}) => {
  return (
    <span
      className={cx(className, displayClassName, {
        'animate-pulse rounded-xs': isLoading,
        'bg-grey-3': isLoading && !isDark,
        'bg-white/40': isLoading && isDark,
      })}
      {...attrs}
    >
      <span className={cx({ 'opacity-0': isLoading })}>
        {typeof value !== 'undefined' ? numberToCurrency(value) : null}
        {formattedValue ?? null}
      </span>
    </span>
  )
}
