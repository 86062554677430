import cx from 'classnames'
import Image from 'next/image'

import { LinkedInIcon, AcreMiniLogoIcon } from 'icons'

export interface ITeamGridContent {
  heading: string
  description: string | null
  ctaTextLine1: string | null
  ctaTextLine2: string | null
  ctaUrl: string | null
  teamMembersCollection: {
    items: {
      headshot: {
        url: string
      }
      name: string
      position: string
      linkedInUrl: string
    }[]
  }
}

interface ITeamGridProps {
  content: ITeamGridContent
}

const LinkedIn = ({ className }: { className: string }) => (
  <div className={cx(className, 'rounded-md bg-teal-1 p-8')}>
    <LinkedInIcon className="text-teal-5 rect-24" />
  </div>
)

const TeamGrid = ({ content }: ITeamGridProps) => {
  const teamMembers = content.teamMembersCollection?.items ?? []

  return (
    <section
      id="team"
      className="rounded bg-white py-32 px-24 lg:px-40 lg:py-62 xl:px-80"
    >
      <h2 className="text-center text-[28px] font-semibold leading-tight text-web-darkest md:text-[36px]">
        {content.heading}
      </h2>
      {content.description ? (
        <p className="mx-auto mt-6 max-w-[375px] text-center text-20 text-web-darkest">
          {content.description}
        </p>
      ) : null}
      <ul className="mt-40 grid gap-32 xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {teamMembers.map((person) => (
          <li key={person.name}>
            <a href={person.linkedInUrl} className="group block">
              <div className="relative aspect-[5/6] overflow-hidden rounded-md bg-grey-8">
                <Image
                  className="transition-opacity duration-200 group-hover:opacity-75"
                  src={person.headshot.url}
                  layout="fill"
                  alt=""
                />
                <LinkedIn className="absolute bottom-14 right-14 translate-y-[calc(100%+14px)] transition-transform duration-200 group-hover:translate-y-0 xs-d:hidden" />
              </div>
              <div className="mt-12 text-web-darkest xs-d:flex xs-d:items-center xs-d:justify-between">
                <div>
                  <p className="text-20 font-semibold leading-tight lg:text-24">
                    {person.name}
                  </p>
                  <p className="mt-6 text-18 leading-relaxed">
                    {person.position}
                  </p>
                </div>
                <LinkedIn className="ml-12 shrink-0 xs:hidden" />
              </div>
            </a>
          </li>
        ))}
        {content.ctaUrl ? (
          <li>
            <a href={content.ctaUrl} className="group block">
              <div className="relative flex aspect-[5/6] items-center justify-center rounded-md bg-teal-5 transition-colors group-hover:bg-opacity-90">
                <AcreMiniLogoIcon className="w-48 fill-tan-3" />
              </div>
              <div className="mt-12 text-web-darkest">
                {content.ctaTextLine1 ? (
                  <p className="text-20 font-semibold leading-tight text-teal-3 underline lg:text-24">
                    {content.ctaTextLine1}
                  </p>
                ) : null}
                {content.ctaTextLine2 ? (
                  <p className="mt-6 text-18 leading-relaxed">
                    {content.ctaTextLine2}
                  </p>
                ) : null}
              </div>
            </a>
          </li>
        ) : null}
      </ul>
    </section>
  )
}

export default TeamGrid
