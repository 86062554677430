import { useState } from 'react'
import cx from 'classnames'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'

import { PrimaryButton, Input, Textarea, Select } from 'ui'

import { useContactUs } from '../../../services/website/contactUs'

export interface IContactContent {
  heading: string
  description: string
  image: {
    url: string
  }
  reasons?: string[]
  confirmationMessage: string
}

interface IContactProps {
  className?: string
  content: IContactContent
}

const Contact = ({ className, content }: IContactProps) => {
  const { t } = useTranslation(['website', 'common'])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [reason, setReason] = useState('')
  const [message, setMessage] = useState('')

  const { mutate: submitContactForm, status: contactFormStatus } =
    useContactUs()

  return (
    <section
      id="contact"
      className={cx(
        className,
        'overflow-hidden rounded bg-white md:grid md:grid-cols-2 lg:grid-cols-3'
      )}
    >
      <div className="relative aspect-square sm:aspect-video md:col-span-1 md:aspect-auto">
        <Image
          src={content.image?.url}
          layout="fill"
          objectFit="cover"
          alt=""
        />
      </div>
      <div className="px-20 pt-32 pb-20 md:col-span-1 lg:col-span-2 lg:px-40 lg:pt-120 lg:pb-80">
        <h2 className="text-center text-[28px] font-semibold leading-tight text-web-darkest md:text-left md:text-[36px]">
          {content.heading}
        </h2>

        <p className="mt-18 hidden text-18 text-web-darkest md:block">
          {content.description}
        </p>
        <div className="relative mt-20 md:mt-40">
          <form
            className={cx({
              invisible: contactFormStatus === 'success',
            })}
            onSubmit={(e) => {
              e.preventDefault()
              submitContactForm({
                name,
                email,
                reason,
                message,
              })
            }}
          >
            <fieldset
              className="grid gap-16 lg:grid-cols-2 lg:gap-40"
              disabled={contactFormStatus === 'loading'}
            >
              <div className="space-y-16">
                <Input
                  id="name"
                  label={t('contact.name')}
                  placeholder={t('contact.name')}
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  required
                />
                <Input
                  type="email"
                  id="email"
                  label={t('contact.email')}
                  placeholder={t('contact.email')}
                  value={email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  required
                />
                {content.reasons?.length ? (
                  <Select
                    id="reason"
                    items={content.reasons.map((reason) => ({
                      value: reason,
                      title: reason,
                    }))}
                    label={t('contact.reason-for-contact')}
                    placeholder={`-- ${t('contact.reason-for-contact')} --`}
                    required
                    value={reason}
                    customOnChange={(id, value) => setReason(value)}
                  />
                ) : null}
              </div>
              <div>
                <Textarea
                  id="message"
                  label={t('contact.message')}
                  placeholder={t('contact.message')}
                  rows={9}
                  required
                  value={message}
                  onChange={(e) => setMessage(e.currentTarget.value)}
                />
                <div className="mt-16 text-right">
                  <PrimaryButton type="submit">
                    {t('ui.submit', { ns: 'common' })}
                  </PrimaryButton>
                  {contactFormStatus === 'error' ? (
                    <p className="mt-12 text-error-1">
                      {t('ui.general-error-message', { ns: 'common' })}
                    </p>
                  ) : null}
                </div>
              </div>
            </fieldset>
          </form>
          {contactFormStatus === 'success' ? (
            <div className="absolute inset-0 grid place-content-center rounded-md border border-grey-3 bg-grey-2 p-20">
              {content.confirmationMessage}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}

export default Contact
